/* stylelint-disable */
@use '@styles/theme.module.scss' as *;
@use 'sass:map';

$tooltip-color: $white;
$arrow-margin-medium: spacing(5);
$arrow-margin-large: 35px;

.container {
  display: flex;
  flex-direction: column;
  position: relative;

  @include media($from: $lg) {
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.error {
  margin-top: spacing(2);
  padding: 0;
  color: $warningFG;
  @include typographySmallBodyMedium;

  @include media($from: $lg) {
    margin: spacing(0) spacing(2) spacing(0);
  }
}

.tooltip {
  &:global(.MuiTooltip-tooltip) {
    padding: spacing(2) spacing(3);
    background-color: $tooltip-color;
    border-radius: radius('md');
    color: $grayDarker;
    box-sizing: border-box;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.02), 0 4px 16px rgba(0, 0, 0, 0.1);
    @include typographySmallBody;

    :global(.MuiTooltip-tooltipArrow):global(.MuiTooltip-tooltipPlacementBottom) {
      margin-top: 5px;
    }
  }
}

.title {
  @include typographyLabelBold;
}

.arrow:global(.MuiTooltip-arrow) {
  // MUI uses inline styles to position tooltip arrow, so it's necessary to increase specificity
  transform: none !important;

  &.medium {
    // MUI uses inline styles to position tooltip arrow, so it's necessary to increase specificity
    left: calc(100% - #{$arrow-margin-medium}) !important;
  }

  &.large {
    // MUI uses inline styles to position tooltip arrow, so it's necessary to increase specificity
    left: calc(100% - #{$arrow-margin-large}) !important;
  }

  &::before {
    background-color: $tooltip-color;
  }
}

.coupon {
  border: 1px solid $grayLighter;
  background-color: transparent;
  box-sizing: border-box;
  width: fit-content;
  display: flex;
  align-items: center;
  line-height: 1;
  cursor: pointer;

  .checkbox {
    width: 10px;
    height: 10px;
    padding: 0;
    margin: 0;
    border-color: $grayLight;
  }

  &:hover {
    border-color: $primary;

    .checkbox {
      border-color: $primary;
    }
  }

  &:focus {
    outline: 1px dashed $grayDarker;
    outline-offset: 0.15rem;
  }

  &.selected {
    border-color: $black;

    .checkbox {
      border-color: $black;
      color: $white;
      background-color: $black;
    }
  }

  &.highlighted {
    border-color: $primary;
  }

  &.failed {
    border-color: $errorFG;
  }

  .title {
    @include typographySmallBodyBold;

    color: $black;
  }

  .info_icon,
  .price {
    color: $black;
    @include typographySmallBody;
  }

  &.medium {
    padding: spacing(2) spacing(3);
    border-radius: radius('xs');
    @include typographySmallBody;

    .title {
      margin: 0 spacing(1);
    }

    .price:last-of-type {
      margin-right: 5px;
    }
  }

  &.large {
    padding: spacing(3) spacing(4);
    border-radius: radius('xs');
    @include typographyBody;

    .title {
      margin: 0 spacing(2);
    }

    .price:last-of-type {
      margin-right: 21px;
    }
  }

  .info_icon {
    margin: 0;
    padding: 0;
  }

  &.full_width {
    width: 100%;

    .info_icon {
      margin-left: auto;
    }
  }

  &.v2 {
    background-color: $grayF1;
    border: none;
    border-radius: 1rem;
    height: 1rem;
    margin-right: 4px;
    margin-top: calc(0.25rem);
    padding: 3px 6px;
    width: max-content;

    & .title,
    & .price {
      @include typographyLabelMedium;

      color: $gray12;
      line-height: 1;

      @include media($from: $lg) {
        line-height: 1;
      }
    }
  }

  &.medium .checkbox {
    & > :global(.MuiSvgIcon-root) {
      @include typographySmallBody;
    }
  }

  &.large .checkbox {
    & > :global(.MuiSvgIcon-root) {
      @include typographyH5Medium;
    }
  }
}
