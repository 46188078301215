@use '@styles/theme.module' as *;

.input {
  margin-bottom: spacing(5);

  &:last-of-type {
    margin-bottom: 0;
  }
}

.checkbox {
  margin-bottom: spacing(5);
}

.alert {
  min-width: 100%;
  margin-bottom: spacing(4);

  &.success {
    padding: spacing(2) spacing(4);
  }
}

.phone_number_label {
  @include typographySmallBody;

  color: $grayDark;
  text-transform: uppercase;
}

.phone_number {
  @include typographyH5;

  margin-bottom: spacing(4);
}

.contact_us {
  @include typographySmallBodyMedium;

  color: $black;
  cursor: pointer;
  margin-bottom: 0.3em;

  &:hover {
    color: $primaryDark;
  }
}
