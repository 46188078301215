@use '@styles/theme.module.scss' as *;
@use 'sass:map';

$image-width: 60px;
$spacing-width: spacing(6);

%controller-item {
  margin-right: spacing(2);

  @include media($from: $sm) {
    margin-right: spacing(3);
  }
}

.controllers_wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-shrink: 0;
  margin: spacing(3) spacing(0) spacing(0) calc(#{$image-width} + #{$spacing-width});
  flex-direction: column;
  gap: spacing(4);

  @include media($from: $smMobile) {
    flex-direction: row;
    gap: 0;
    align-items: center;
  }

  @include media($from: $sm) {
    flex-direction: row-reverse;
    margin-left: spacing(0);
  }

  @include media($from: $md) {
    margin-top: spacing(0);
  }

  &.drawer_opened {
    flex-direction: row;
    margin-top: spacing(3);
  }
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 36px;
  height: 36px;
  background-color: $white;
  border: 1px solid $grayLighter;
  border-radius: map.get($radius, 'full');

  @extend %controller-item;
}

.controllers {
  align-items: center;
  display: flex;
  flex-shrink: 0;

  & .button {
    & svg {
      color: $black;
    }

    &:hover,
    &:focus {
      & svg {
        color: $primary;
      }
    }
  }
}

.button_loader {
  @extend %controller-item;
}

.counter {
  margin-left: spacing(2);

  @include media($from: $sm) {
    margin-left: spacing(3);
  }
}

.alert {
  position: absolute;
  z-index: 10;
  right: 0;
  top: 0;
  transform: translateY(-100%);
  max-width: 100%;
}

.icon {
  height: 16px;
  width: 16px;
}

.replace_button {
  margin-right: spacing(4);
  border: 1px solid $grayLighter;
  @include typographyBody;
}

.modified_amount {
  color: $grayDarker;
  display: none;
  @extend %controller-item;

  @include media($from: $sm) {
    display: block;
  }
}

.modified_label {
  @include typographyExtraSmallBodyBold;

  margin-right: spacing(3);
  padding: spacing(1) spacing(2);
  border: 1px solid $primary;
  background-color: $primaryLightest;
  border-radius: 25px;
}
