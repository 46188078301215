@use '@styles/theme.module' as *;

.alternative_modal_title {
  @include typographyH3;
}

.with_margin_bottom {
  margin-bottom: spacing(4);
}

.alternative_modal_title_link {
  color: $primary;
}

.replace_modal {
  @include media($from: $sm) {
    max-width: 763px;
  }
}

.modal_button_wrapper {
  box-shadow: 0 -1px 2px rgb(0 0 0 / 0.05);
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $white;
  z-index: 10;
  padding: spacing(6) spacing(3.5);

  @include media($from: $sm) {
    padding: spacing(6) spacing(9);
  }
}

.content_wrapper {
  margin-bottom: spacing(12);

  @include media($from: $sm) {
    margin-bottom: spacing(9);
  }
}
