@use '@styles/theme.module.scss' as *;
@use 'sass:map';

.link {
  @include typographyButtonsAndLinks;

  text-decoration-line: underline;
  color: inherit;
  border-radius: map.get($radius, 'md');

  &:focus-visible {
    outline: 1px dashed $grayDarker;
    outline-offset: spacing(1);
  }

  &.bold {
    @include typographyButtonsAndLinksBold;
  }
}
