@use '@styles/theme.module' as *;

.modal_wrapper {
  max-width: 100%;

  @include media($from: $sm) {
    width: 640px;
    height: 675px;
  }
  @include media($from: $xl) {
    width: 656px;
  }
}

.title {
  margin-bottom: spacing(7);
  @include typographyH3;
}
