@use '@styles/theme.module.scss' as *;

.flexWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @include media($from: $sm) {
    width: auto;
  }
}

.button {
  min-width: 173px;
  margin-bottom: spacing(5);

  @include media($from: $sm) {
    margin-bottom: spacing(8);
  }
}

.giftCardFlexWrapper {
  display: flex;
  flex-flow: column wrap;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: spacing(4);

  @include media($from: $sm) {
    flex-flow: row nowrap;
    align-items: center;
  }
}

.giftCardWrapper {
  min-height: 84px;
  padding: spacing(5);
  border: 1px solid $grayLighter;
  border-radius: radius('md');
  margin-bottom: spacing(7);

  &.empty {
    background-color: $gray1;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.giftCardWrapperEmpty {
  min-height: 84px;
  padding: spacing(5);
  border: 1px solid $grayLighter;
  border-radius: radius('md');
  margin-bottom: spacing(7);
  background-color: $gray1;

  &:last-child {
    margin-bottom: 0;
  }
}

.giftCardAmount {
  margin-right: spacing(5);
  color: $primary;
  width: max-content;
  @include typographyBodyBold;

  &.empty {
    color: $grayDark;
  }

  &:last-child {
    margin-right: 0;
  }
}

.giftCardAmountEmpty {
  margin-right: spacing(5);
  color: $black;
  width: max-content;
  @include typographyBodyBold;

  &:last-child {
    margin-right: 0;
  }
}

.giftCardFrom {
  @include typographyBodyBold;
}

.giftCardFromInfo {
  color: $grayDark;
  margin-bottom: spacing(5);
  @include typographySmallBody;

  @include media($from: $sm) {
    margin-bottom: 0;
  }
}

.icon {
  height: 16px;
  width: 16px;
  color: $black;
}

.checkbox {
  input[type='checkbox'][disabled] + label {
    cursor: default;
  }
}
