@use '@styles/theme.module' as *;
@use 'sass:map';

.carousel_item {
  position: relative;
  width: 79px;
  height: 79px;
  margin-right: spacing(2);
  border: 2px solid transparent;
  transition: border-color 0.2s ease-in-out;
  border-radius: map.get($radius, 'sm');
  cursor: pointer;

  @include media($from: $sm) {
    width: 81px;
    height: 79px;
  }

  @include media($from: $md) {
    margin-right: 0;
    margin-bottom: spacing(2);
    width: 62px;
    height: 62px;
  }

  @include media($from: $lg) {
    width: 92px;
    height: 92px;
  }

  @include media($from: $xl) {
    width: 128px;
    height: 128px;
  }

  &:focus-visible {
    outline: 1px dashed $grayDarker;
    outline-offset: spacing(1);
  }

  &:last-of-type {
    margin: 0;
  }

  &.selected {
    border-color: $black;
  }

  &:hover {
    border-color: $grayLight;
  }
}

.carousel_container {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  margin: spacing(5) 0 0;

  @include media($from: $md) {
    flex-direction: column;
    margin: 0 0 0 spacing(5);
  }

  img {
    object-fit: contain;
  }
}
