@use '@styles/theme.module.scss' as *;

.subtitle {
  @include typographySmallBody;
}

.with_margin {
  @include media($from: $lg) {
    margin-right: spacing(13);
  }
}

.left_margin {
  margin-left: spacing(1);
}

.fixed_copyright {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $white;
  border-top: 1px solid $grayLight;
  position: fixed;
  width: 100%;
  height: 34px;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
}

@media print {
  .subtitle {
    margin-top: spacing(2);
  }
}
