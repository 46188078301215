@use '@styles/theme.module' as *;

.navigation {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
}

.nav_icon:global(.MuiButtonBase-root) {
  border: 0;
}

.left_arrow {
  margin-right: spacing(3);

  &[disabled] svg {
    color: $grayLighter;
  }
}

.right_arrow {
  &[disabled] svg {
    color: $grayLighter;
  }
}

.arrow_icon {
  color: $black;
}
