@use '@styles/theme.module.scss' as *;

.tab_titles {
  height: 45px;
  width: 100%;
  min-height: 0;
  border-bottom: 1px solid $gray4;

  & :global(.MuiTabs-indicator) {
    background-color: $primary;
    margin: 0 0 2px;
    transition: none;

    @include media($from: $xl) {
      margin: 0;
    }
  }
}

.title {
  text-transform: none;
  color: $grayDark;
  padding: 0;
  min-width: 0;
  min-height: 0;
  height: auto;
  margin-bottom: spacing(6);
  @include typographyBodyBold;

  @include media($from: $xl) {
    line-height: 1.375rem;
    margin-bottom: spacing(6);
  }

  &:not(:first-child) {
    margin-left: spacing(6);
  }

  &:global(.Mui-selected) {
    color: $black !important;
  }

  &:focus {
    outline: 1px dashed $grayDarker;
  }
}
