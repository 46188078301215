@use '@styles/theme.module' as *;

.product_preview {
  display: flex;
  align-items: center;
  margin-bottom: spacing(6);

  @include media($from: $lg) {
    margin-bottom: spacing(7);
  }
}

.image_container {
  position: relative;
  display: flex;
  justify-content: center;
  width: 80px;
  height: 80px;
  min-width: 80px;
  margin-right: spacing(2);
}

.image {
  width: 100%;
  height: 100%;
}

.product_name {
  @include typographyH5Medium;
  @include media($from: $lg) {
    @include typographyH4Medium;
  }
}
