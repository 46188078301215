@use '@styles/theme.module' as *;

$menu-width: 420px;

.dialogs {
  :global(.MuiPaper-root) {
    background-color: $primary;

    @include media($from: $md) {
      width: $menu-width;
    }
  }

  :global(.MuiBackdrop-root) {
    background-color: transparent;
  }

  :global(.MuiDialog-container) {
    justify-content: flex-start;

    @include media($from: $md) {
      margin-left: $menu-width;
    }
  }
}

.top_menu {
  width: 100%;
  display: flex;
  align-items: center;
  margin: spacing(5) 0;
  padding: 0;
  justify-content: flex-start;

  @include media($from: $sm) {
    margin: spacing(6) 0;
  }

  .back_button {
    position: fixed;
    z-index: 1100;
    left: spacing(5);
    width: 32px;
    height: 32px;

    @include media($from: $sm) {
      margin-right: spacing(10);
    }

    @include media($from: $md) {
      visibility: hidden;
    }

    svg path {
      stroke: $white;
    }
  }

  .close_button {
    width: 25px;
    height: 32px;
    padding: 0;
    position: fixed;
    top: spacing(5);
    right: spacing(5);
    z-index: $mobileStepper;

    @include media($from: $sm) {
      top: spacing(6);
      right: spacing(6);
    }

    svg path {
      stroke: $white;
    }
  }
}

.category_header {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 spacing(6) 0 spacing(12);
}

.breadcrumbs {
  :global(.MuiBreadcrumbs-separator) {
    color: $white;
    opacity: 0.6;
  }

  .breadcrumbs_text {
    color: $white;
    opacity: 0.6;
  }
}

.list_wrapper {
  padding: spacing(3) spacing(4) spacing(5) spacing(12);

  @include media($from: $sm) {
    padding-left: 82px;
    padding-right: 50px;
  }

  .list_text {
    max-width: 100%;
    color: $white;
  }
}

.list_item {
  padding: spacing(3) 0;

  &:hover,
  &:focus-within {
    background-color: rgb(0 0 0 / 0.1);
  }
}
