@use '@styles/theme.module' as *;

.wrapper {
  width: 100%;
  position: relative;

  &.drawer_opened {
    overflow: hidden;
    border: spacing(4) solid $gray1;
  }
}

.buttons {
  position: fixed;
  bottom: 0;
  right: 0;

  &.modify_mode {
    bottom: -35px;
  }

  @include media($from: $md) {
    display: none;
  }
}
