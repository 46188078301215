@use '@styles/theme.module' as *;

.wrapper {
  margin-top: spacing(7);

  @include media($from: $sm) {
    margin-top: spacing(11);
  }

  @include media($from: $md) {
    margin-top: spacing(8);
  }

  @include media($from: $lg) {
    margin-top: spacing(11);
  }
}

.title {
  margin-bottom: spacing(7);
  @include typographyBody;
}

.input {
  margin-bottom: spacing(5);

  &:last-of-type {
    margin-bottom: 0;
  }
}

.button {
  margin-top: spacing(7);
  @include typographyBody;
}
