@use '@styles/theme.module.scss' as *;

.tile {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: stretch;
  align-self: flex-start;
  border: 1px solid $primary;
  padding: spacing(5);
  border-radius: spacing(2);
  background-color: transparent;
  cursor: pointer;

  @include media($until: $largeTablet) {
    display: none;
  }

  @include media($from: $largeTablet) {
    height: 190px;
  }

  &:focus-visible {
    outline: 1px dashed $grayDarker;
    outline-offset: spacing(1);
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }
}

.text {
  position: relative;
  width: 100%;
  text-align: left;
  @include typographyBody;

  &::after,
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    background-color: $black;
    width: 1px;
    height: 9px;
  }

  &::after {
    transform: translateY(-50%) rotate(90deg);
  }
}
