@use '@styles/theme.module.scss' as *;

.wrapper {
  position: relative;
  height: 100%;
  display: flex;
  justify-content: flex-end;
}

.account {
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  cursor: pointer;

  &:focus-visible {
    outline: 1px dashed $grayDarker;
    outline-offset: spacing(1);
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }
}

.title {
  margin: 0;
  white-space: nowrap;
  color: $grayDark;
  @include typographySmallBodyBold;

  @include media($from: $xl) {
    @include typographyBodyBold;
  }
}

.popper {
  margin: 0;
  padding: 0;
}

.account_name_loader {
  width: 100px;
}
