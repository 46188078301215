@use '@styles/theme.module' as *;
@use 'sass:map';

.price {
  @include typographyBody;
  @include media($from: $sm) {
    min-width: spacing(10);
    text-align: right;
  }
}

.controllers {
  align-items: center;
  display: flex;
  position: relative;

  @include media($until: $sm) {
    justify-content: space-between;
    width: 100%;
    padding-left: 84px;

    .price {
      order: -1;
      margin-right: spacing(6);
    }
  }

  &.drawer_opened {
    flex-direction: row-reverse;
    margin-left: calc(60px + 1.5rem);

    .price {
      flex: 1;
      text-align: left;
    }
  }
}

.buttons_wrapper {
  display: flex;
}

.icon {
  height: 16px;
  width: 16px;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 36px;
  height: 36px;
  background-color: $white;
  border: 1px solid $grayLighter;
  border-radius: map.get($radius, 'full');
  margin-right: spacing(2);

  @include media($from: $sm) {
    margin-right: spacing(3);
  }
}

.counter {
  margin-right: spacing(2);
}
