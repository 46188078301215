@use '@styles/theme.module' as *;

.wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: spacing(5);
  border: 1px solid $grayLighter;
  border-radius: radius('md');
  margin-bottom: spacing(5);

  @include media($from: $sm) {
    flex-wrap: nowrap;
  }

  &:last-child {
    margin-bottom: 0;
  }
}
