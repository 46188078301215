@use '@styles/theme.module.scss' as *;

.navigation {
  width: 100%;

  @include media($from: $lg) {
    max-width: 1320px;
    margin: 0 auto;
  }

  @include media($from: $lg, $until: $lgbp) {
    margin-bottom: spacing(5);
  }

  @include media($from: $xl) {
    max-width: 1728px;
  }
}

.navbar {
  padding: 0;
  margin: 0 auto;
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  list-style: none;
}

.nav_item_link,
.nav_item_nolink_text {
  display: inline;
  flex: 0 0 auto;
  max-width: 100%;
  text-align: center;
}

.nav_item {
  height: 72px;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  border-bottom: 3px solid transparent;
  color: $grayDark;
  cursor: pointer;
  z-index: auto;

  @include media($from: $lgbp) {
    height: 95px;
  }

  .nav_item_icon {
    height: 32px;
    width: 32px;
    opacity: 0.75;

    @include media($from: $xl) {
      height: 42px;
      width: 42px;
    }
  }

  &.active,
  &:hover,
  &:focus,
  &:active {
    .nav_item_link {
      color: $black;
    }

    .nav_item_icon {
      opacity: 1;
    }
  }

  a:active,
  a:focus {
    outline: 0;
    border: none;
  }

  &:focus-within {
    outline: 1px dashed $grayDark;

    .nav_item_link {
      color: $black;
    }

    .nav_item_icon {
      opacity: 1;
    }
  }

  &.active {
    border-color: $primary;
  }

  &.reorder {
    color: $primary;

    .nav_item_icon {
      color: $primary;
    }

    &.active {
      color: $black;

      .nav_item_icon {
        color: $black;
      }
    }
  }

  &.link {
    cursor: pointer;
  }
}

.nav_item_controls {
  white-space: nowrap;
  text-align: center;
  line-height: 1;
  margin-top: spacing(1);
}

.nav_item_caret {
  background: none;
  border: none;
  padding-left: spacing(1);

  svg {
    width: 0.6rem;
  }
}

.nav_item_caret:focus {
  outline: 1px dashed $grayDark;
}

.nav_dropdown,
.nav_dropdown_menu {
  display: flex;
  flex-wrap: nowrap;
  top: -#{spacing(1)} !important;
  padding: spacing(7) spacing(7) spacing(7) spacing(3);
  border-radius: 12px;
  box-shadow: 0 2px 12px rgb(0 0 0 / 0.1);
  background-color: $white;
  z-index: 1100;
}

.nav_dropdown {
  width: 100%;

  @include media($from: $lg) {
    max-width: 1320px;
  }

  @include media($from: $xl) {
    max-width: 1728px;
  }
}

.nav_dropdown_menu {
  flex-direction: column;
  min-width: 15%;
  padding-left: spacing(7);
}
