@use '@styles/theme.module' as *;

.wrapper {
  position: relative;
}

.inner_circle {
  color: $grayLighter;
}

.outer_circle {
  animation-duration: 1300ms;
  position: absolute;
  left: 0;
}

.light {
  color: $primary;
}

.orange {
  color: $orange;
}
