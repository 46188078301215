@use '@styles/theme.module.scss' as *;

.title {
  color: $black;
  margin-bottom: spacing(7);
  @include typographyH3Bold;

  @include media($from: $sm) {
    margin: 0;
  }
}

.subtitle {
  color: $black;
  @include typographyBody;
}
