@use '@styles/theme.module' as *;

.grid {
  flex-wrap: wrap;
  justify-content: flex-start;
  flex: 1;
  position: relative;

  // blank separator is needed for covering first products per row left separators
  // and making them invisible for user
  &::before {
    content: '';
    position: absolute;
    top: spacing(7);
    bottom: spacing(0);
    left: spacing(0);
    width: spacing(5);
    background-color: $white;
    z-index: 1;
  }

  .grid_item {
    position: relative;

    &:has(> div[class*='Skeleton']) {
      border-bottom: none;
    }

    &::before {
      content: '';
      position: absolute;
      top: spacing(9);
      bottom: spacing(2);
      left: calc(#{spacing(5)} / 2);
      width: 1px;
      background-color: $grayLighter;
    }

    &.v2::before {
      content: none;
    }

    @include media($until: $xsMobile) {
      flex-grow: 0;
      max-width: 50%;
    }
  }
}
