// stylelint-disable no-descending-specificity
@use '@styles/theme.module' as *;

.popper {
  z-index: $searchAutocomplete;
}

.search_input {
  position: relative;
  width: 100%;

  @include media($from: $md) {
    width: auto;
  }

  [aria-expanded='true'] {
    @include media($from: $lg) {
      border-radius: spacing(6) spacing(6) 0 0;
    }
  }

  .icon_clear {
    cursor: pointer;
    border: none;
    background-color: transparent;
    width: 25px;
    height: 25px;
    justify-content: center;
    display: flex;
    visibility: hidden;
    padding: 0;

    &:focus-visible {
      outline: 1px dashed $grayDarker;
      outline-offset: spacing(1);
    }

    &:focus:not(:focus-visible) {
      outline: none;
    }
  }

  &.focus_within {
    .icon_clear {
      visibility: visible;
    }
  }

  :global(.MuiAutocomplete-popper) {
    width: 100% !important;
    top: 17px !important;
    box-shadow: none;

    @include media($xs, $sm) {
      height: calc(100vh - 140px);
      top: 10px !important;
      background-color: $white;
      border-radius: 0;
      overflow-x: hidden;
      overflow-y: auto;
      padding: 0 spacing(2);
    }

    @include media($from: $sm) {
      top: 21px !important;
    }

    @include media($from: $md) {
      top: 45px !important;
    }

    @include media($from: $lg) {
      box-shadow: 0 9px 9px rgb(0 0 0 / 0.15);
      max-height: fit-content;
      border-bottom-right-radius: spacing(6);
      border-bottom-left-radius: spacing(6);
      top: 0 !important;
    }
  }

  :global(.MuiPaper-root) {
    border-bottom-right-radius: spacing(6);
    border-bottom-left-radius: spacing(6);

    :global(.MuiAutocomplete-listbox) {
      padding: 0 0 spacing(4);

      // Viewport height - headers fixed height
      max-height: calc(100vh - 133px);
      overflow-y: auto;

      & > div:last-of-type {
        margin-bottom: spacing(3);
      }

      @include media($from: $md) {
        padding-bottom: spacing(8);
      }

      @include media($from: $lg) {
        padding-bottom: spacing(4);

        & > div:last-of-type {
          margin-bottom: spacing(5);
        }
      }
    }
  }

  :global(.MuiAutocomplete-root.Mui-focused) {
    box-shadow: 0 5px 18px rgb(0 0 0 / 0.15);
  }

  .search_autocomplete_wrapper {
    border-radius: 24px;
    background-color: $white;

    &:focus-within {
      @include media($from: $md, $until: $lg) {
        width: calc(100% - #{spacing(10)});
      }

      .icon_clear {
        visibility: visible;

        @include media($from: $md) {
          position: absolute;
          right: -#{spacing(10)};
        }

        @include media($from: $lg) {
          position: static;
        }
      }
    }

    :global(.MuiFormControl-root) {
      width: 100%;

      :global(.MuiOutlinedInput-root) {
        width: 100%;
        padding: 0 22px 0 spacing(6);
        min-height: 48px;

        &:global(.Mui-focused) {
          filter: none;
        }
      }
    }
  }

  .search_autocomplete_border {
    @include media($from: $lg) {
      border-radius: 30px 30px 0 0;
    }
  }
}
