@use '@styles/theme.module' as *;

.dialog_content {
  width: 100%;
  height: 100%;
  padding: 0;

  @include media($from: $md) {
    width: 443px;
  }

  @include media($from: $lg) {
    height: fit-content;
    width: fit-content;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  padding: spacing(4);
  width: 100%;
  height: 100%;
  background-color: $white;
  overflow: auto;
  @include scroll;
  @include scrollMargin;

  @include media($from: $sm) {
    padding: spacing(8) spacing(14);
  }

  @include media($from: $md) {
    width: 443px;
    padding: spacing(8);
  }

  @include media($from: $lg) {
    box-shadow: 0 0 20px rgb(0 0 0 / 0.06), 0 16px 32px rgb(0 0 0 / 0.15);
    border-radius: spacing(4);
    height: fit-content;
    max-height: 100vh;
    position: absolute;
    padding: spacing(9);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @include media($from: $xl) {
    width: 554px;
    padding: spacing(10);
  }

  &.primary {
    background-color: $primaryDark;

    @include media($from: $lg) {
      background-color: $white;
    }
  }
}

.navigation {
  margin-top: spacing(5);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0;
  margin-bottom: spacing(8);

  @include media($from: $sm) {
    display: flex;
    align-items: flex-start;
    margin-bottom: spacing(9);
  }

  @include media($from: $md) {
    margin-top: 0;
    flex-grow: 0;
    align-items: center;
  }

  @include media($from: $lg) {
    margin-bottom: spacing(7);
  }

  &.primary {
    @include media($from: $sm) {
      flex-grow: 0;
    }
  }
}

.logo_wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @include media($from: $sm) {
    flex-direction: row;
  }
}

.content {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-items: flex-start;
}

.content_heading {
  flex: none;
  display: flex;
  flex-direction: column;
  @include typographySmallBody;
}

.icon_top_navigation {
  height: 34px;
  width: 155px;
  margin-left: 0;
  margin-right: auto;
  visibility: visible;

  @include media($from: $sm) {
    visibility: visible;
    margin-left: spacing(12);
  }

  @include media($from: $md) {
    margin-left: spacing(5);
  }

  @include media($from: $lg) {
    margin-left: 0;
  }

  &.without_back_icon {
    @include media($from: $md) {
      margin-left: spacing(0);
    }
  }
}

.back_to_shop_button:global(.MuiButtonBase-root) {
  border: 0;
  margin-bottom: spacing(8);

  @include media($from: $sm) {
    margin-bottom: 0;
  }

  &.primary {
    @include media($from: 0, $until: $lg) {
      background-color: transparent;

      &:hover {
        border-color: transparent;

        svg {
          color: $white;
        }
      }
    }
  }
}

.back_to_shop_icon,
.close_icon {
  width: 24px;
  height: 24px;

  &.primary {
    @include media($from: 0, $until: $lg) {
      color: $white;
    }
  }
}

.close_button:global(.MuiButtonBase-root) {
  border: 0;

  .close_icon {
    height: 16px;
    width: 16px;
  }

  @include media($from: $lg) {
    position: absolute;
    top: 40px;
    right: 40px;
  }

  &.primary {
    @include media($from: 0, $until: $lg) {
      background-color: transparent;

      &:hover {
        border-color: transparent;

        svg {
          color: $white;
        }
      }
    }
  }
}

.without_margin {
  @include media($from: $lg) {
    margin-left: 0;
  }
}

.hide_close_button {
  margin-bottom: 0;
}
