@use '@styles/theme.module' as *;

.title {
  margin-bottom: spacing(7);
  @include typographyH3;
}

.input {
  & button {
    @include typographySmallBody;
    @include media($from: $sm) {
      @include typographyBody;
    }
  }
}
