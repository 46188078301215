@use '@styles/theme.module' as *;

.configuration_modal {
  @include media($from: $sm) {
    max-width: 443px;
  }

  @include media($from: $xl) {
    max-width: 520px;
  }
}

.mini_product_page {
  @include media($from: $sm) {
    max-width: 800px;
  }

  @include media($from: $lg) {
    max-width: 1000px;
  }

  @include media($from: $lgbp) {
    max-width: 1400px;
    max-height: 800px;
  }
}
