@use '@styles/theme.module' as *;

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: spacing(6) 0 spacing(6);
}

.title {
  color: $grayDark;
  margin-bottom: spacing(2);
}

.time_slot_info {
  @include media($until: $sm) {
    max-width: 8rem;
  }
}
