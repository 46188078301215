@use '@styles/theme.module' as *;
@use 'sass:map';

.input {
  margin: 0 0 spacing(4) 0;
  position: relative;

  & :global(.MuiOutlinedInput-root) {
    padding: 0 !important;
  }

  & :global(.MuiOutlinedInput-input.MuiAutocomplete-input) {
    padding: 13px spacing(5);
    @include media($from: $md) {
      padding: 13px spacing(6);
    }
  }
}

.autocomplete_wrapper {
  position: relative;
}

.list_wrapper {
  background: $white;
  box-shadow: 0 0 16px rgb(0 0 0 / 0.04), 0 8px 20px rgb(0 0 0 / 0.1);
  border-radius: map.get($radius, 'xlg');
}

.list {
  padding: spacing(6);

  @include scroll;
}

.matched {
  color: $black;
}

.option {
  cursor: pointer;
  margin-bottom: spacing(6);
  color: $grayDark;
  @include typographyBodyMedium;

  &:hover,
  &:global(.Mui-focused) {
    color: $primary;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.express_label {
  margin-right: spacing(4);
  height: 0.675rem;
  min-width: 3.125rem;
  color: $deliveryPassFG;
}
