@use '@styles/theme.module.scss' as *;

.share_button {
  @include media($until: $lg) {
    margin-top: spacing(4);
  }
}

.share_button,
.fallback_button {
  display: inline-flex;
  cursor: pointer;
  align-items: center;
  background: none;
  padding: spacing(2) spacing(5);
  margin-right: spacing(2);
  font-size: 1rem;
  line-height: 1.4;
  border: 1px solid $primary;
  border-radius: 40px;

  &:hover {
    color: $primary;
  }

  &:focus-within {
    outline: 1px dashed $black;
    outline-offset: 2px;
  }

  @include media($until: $lg) {
    padding: spacing(4);
    justify-content: center;
  }
}

.share_icon {
  width: 1rem;
  height: 1rem;
  margin-right: spacing(2);
}

.main,
.fallback {
  @include media($until: $lg) {
    display: flex;
    flex-direction: column;
    gap: spacing(4);
    justify-content: space-between;
  }
}

.label {
  margin: spacing(4) 0;

  @include media($until: $lg) {
    margin: spacing(4) 0 0;
  }
}
