@use '@styles/theme.module' as *;

.payment_btn {
  display: flex;
  justify-content: flex-start;
  color: $black;
  padding: spacing(4) spacing(3);
  border: 1px solid $grayLighter;
  border-radius: 8px;
  margin-bottom: spacing(2);
}

.icon {
  margin-right: spacing(1);

  &_tab {
    display: flex;
    align-items: center;
  }
}
