@use '@styles/theme.module' as *;

.credits_item_wrapper {
  padding: spacing(5);
  border: 1px solid $grayLighter;
  border-radius: spacing(2);
  margin-bottom: spacing(5);

  @include media($from: $sm) {
    padding: spacing(6) spacing(5);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.title {
  @include typographyBodyBold;
}

.date_info {
  margin-bottom: spacing(5);

  @include media($from: $sm) {
    margin-bottom: 0;
  }

  .subtitle {
    color: $grayDark;
  }
}

.select_payment {
  width: 100%;
  margin: 0 0 spacing(4) 0;

  @include media($from: $sm) {
    width: fit-content;
    margin: 0 spacing(4) 0 spacing(4);
  }
}

.see_details {
  width: 100%;
  margin-left: auto;

  @include media($from: $sm) {
    width: fit-content;
  }
}
