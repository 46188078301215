@use '@styles/theme.module' as *;

.wrapper {
  @include media($from: $sm) {
    width: 640px;
  }
  @include media($from: $md) {
    width: 900px;
  }
  @include media($from: $lg) {
    width: 640px;
  }
  @include media($from: $xl) {
    width: 840px;
  }
}
