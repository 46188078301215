@use '@styles/theme.module' as *;

.image_wrapper {
  @include media($from: $md) {
    display: flex;
  }

  &.disabled {
    opacity: 0.5;
  }
}

.image_container {
  width: 100%;
  height: 264px;
  position: relative;

  @include media($from: $sm) {
    height: 345px;
  }

  @include media($from: $md) {
    height: 390px;
  }

  @include media($from: $lg) {
    height: 460px;
  }

  @include media($from: $xl) {
    height: 583px;
  }

  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}

.skeleton {
  &_image {
    width: 100%;
    height: 100%;
  }
}
