@use '@styles/layouts' as *;
@use '@styles/theme.module' as *;

.wrapper {
  display: flex;
  flex-flow: column wrap;
  flex-grow: 1;

  > div {
    max-width: 100%;
  }
}

.container {
  @include container;
}
