@use '@styles/theme.module' as *;

.container {
  @include media($from: $largeTablet) {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-gap: spacing(5);
  }

  .content {
    grid-column: 2 / span 10;
    @include media($from: $lgbp) {
      grid-column: 2 / span 6;
    }

    .banner_container {
      margin: spacing(7) spacing(0) spacing(9);
    }

    .link {
      color: $black;
      @include typographyButtonsAndLinks;
    }
  }
}
