@use '@styles/theme.module' as *;
@use 'sass:map';

.popper {
  min-width: 200px;
  max-width: 300px;
  z-index: $appBar + 4;
  background: #fff;
  text-align: center;
  margin: spacing(3) 0 0;
  padding: spacing(2) spacing(3) spacing(7);
  box-shadow: 0 0 16px rgb(0 0 0 / 0.04), 0 8px 20px rgb(0 0 0 / 0.1);
  border-radius: map.get($radius, 'xlg');
  @include media($from: $xl) {
    min-width: 300px;
    max-width: 400px;
  }
}

.title {
  text-align: center;
  @include typographyH5Medium;

  @include media($from: $xl) {
    .title {
      @include typographyH5Medium;
    }
  }
}

.box_button {
  display: flex;
  justify-content: right;
}

.button {
  &_exit {
    background-color: transparent !important;
    border: 0 !important;
    color: $black;
    right: -(spacing(1));

    @include hover {
      & svg {
        color: $primary !important;
      }
    }
  }

  &_signup {
    margin: spacing(6) 0;
  }
}
