@use '@styles/theme.module.scss' as *;
@use 'sass:map';

.button {
  border-radius: map.get($radius, 'md');

  &:disabled {
    background-color: $gray2;
    border-color: transparent;
    color: $grayDark !important;
  }

  &:focus-visible {
    outline: 1px dashed $grayDarker;
    outline-offset: spacing(1);
  }

  &.full_width {
    width: 100%;
  }
}

.loading_icon {
  color: $white;
}

.contained {
  &_light {
    color: $white;
    background-color: $primary;

    &:active {
      background-color: $primaryDark;
    }

    @include hover {
      // Fix for Safari desktop 15.3<
      background-color: $primaryDark !important;
    }
  }

  &_dark {
    color: $black;
    background-color: $white;

    &:active {
      background-color: $gray2;
    }

    @include hover {
      // Fix for Safari desktop 15.3<
      background-color: $gray2 !important;
    }
  }

  &_orange {
    color: $white;
    background-color: $orange;

    &:active {
      background-color: $orange;
    }

    @include hover {
      // Fix for Safari desktop 15.3<
      background-color: $orangeDark !important;
    }
  }

  &_red {
    color: $white;
    background-color: $red;

    &:active {
      background-color: $red;
    }

    @include hover {
      // Fix for Safari desktop 15.3<
      background-color: $redDark !important;
    }
  }
}

.outlined {
  &_light {
    color: $black;
    background-color: $white;
    border: 1px solid $primary;

    &:active {
      color: $primary;
    }

    @include hover {
      // Fix for Safari desktop 15.3<
      color: $primary !important;
      background-color: $white;
    }

    .loading_icon {
      color: $primary;
    }
  }

  &_dark {
    color: $white;
    background-color: transparent;
    border: 1px solid $white;

    &:active {
      color: $grayLighter;
      border-color: $grayLighter;
    }

    @include hover {
      color: $grayLighter;

      // Fix for Safari desktop 15.3<
      border-color: $grayLighter !important;
    }
  }

  &_orange {
    color: $black;
    background-color: $white;
    border: 1px solid $orange;

    &:active {
      color: $orange;
    }

    &:hover {
      // Fix for Safari desktop 15.3<
      background-color: $white !important;
      color: $orange;
    }
  }

  &_elevated {
    color: $black;
    background-color: transparent;
    border: 1px solid $grayLighter;
    box-shadow: 0 2px 12px rgb(0 0 0 / 0.1);

    .loading_icon {
      color: $primary;
    }

    &:hover {
      background-color: $gray2;
    }
  }
}

.text {
  padding: 0;

  &_orange {
    color: $orange;

    &:active {
      color: $orange;
    }

    @include hover {
      color: $orangeDark !important;
      background-color: transparent;
    }
  }
}

.small {
  @include buttonGeneralStyles;

  padding: spacing(2) spacing(5);
  @include typographyBody;

  @include media($from: $lg) {
    padding: spacing(2) spacing(5);
  }
}

.large {
  @include buttonGeneralStyles;

  padding: spacing(3.5) spacing(6);
  min-height: 48px;
  @include typographyBody;

  &.underline_light,
  &.underline_dark {
    min-height: 0;
  }
}

.underline {
  &_light,
  &_orange,
  &_dark {
    background-color: transparent;
    border: none;
    min-width: fit-content;
    padding: 0;
    position: relative;
    border-radius: 0;

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: $black;
    }
  }

  &_light,
  &_dark {
    color: $black;

    &:active {
      color: $primary;
      background-color: transparent;
    }

    @include hover {
      color: $primary;
      background-color: transparent;

      &::before {
        background-color: $primary;
      }
    }
  }

  &_orange {
    color: $black;

    @include hover {
      color: $orangeDark;
      background-color: transparent;

      &::before {
        background-color: $orangeDark;
      }
    }

    &:active {
      color: $orangeDark;
      background-color: transparent;
    }
  }
}
