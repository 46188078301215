@use '@styles/theme.module' as *;

.skiplink {
  margin: spacing(2) spacing(1);
  position: absolute;
  transform: translateX(-200%);
  text-decoration: underline;
  border: 1px solid $primaryDarkest;
  color: $primaryDarkest;
  background-color: $white;
  padding: spacing(2) spacing(4);
  @include typographySmallBody;
  @include media($from: $lg) {
    @include typographyBody;
  }

  &:hover {
    color: $primaryDark;
    cursor: pointer;
  }

  &:focus {
    color: $primaryDark;
    transform: translateX(1em);
    outline: 1px dashed $grayDarker;
    outline-offset: spacing(1);
  }
}
