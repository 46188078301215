@use '@styles/theme.module' as *;

.tab_item {
  display: flex;
  flex-direction: column;

  .title {
    @include typographyBody;
  }

  .value {
    @include typographyBodyBold;
  }
}
