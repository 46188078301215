@use '@styles/theme.module' as *;

.iframe_wrapper {
  height: 100%;
  margin-top: spacing(4);

  iframe {
    width: 100%;
    min-height: 900px;
    border: 0;
  }
}
