@use '@styles/theme.module' as *;

.container {
  &_loading {
    pointer-events: none;
    opacity: 0.5;
  }

  @include media($from: $largeTablet) {
    padding: 0 spacing(5) spacing(0);
  }
}

.heading {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding-bottom: spacing(7);
  @include typographyH5Medium;

  @include media($from: $sm) {
    justify-content: flex-start;
  }

  span {
    text-transform: lowercase;
  }

  .edit {
    height: fit-content;
    margin-left: spacing(2);
    @include typographyBodyMedium;
  }
}

.footer {
  text-align: right;
  margin-right: spacing(4);
  margin-bottom: spacing(4);

  @include media($from: $sm) {
    margin-top: spacing(4);
  }
}

.horizontal_padding {
  padding: spacing(0) spacing(5);
}
