@use '@styles/theme.module' as *;

.hide {
  display: none;
}

.item_info_panel {
  min-width: 320px;
  min-height: 635px;
}
