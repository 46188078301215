@use '@styles/theme.module' as *;
@use 'sass:map';

.plan_wrapper {
  padding-top: spacing(7);
}

.title {
  margin: spacing(11) 0 spacing(7);

  @include media($until: $sm) {
    margin: spacing(8) 0 spacing(5);
  }
}

.expired_title {
  color: $deliveryPassFG;
  padding: 0 spacing(8);
  @include typographyBodyBold;

  @include media($until: $sm) {
    padding: 0 spacing(6);
  }
}

.card {
  border: 1px solid $grayLighter;
  border-radius: map.get($radius, 'lg');
  overflow: hidden;
}

.plan_details {
  background-color: $gray1;
  padding: 0 spacing(8);

  @include media($until: $sm) {
    padding: 0 spacing(6);
  }
}

.divider {
  color: $grayLighter;
}

.free_one_year_title {
  margin: spacing(7) 0;

  @include media($until: $sm) {
    margin: spacing(7) 0 spacing(5);
  }
}

.link {
  span {
    color: $grayDark;
    border-color: $grayDark;
  }
}

.plans_section {
  padding: spacing(5) 0;
}
