@use '@styles/theme.module.scss' as *;

.wrapper {
  position: relative;
  margin-top: 0;
}

.description {
  margin-left: spacing(1);
  padding-top: 1px;
  @include typographySmallBody;
}

.unavailable_info_section {
  display: flex;
}

.unavailable {
  color: $errorFG;
  @include typographySmallBody;
}
