@use '@styles/theme.module' as *;

.button_group_content {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  min-height: 62px;
  border: 1px solid $grayLighter;
  box-sizing: border-box;
  border-radius: spacing(2);
  align-self: stretch;
  flex-grow: 0;
  padding: spacing(4);
  margin-bottom: spacing(5);
  color: $black;

  @include media($from: $md) {
    padding: spacing(3);
  }
}

.send_btn {
  color: $primary;
  margin-right: spacing(1);

  &::before {
    background-color: $primary;
  }
}

.text {
  @include typographySmallBody;
}

.icon {
  width: 17px;
  height: 17px;
  flex: none;
  margin: 0 spacing(3);
  color: $grayDark;
}

.link_text {
  border-color: $primaryLight;
}

.link:not(:focus-visible) {
  color: $primary;
}
