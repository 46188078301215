@use '@styles/theme.module' as *;

.controls {
  white-space: nowrap;
}

.container {
  @include media($from: $sm) {
    max-width: 444px;
  }

  > div {
    min-width: auto;
  }
}

.one_year_modal {
  :global(.line_bottom) {
    height: 1px;
    background-color: $grayLighter;
    border: none;
    margin: spacing(5) 0;
    display: block;
  }

  :global(.title_section) > h2 {
    display: none;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;

  ul {
    color: $black !important;

    li {
      margin-top: spacing(2) !important;

      &::first-letter {
        color: $deliveryPassFG;
      }
    }
  }

  :global(.title_section) > h2 {
    display: none;
  }

  :global(.title_section) > h2:first-child {
    text-transform: lowercase;

    &::first-letter {
      text-transform: capitalize;
    }
  }

  :global(.subtitle) {
    display: none;
  }

  :global(.plan_duration) {
    margin: spacing(1) 0;
    @include typographyBody;
  }

  :global(.line_top) {
    height: 1px;
    background-color: $grayLighter;
    border: none;
    margin: spacing(5) 0;
  }

  :global(.line_bottom) {
    display: none;
  }
}

.title {
  margin: spacing(4) 0;
  @include typographyH4Bold;

  svg {
    transform: translateY(spacing(2));
  }
}

.sub_title {
  color: $grayDark;
  @include typographyBody;
}

.terms {
  margin-bottom: spacing(6);
  @include typographyBodyMedium;
}

.terms_text {
  vertical-align: baseline;
  @include typographyBodyBold;
}
