@use '@styles/theme.module' as *;

.modal_window {
  width: 100%;

  @include media($from: $sm) {
    max-width: 520px;
  }
}

.payments {
  margin: spacing(1) 0 spacing(7) spacing(1);
}

.title_wrapper {
  margin-bottom: spacing(4);
}

.subtitle {
  color: $grayDark;
  margin: -#{spacing(3)} 0 spacing(7);
}

.error_wrapper {
  display: flex;
  align-items: center;
  margin: -#{spacing(4)} auto spacing(4);
  width: fit-content;

  .icon {
    color: $errorFG;
    margin-right: spacing(1);
  }

  .error_message {
    width: fit-content;
    color: $errorFG;
  }
}
