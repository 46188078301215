@use '@styles/theme.module.scss' as *;

.container {
  min-height: 18px;
  min-width: 145px;

  @include media($from: $md) {
    min-height: 14px;
  }

  //  extra small device width is covered via breakpoints ($sm  / 2)
  @include media($until: $sm / 2) {
    min-width: spacing(14);
    width: spacing(14);
  }
}

.link {
  white-space: nowrap;
  margin: 0;
  color: $errorFG;
  @include typographyBody;

  &::before {
    background-color: $errorFG;
  }

  &.is_in_hamburger {
    &::before {
      background-color: $white;
    }

    &:hover {
      &::before {
        background: none;
      }
    }
  }
}

.title {
  margin-bottom: spacing(1);
  color: $grayDark;
}

.success_delivery_link {
  text-decoration-line: underline;
  color: $grayDark;
  text-align: start;
  min-width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline;
  border-radius: 0;
  @include typographySmallBody;

  @include media($from: $xl) {
    @include typographyBody;
  }
}

.success_delivery_link::before {
  display: none;
}

.arrow {
  margin: 0 0 2px spacing(1);
}

.loadingBlock {
  width: 70%;
}

.address_label {
  text-transform: capitalize;
}

.address_label_screen_reader_only {
  position: absolute;
  width: 100%;
  margin-left: -100%;
  opacity: 0;
}
