@use '@styles/theme.module' as *;

.delivery {
  margin-right: spacing(5);
}

.delivery_label {
  color: $grayDark;
}

.delivery_value {
  color: $black;
  display: inline-flex;
  align-items: flex-end;
  white-space: nowrap;
  @include typographyBodyMedium;
}

.formatted_price {
  margin-right: spacing(1);
}

.or {
  margin-right: spacing(1);
}

.free_pass_button {
  text-transform: capitalize;
  border: none;
  cursor: pointer;
  background: none;
  padding: 0;
  margin: 0;
  line-height: 1.2 !important;

  &:focus-visible {
    outline: 1px dashed $grayDarker;
    outline-offset: spacing(1);
  }
}

.link {
  color: $deliveryPassFG;
  border-bottom: 1px solid $deliveryPassFG;
  @include typographyBody;

  &:hover {
    color: $deliveryPassFG !important;
    border-color: $deliveryPassFG !important;
  }
}

.delivery_pass_modal {
  width: auto;
}

.hide_delivery {
  display: none;
}
