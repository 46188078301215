@use '@styles/theme.module.scss' as *;

.hello {
  background-color: $primaryDarker;
  color: $white;
  padding: spacing(2);

  svg {
    color: $white;
  }

  a {
    color: $white;
    font-weight: bold;
    text-decoration: underline;
  }
}
