@use '@styles/theme.module' as *;

.button_group_content {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  min-height: 62px;
  border: 1px solid $grayLighter;
  box-sizing: border-box;
  border-radius: spacing(2);
  align-self: stretch;
  flex-grow: 0;
  padding: spacing(4);
  margin-bottom: spacing(5);
  color: $black;

  @include media($from: $md) {
    padding: spacing(3);
  }
}

.content_heading {
  display: flex;
  flex-direction: column;
  @include typographySmallBody;

  @include media($from: $lg, $until: $xl) {
    width: 200px;
  }
}

.link {
  color: $primary;
}

.chat_link_text {
  padding: 0;
  text-transform: none;
  color: $primary;
  @include typographySmallBody;

  &::before {
    background-color: $primary;
  }
}

.link_text {
  border-color: $primaryLight;
}

.secondary_link {
  display: inline-block;
  margin: 0;
  padding: 0;
}

.icon {
  width: 17px;
  height: 17px;
  flex: none;
  margin: 0 spacing(3);
  color: $grayDark;
}
