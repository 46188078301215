@use '@styles/theme.module' as *;

.subtotal_value {
  color: $black;
  margin-left: spacing(1);
  @include typographyBodyBold;

  @include media($from: $lg) {
    @include typographyH5Medium;
  }
}

.subtotal_wrapper {
  display: flex;
  margin-left: spacing(1);
}

.previous {
  color: $grayDark;
  text-decoration: line-through;
  margin-left: spacing(1);
  @include typographyBodyMedium;

  @include media($from: $lg) {
    @include typographyH5Medium;
  }
}

.current {
  color: $sale;
  @include typographyBodyMedium;

  @include media($from: $lg) {
    @include typographyH5Medium;
  }
}
