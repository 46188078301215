@use '@styles/theme.module' as *;

.title {
  margin-bottom: spacing(7);
  @include typographyH3;
}

.content {
  margin-bottom: spacing(7);

  &_text,
  &_link {
    @include typographyBody;
  }

  &_link {
    margin-left: spacing(1);
  }
}

.button {
  width: 100%;
}
