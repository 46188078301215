@use '@styles/theme.module' as *;

.group_scale_label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: spacing(2) spacing(3);
  min-height: 24px;
  border: 1px solid $grayLighter;
  border-radius: radius('xs');
  cursor: pointer;

  &:hover,
  &:focus {
    border: 1px solid $primary;
    outline-style: none;
  }

  &_icon {
    height: 8px;
  }

  &_message {
    padding: 0;
    margin: 0;
    @include typographySmallBodyBold;
  }

  &.active {
    border: 1px solid $black;
  }

  &.large {
    padding: spacing(3) spacing(4);
  }

  &.v2 {
    align-items: center;
    background-color: $groupScaleBackgoundGreen;
    border: none;
    border-radius: 1rem;
    color: $primary;
    height: 1rem;
    min-height: unset;
    margin-right: 4px;
    margin-top: calc(spacing(1));
    padding: 0 calc(spacing(3) / 2);
    width: max-content;

    &:focus-visible {
      outline: 1px dashed $grayDarker;
      outline-offset: 0.15rem;
    }

    & .group_scale_label_message {
      @include typographyLabelMedium;

      margin-right: calc(spacing(1) / 2);
      line-height: 1;

      @include media($from: $lg) {
        line-height: 1;
      }
    }

    & .group_scale_label_icon {
      @include typographyLabelMedium;

      stroke: $primary;
      height: 11px;
    }
  }
}
