@use '@styles/theme.module.scss' as *;

.wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  @include media($from: $xs) {
    width: 100%;
    margin-right: 0;
  }
}

.content {
  border: 1px solid $grayLighter;
  border-radius: spacing(2);
  padding: spacing(5);
  position: relative;
  cursor: pointer;
  height: 100%;
  min-height: 130px;

  @include media($from: $sm) {
    padding-right: spacing(11);
  }

  &:focus-within {
    outline: 1px dashed $grayDarker;
  }
}

.disabled_card {
  position: absolute;
  z-index: 10;
  width: 100%;
  min-height: 130px;
  height: 100%;
  background: rgb(0 0 0 / 0.1);
  border-radius: spacing(2);
  cursor: not-allowed;
}

.active {
  .content {
    border-color: $black;
    pointer-events: none;
  }
}

.add_new_card_wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  &::after {
    content: '\a\b';
    display: block;
    margin-top: spacing(2);
    margin-right: spacing(4);
    opacity: 0;
    @include typographyBody;
  }
}

.add_new_card_text_line {
  margin: 0;
  padding: 0;
  @include typographyBody;
}

.add_new_card {
  border-color: $primary;
  color: $black;
  min-height: 130px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  @include typographyBody;
}

.address,
.name {
  margin: 0;
}

.phone,
.details {
  margin: 0;
  color: $grayDark;
  word-break: break-word;
  @include typographyBody;
}

.express_label {
  color: $deliveryPassFG;
  height: 0.625rem;
  position: absolute;
  right: 15px;
  bottom: 70%;
}

.check {
  position: absolute;
  right: 22px;
  width: 15px;
  height: 12px;
  bottom: spacing(6);
}

.icon {
  position: absolute;
  transform: translateY(-50%);
  right: 28px;
  bottom: 24px;
  height: 8px;
  width: 8px;
}

.button {
  margin-top: spacing(2);
  margin-right: spacing(4);
  margin-bottom: spacing(0);
  @include typographyBody;
}

.company_name {
  word-break: break-word;
  margin-bottom: spacing(2);
}

.instructions {
  display: flex;
  flex-direction: column;
}
