@use '@styles/theme.module.scss' as *;

.wrapper {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  .label {
    @include typographySmallBodyBold;
  }

  &.v2 {
    display: block;

    .label {
      @include typographyLabelMedium;

      color: $gray12;
      line-height: 1;

      @include media($from: $lg) {
        line-height: 1;
      }
    }

    .alternatives_button {
      align-items: center;
      background-color: $groupScaleBackgoundGreen;
      border: none;
      border-radius: 1rem;
      color: $primary;
      cursor: pointer;
      display: flex;
      height: 1.25rem;
      justify-content: center;
      min-height: unset;
      margin-top: calc(spacing(1));
      padding: 0 calc(spacing(3) / 2);
      width: 100%;

      &[disabled] {
        cursor: not-allowed;
      }

      & .button_label {
        @include typographyLabelMedium;

        margin-right: calc(spacing(1) / 2);
        line-height: 1;

        @include media($from: $lg) {
          line-height: 1;
        }
      }

      & .button_label_icon {
        stroke: $primary;
        height: 11px;
      }
    }
  }
}
