@use '@styles/theme.module.scss' as *;
@use 'sass:map';

.wrapper {
  border-radius: map.get($radius, 'button');
  border: 1px solid $primary;
  background-color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  transition: 0.2s ease-in-out;
  text-align: center;
  padding: spacing(2) spacing(2) spacing(2) spacing(3);
  min-width: 42px;
  height: 28px;
  cursor: pointer;

  &_quantity {
    text-align: center;
    margin-right: 2px;
  }

  &_button {
    height: 12px;
    width: 12px;

    .icon {
      stroke: $primary;
      width: 8px;
      height: 8px;
    }
  }
}
