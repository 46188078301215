@use '@styles/theme.module.scss' as *;

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: spacing(7) 0;

  @include media($until: $sm) {
    align-items: flex-start;
    flex-direction: column;
  }
}

.title {
  color: $grayDark;
  margin-bottom: spacing(2);
}

.description {
  @include typographyBody;
}

.buttons {
  display: flex;

  > a {
    color: $black;
    border-color: $deliveryPassFG;
    @include hover {
      color: $deliveryPassDark !important;
    }
  }

  @include media($until: $sm) {
    margin-top: spacing(5);
    width: 100%;
    flex-direction: column;

    > button {
      width: 100%;
    }
  }
}
