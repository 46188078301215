@use '@styles/theme.module' as *;

.modal {
  @include media($from: 1024) {
    width: 652px;
  }
  @include media($from: 1440) {
    width: 753px;
  }
  @include media($from: $xl) {
    width: 980px;
  }
}

.saved_money {
  color: $primary;
}
