@use '@styles/theme.module' as *;
@use 'sass:map';

.wrapper {
  position: absolute;
  width: 100%;
  top: 48px;
  left: 0;
  right: 0;
  background: $white;
  box-shadow: 0 0 16px rgb(0 0 0 / 0.04), 0 8px 10px rgb(0 0 0 / 0.1);
  border-radius: map.get($radius, 'xlg');
  padding: spacing(6);
  color: $grayDarker;
  z-index: 99;
  @include typographyBodyMedium;
}

.button {
  color: $black;
  text-decoration: underline;
  border: none;
  background-color: transparent;
  padding: 0;
  @include typographyBodyMedium;

  &:hover {
    color: $primary;
    cursor: pointer;
  }

  &:focus {
    color: $primary;
  }
}
