@use '@styles/theme.module' as *;

.wrapper {
  &:not(:last-child),
  &:first-child {
    margin-bottom: spacing(7);

    @include media($from: $sm) {
      margin-bottom: spacing(12);
    }
  }
}

.title {
  margin-bottom: spacing(7);
}
