@use '@styles/theme.module' as *;

.product_card {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $white;
  cursor: pointer;
  outline: none;

  &_wrapper {
    position: relative;
    height: 100%;
  }

  &_main_info {
    flex: 1 1 auto;
    margin: 0 0 spacing(3) 0;
  }

  &_promo {
    margin: spacing(3) 0;
  }

  &_price_block {
    display: grid;
    grid-gap: calc(#{spacing(1)} / 2);
  }

  &_price_selector {
    text-align: right;
    margin-left: spacing(1);
    min-width: 80px;
  }

  &_sub_info {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    &_scale_price {
      color: $sale;
      @include typographySmallBody;
    }
  }
}

.product_hover {
  &:hover {
    .hovered_tile_image {
      background-color: rgb(0 0 0 / 0.1);
    }

    .hovered_tile_name {
      text-decoration: underline;
    }
  }
}
