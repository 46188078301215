@use '@styles/theme.module' as *;

.headline {
  padding-bottom: spacing(5);
  margin: 0;
  border-bottom: 1px solid $grayLighter;
  @include typographyH3;

  @include media($from: $sm) {
    padding: 0 spacing(6) spacing(5);
  }

  @include media($until: $sm) {
    border: none;
  }
}

.headline_with_tabs {
  @include typographyH3;

  border-bottom: none;

  @include media($from: $sm) {
    margin: 0 spacing(6) 0;
  }
}

.title {
  color: $black;
}

.title_counter {
  color: $grayDark;
  @include typographyH3;
}

.header_block {
  display: flex;
  align-items: center;
  padding-bottom: spacing(7);
  border-bottom: 1px solid $grayLighter;

  .headline {
    margin: 0;
    padding-bottom: spacing(0);
    padding-right: spacing(2);
    padding-left: spacing(0);
    border-bottom: none;
  }
}
