@use '@styles/theme.module.scss' as *;

$copyright-footer-height: 34px;

.wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 100%;
  width: 100%;
  margin: auto 0;
  padding-bottom: $copyright-footer-height;
}

.title {
  color: $black;
  margin-bottom: spacing(5);
  @include typographyH1Bold;

  @include media($from: $sm) {
    margin-bottom: spacing(4);
  }
}

.subtitle {
  color: $black;
  @include typographyBody;
}
