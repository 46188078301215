@use '@styles/theme.module' as *;

.submit_block {
  position: relative;
  margin-top: spacing(2);
  padding-top: spacing(7);
}

.submit_error {
  margin-bottom: spacing(2);
}
