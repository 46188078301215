@use '@styles/theme.module.scss' as *;

.previewbox {
  display: flex;
  align-items: center;
  position: fixed;
  margin-left: spacing(3);
  z-index: $draftIndicator;
  padding: spacing(4) spacing(6);
  bottom: spacing(10);
  border: 3px solid $primary;
  background-color: $white;

  svg {
    align-self: flex-start;
    margin-right: spacing(4);
    color: red;
    width: spacing(8);
    height: spacing(8);
  }
}
