@use '@styles/theme.module' as *;

.wrapper {
  margin-bottom: spacing(7);

  .tiles_container {
    .cart_tile:first-child {
      margin: 0;
    }

    .cart_tile:last-child {
      border-bottom: none;
    }
  }

  .subtotal {
    margin-top: spacing(6);
    padding: spacing(0) spacing(5);
  }

  &.unavailable_section {
    @include media($until: $sm) {
      background-color: $warningBG;
      border: 1px solid rgb(0 0 0 / 0.1);
      border-left: none;
      border-right: none;
      padding-bottom: 0;

      &.gray {
        background-color: $gray2;
      }

      & .title_wrapper {
        margin: calc(spacing(6) - 1.25rem) spacing(4) spacing(6);
        padding: 0;
      }

      & .bottom_divider {
        display: none;
      }
    }

    & .tiles_container {
      @include media($until: $sm) {
        .cart_tile {
          margin: 0 spacing(4);
          border-bottom: 1px solid rgb(0 0 0 / 0.1);
        }

        .cart_tile:first-child {
          margin-top: calc(-1 * spacing(4));
        }
      }

      @include media($from: $sm) {
        .cart_tile:first-child {
          margin: 0;
        }

        .cart_tile:last-child {
          border-bottom: none;
        }
      }
    }

    & .subtotal {
      align-items: center;
      display: flex;
      justify-content: flex-end;
      margin-top: spacing(6);
      color: $black;
      margin-left: spacing(5);

      @include media($until: $sm) {
        margin: spacing(6) spacing(4);
      }

      & .subtotal_value {
        margin-left: 0.75rem;
      }
    }
  }
}

.bottom_divider {
  margin: spacing(7) spacing(5);
}

.note {
  padding-left: spacing(5);
  margin-top: spacing(6);
  color: $grayDark;
}

.unavailable {
  background-color: $warningBG;
  border-radius: radius('xlg');
  border: 1px solid rgb(0 0 0 / 0.1);

  @include media($until: $sm) {
    border-radius: 0;
    border: none;
  }
}

.out_of_stock {
  background-color: $gray2;
  border-radius: radius('xlg');
  border: 1px solid rgb(0 0 0 / 0.1);

  @include media($until: $sm) {
    border-radius: 0;
    border: none;
  }
}

.title_wrapper {
  display: flex;
  padding-left: spacing(5);
  margin-bottom: spacing(6);
}

.department_title_wrapper {
  display: flex;
  padding-left: 1.25rem;
  margin-top: 2.75rem;
  margin-bottom: 1.5rem;
}
