@use '@styles/theme.module' as *;

.quantity {
  border-color: $primary;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 0;
  right: 0;
}

.skeleton {
  margin-left: auto;
}

.custom_order {
  display: flex;
  justify-content: flex-end;
}
