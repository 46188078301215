@use '@styles/theme.module.scss' as *;

.wrapper {
  position: absolute;
  background: $white;
  box-shadow: 0 0 16px rgb(0 0 0 / 0.04), 0 8px 20px rgb(0 0 0 / 0.1);
  border-radius: 16px;
  width: max-content;
  padding: spacing(8) spacing(6);
  z-index: 2;
  right: 0;
  bottom: 0;
  transform: translate(0, 100%);

  &::before {
    content: '';
    position: absolute;
    width: 100px;
    height: 15px;
    right: 0;
    top: -10px;
  }
}

.innerWrapper {
  padding: 0 spacing(2);
}

.links {
  @include scroll;

  color: black;
  padding: 0;
  max-height: 60vh;
  overflow-y: scroll;
  margin: 0;
}

.greetings {
  @include typographyH5Medium;

  color: black;
  display: flex;
  font-feature-settings: 'liga' off;
  margin: spacing(0) spacing(0) spacing(1);
}

.since {
  @include typographyBody;

  padding-bottom: spacing(6);
  margin: 0;
  border-bottom: 1px solid $grayLighter;
  color: $grayDark;
}

.item {
  @include typographyBody;

  border: none;
  background-color: transparent;
  padding: spacing(0);
  cursor: pointer;
  height: 24px;
  display: flex;
  white-space: nowrap;
  margin: spacing(5) spacing(0);
  width: max-content;

  &::before {
    display: none;
  }

  &:hover {
    color: $primary;
  }

  &:focus-visible {
    outline: 1px dashed $grayDarker;
  }

  &:last-child {
    margin: spacing(0);
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }

  svg {
    margin-right: spacing(3);
    color: $grayDark;
  }
}

.link {
  &:focus {
    outline: 1px dashed #444;
    outline-offset: 4px;
  }
}

.button {
  &:focus {
    outline: 1px dashed #444;
    outline-offset: 4px;
  }
}

.listItem {
  width: 100%;
  list-style-type: none;

  &:first-child {
    margin-top: spacing(6);
  }
}

.withBorder {
  border-bottom: 1px solid $grayLighter;
  padding-bottom: spacing(9);
  margin-bottom: spacing(6);
}
