@use 'sass:map';

$radius: (
  'none': 0,
  'xs': 2px,
  'sm': 4px,
  'md': 5px,
  'lg': 8px,
  'xlg': 16px,
  'button': 40px,
  'full': 100%,
  'hybrid': 8px 8px 0 0,
);

@function radius($size) {
  @return map.get($radius, $size);
}

@each $rad, $value in $radius {
  // stylelint-disable
  :export {
    #{unquote($rad)}: $value;
  }
}
