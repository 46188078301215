@use '@styles/theme.module' as *;

.controls {
  // to override mui's border-radius
  .recipientMessage.recipientMessage {
    border-radius: 2rem;
  }

  .error {
    margin: 0 auto spacing(2);

    svg {
      padding-top: 1px;
    }
  }
}

.subtitle {
  @include typographyH5Medium;
}
