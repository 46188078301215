@use '@styles/theme.module' as *;

.helper_text {
  align-items: center;
  display: flex;
}

.helper_text_icon {
  height: 12px;
  width: 12px;
  color: $primary;
  margin-left: spacing(1);
}
