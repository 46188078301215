@use '@styles/theme.module' as *;

.recaptcha {
  margin-top: spacing(3);
}

.link:not(:focus-visible) {
  span {
    color: $primary;
    border-bottom-color: $primary;
  }
}
