@use '@styles/theme.module.scss' as *;
@use 'sass:map';

.loader_container {
  position: absolute;
  left: -1px;
  right: -1px;
  top: -1px;
  bottom: -1px;
  cursor: default;
  background-color: $white;
  border: 1px solid;
  border-radius: map.get($radius, 'button');

  &.primary {
    border-color: $primary;
  }

  &.secondary {
    border-color: orange;
  }
}

.loader_wrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.loader {
  display: block;

  &.primary {
    color: $primary;
  }

  &.secondary {
    color: orange;
  }
}
