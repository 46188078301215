@use '@styles/theme.module.scss' as *;

.tabs_flex_container {
  border-bottom: 2px solid $grayLighter;
}

.tabs_root {
  width: 100%;
  margin-bottom: spacing(6);
}

.tab {
  padding: 0;
  min-width: 0;
  text-transform: none;
  color: $black;
  min-height: 0;
  margin: 14px 1px;

  @include typographyBodyBold;

  &:not(:first-child) {
    margin-left: spacing(6);
  }

  &:global(.Mui-selected) {
    color: $black;
  }

  @include focusVisible {
    outline: auto;
  }
}
