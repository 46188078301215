@use '@styles/theme.module' as *;

.title {
  margin-bottom: spacing(7);
}

.list {
  margin-bottom: spacing(7);

  @include media($from: $sm) {
    margin-bottom: spacing(12);
  }

  &:last-child {
    margin-bottom: spacing(0);
  }
}

.container {
  @include media($from: $largeTablet) {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-gap: spacing(5);
  }
}

.content {
  grid-column: 2 / span 10;
  @include media($from: $lgbp) {
    grid-column: 2 / span 6;
  }
}
