@use '@styles/theme.module.scss' as *;

@mixin transition {
  transition: all 0.5s ease-in-out;
}

.layout {
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr auto;
  row-gap: spacing(4);

  @include media($from: $sm) {
    row-gap: spacing(5);
  }

  @include media($from: $largeTablet) {
    grid-template-columns: auto auto 1fr auto;
  }

  @include media($from: $lg) {
    grid-template-columns: auto 1fr auto auto;
    column-gap: spacing(2);
  }
  @include media($from: $xl) {
    grid-template-columns: 4fr 8fr 4fr;
    column-gap: spacing(2);
  }

  &_menu {
    @include media($from: $lg) {
      display: none;
    }
  }

  &_start {
    display: flex;
    align-items: center;
  }

  &_search {
    @include transition;

    @include media($until: $largeTablet) {
      grid-column: 1 / -1;
      grid-row: 2 / 3;
    }
  }

  &_end {
    display: flex;
    align-items: center;

    @include media($until: $largeTablet) {
      grid-column: 3 / 4;
    }
    @include media($from: $xl) {
      justify-content: center;
    }
  }
}

.account,
.fdSwitch {
  margin-right: spacing(7);
  @include media($until: $lg) {
    display: none;
  }
}

.top_line {
  padding: spacing(6) 15px spacing(4) 15px;
  border: none;

  @include media($from: $sm) {
    padding: spacing(6) spacing(5) spacing(5) spacing(5);
  }

  @include media($from: $largeTablet) {
    padding: spacing(7);
  }

  @include media($from: $lg) {
    padding: 28px 60px spacing(6);
  }

  @include media($from: $xl) {
    padding: spacing(7) spacing(13) spacing(6) spacing(13);
  }
}

.logo {
  display: flex;
  opacity: 1;

  @include transition;
  @include media($from: $largeTablet) {
    margin-right: spacing(3.5);
  }

  @include focusVisible {
    outline: auto;
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }

  svg {
    width: 138px;
    height: 26px;

    @include media($from: $md) {
      width: 154px;
      height: 24px;
    }

    @include media($from: $lg) {
      width: 154px;
      height: 29px;
    }

    @include media($from: $xl) {
      width: 154px;
      height: 29px;
    }
  }
}

.cosLogo {
  display: flex;
  opacity: 1;

  @include transition;
  @include media($from: $largeTablet) {
    margin-right: spacing(3.5);
  }

  @include focusVisible {
    outline: auto;
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }

  svg {
    width: 138px;
    height: 48px;

    @include media($from: $md) {
      width: 144px;
      height: 48px;
    }

    @include media($from: $lg) {
      width: 144px;
      height: 56px;
    }
  }
}

.logo_wrapper {
  display: flex;
  cursor: pointer;

  &:focus-visible {
    outline: 1px dashed $grayDarker;
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }
}

.top_line_menu_icon,
.reorder {
  cursor: pointer;
  align-self: center;
  flex-shrink: 0;
}

.reorder {
  color: $primary;
  display: flex;
  align-items: center;
  padding: 0;
  @include typographySmallBody;

  @include media($from: $lg) {
    display: none;
  }

  svg {
    max-width: 22px;
    margin-right: spacing(2);

    @include media($from: $sm) {
      max-width: none;
    }
  }

  &:hover {
    background-color: transparent;
  }
}

.reorder_title {
  @include media($until: $xsMobile + 55) {
    display: none;
  }
}

.top_line_menu_icon {
  margin-right: spacing(2);

  @include media($from: $sm) {
    margin-right: spacing(4);
  }

  @include media($from: $largeTablet) {
    margin-right: spacing(2);
  }
}

.delivery {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding-left: spacing(3.5);
  border-left: 1px solid $grayLighter;
  opacity: 1;
  max-width: 100%;
  width: max-content;
  margin-right: spacing(2);

  @include transition;
  @include media($until: $largeTablet) {
    display: none;
  }
}

.delivery_title {
  white-space: nowrap;
  margin: 0 0 spacing(1);
  color: $grayDark;
  @include typographySmallBodyBold;

  @include media($from: $xl) {
    @include typographyBodyBold;
  }
}

.drawer {
  :global(.MuiPaper-root) {
    width: 100%;
    background-color: $primaryDark;

    @include media($from: $md) {
      width: auto;
    }
  }
}

.show {
  @include transition;

  opacity: 1;
  max-width: 100vw;
}

.hide {
  @include transition;

  opacity: 0;
  max-width: 0;
  overflow: hidden;
}

.delivery_pass_popup {
  @include media($from: $sm) {
    width: 402px;
    height: 328px;
  }
}

.hidden {
  visibility: hidden;
}
