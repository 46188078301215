@use '@styles/theme.module' as *;

.wrapper {
  display: flex;
  flex-direction: row;
  place-content: center;
  background: linear-gradient(to left, $gray1 calc(100% - spacing(5)), transparent 0);

  & > * {
    flex-basis: 50%;
    max-width: 50%;

    &::before {
      content: none;
    }
  }

  :global(.MuiGrid-item) {
    padding-left: spacing(5);

    & :global(.product_tile_card) {
      background-color: transparent !important;
    }

    @media (max-width: 380px) {
      flex-grow: 0;
    }
  }
}

.banner {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 280px;
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 0 spacing(2) !important;
  margin: spacing(7) 0;

  & * {
    @include typographySmallBody;
  }

  & > *:not(.safety_zone) {
    display: flex;
    flex-direction: column;
    place-content: center;
    align-items: center;
    margin-bottom: spacing(7);
  }

  &_button {
    cursor: pointer;
    padding: spacing(2) 0;
    white-space: nowrap;
    width: 75%;
    margin-bottom: spacing(1);
    place-self: center;
    border: none;
    border-radius: 40px;
    @include typographyBody;
  }
}

.safety_zone {
  width: 180px;
  height: 135px;
  margin: 0 auto;
}
