/* stylelint-disable no-descending-specificity */
@use '@styles/theme.module.scss' as *;
@import '@styles/mixins/dropdown-form-control';

$padding-content: spacing(3);
$padding-title: 28px;
$height-list-item: 150px;
$border-radius: 100px;

.form_control {
  @include formControl;
}

.dropdown_wrapper {
  display: flex;
  position: relative;

  &.mini {
    height: 36px;
  }

  &.medium {
    height: 40px;
  }

  &.standard {
    height: 48px;

    @include media($from: $xl) {
      height: 52px;
    }
  }

  &.large {
    height: 52px;
  }
}

.dropdown {
  background-color: $white;
  position: relative;
  height: 100%;
  width: 100%;
  @include typographyButtonsAndLinksMedium;

  &:global(.MuiOutlinedInput-root) {
    border-radius: $border-radius;
    box-sizing: border-box;

    & > :global(.MuiSelect-select) {
      padding-left: spacing(5);
    }

    & :global(.MuiSelect-icon) {
      position: absolute;
      right: spacing(5);
      top: 50%;
      transform: translate(0, -50%);
      height: 10px;
      width: 10px;
    }

    & :global(.MuiSelect-iconOpen) {
      transform: translate(0, -50%) rotate(180deg);
    }
  }

  svg {
    color: $black;
    z-index: 10;
  }

  [aria-expanded='true'] ~ svg {
    transform: rotate(180deg);
  }

  &:global(.Mui-disabled) svg {
    color: $grayDark;
  }

  & > :global(.MuiSelect-select) {
    padding: spacing(3) spacing(7) spacing(3) 0;
  }

  & > :global(.MuiOutlinedInput-notchedOutline) {
    border-radius: $border-radius;
    border: 1px solid $grayLight !important;
  }

  &:global(.Mui-focused) > :global(.MuiOutlinedInput-notchedOutline) {
    border-color: $black !important;
  }

  &:global(.MuiInputBase-formControl):not(.error):has(div[aria-expanded='false']) {
    :global(.MuiOutlinedInput-notchedOutline) {
      border-color: rgb(0 0 0 / 0.23) !important;
    }
  }

  &:global(.Mui-focused.MuiInputBase-formControl):has(div[aria-expanded='false']) {
    :global(.MuiOutlinedInput-notchedOutline) {
      border-color: $black !important;
    }
  }

  &:hover > :global(.MuiOutlinedInput-notchedOutline) {
    border-color: $primary !important;
  }

  &.error > :global(.MuiOutlinedInput-notchedOutline) {
    border-color: $errorFG !important;
  }

  &:global(.Mui-disabled) > :global(.MuiOutlinedInput-notchedOutline) {
    border-color: $grayLighter !important;
    background-color: $gray2 !important;
  }
}

.menu {
  & > :global(.MuiPaper-root) {
    padding: $padding-content spacing(1) 0 $padding-content;
    box-shadow: 0 0 16px rgb(0 0 0 / 0.04), 0 8px 20px rgb(0 0 0 / 0.1);
    border-radius: 10px;
    @include scroll;

    & > :global(.MuiList-root) {
      padding: 0;
      max-height: $height-list-item;
      overflow-y: auto;

      :global(.Mui-disabled) {
        color: $grayDark;
      }

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-button:end:increment {
        height: 16px;
        display: block;
        background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
        height: 26px;
        background-color: $grayLighter;
        border-radius: 10px;
      }

      & :global(.MuiMenuItem-root) {
        max-width: 400px;
        margin: 0;
        padding: 0 0 $padding-content;
        background-color: rgb(0 0 0 / 0);
        white-space: normal;

        @include hover {
          color: $primary;

          .addtional_info {
            color: $black;
          }
        }
      }
    }

    .addtional_info {
      padding-right: spacing(2);
    }
  }
}

.dropdown_label {
  @include typographyBody;
}

.dropdown_item {
  white-space: pre;
  @include typographyBody;
  @include media($from: $xl) {
    @include typographyBodyMedium;
  }
}

.error_text {
  margin: spacing(1) 0 0 $padding-content;
  color: $errorFG;
}

.item_with_pic {
  display: flex;
  flex-wrap: wrap;

  span:last-child {
    white-space: pre-line;
  }
}
