@use '@styles/theme.module.scss' as *;
@use 'sass:map';

@mixin default {
  background: $white;
  color: $black;

  &:disabled {
    border-color: $gray2;
    background-color: $gray2;
    color: $grayDark;
    cursor: not-allowed;
  }

  &:enabled:active {
    background-color: $white;
  }

  &.error {
    border-color: $errorFG;
  }
}

.button {
  &.primary {
    @include default;

    border-color: $primary;

    &:enabled:hover {
      color: $primary;
      border-color: $primaryDark;
    }
  }

  &.secondary {
    @include default;

    border-color: $orange;

    &:enabled:hover {
      color: $orange;
      border-color: $orangeDark;
    }
  }

  &.tertiary {
    @include default;

    border-color: $grayLight;

    &:enabled:hover {
      border-color: $primary;
    }
  }
}
