@use '@styles/theme.module' as *;

.popper {
  z-index: 3;
}

.tooltip {
  &:global(.MuiTooltip-tooltip) {
    padding: 0;
    background: none;
    filter: drop-shadow(0 0 12px rgb(0 0 0 / 0.02)) drop-shadow(0 4px 16px rgb(0 0 0 / 0.1));

    &:global(.MuiTooltip-tooltipPlacementBottom) {
      margin-top: spacing(2);
    }
  }
}
