@use '@styles/theme.module' as *;

.description {
  &_block {
    margin-top: spacing(4);
  }

  &_item {
    margin: 0;
    @include typographyBodyBold;
  }

  &_list {
    padding: 0;
    margin: 0;

    &_item {
      list-style: none;
      margin-top: 0;
    }
  }
}
