@use './theme.module.scss' as *;

@mixin container() {
  width: 100%;
  margin: 0 auto;
  padding: 0 spacing(4);
  max-width: $xl + px;

  @include media($sm) {
    padding: 0 spacing(5);
  }

  @include media($md) {
    padding: 0 spacing(7);
  }

  @include media($lg) {
    padding: 0 spacing(11);
  }

  @include media($xl) {
    padding: 0 spacing(13);
  }
}
