@use '@styles/theme.module' as *;

.alert {
  border-radius: spacing(2);
  margin-bottom: spacing(11);

  :global(.MuiAlert-message) {
    width: 100%;
    display: block;
    padding: 0 spacing(4);

    @include media($from: $smMobile) {
      display: flex;
      justify-content: space-between;
    }

    .wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: spacing(3);

      @include media($from: $smMobile) {
        width: 60%;
        margin-bottom: 0;
      }

      .icon {
        margin-right: spacing(3);
      }

      .text {
        color: $black;
        @include typographyBody;
      }
    }

    .button {
      width: 100%;
      border-color: $grayLight;
      background-color: $primaryLightest;

      @include media($from: $smMobile) {
        width: fit-content;
      }
    }
  }
}
