@use '@styles/theme.module.scss' as *;

.flexWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  @include media($from: $sm) {
    flex-direction: row;
    align-items: center;
    width: auto;
  }
}

.button {
  min-width: 173px;
  margin-bottom: spacing(5);

  @include media($from: $sm) {
    margin-bottom: spacing(8);
  }
}

.giftCardFlexWrapper {
  display: flex;
  flex-flow: column wrap;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 0;

  @include media($from: $sm) {
    flex-flow: row nowrap;
    align-items: center;
  }
}

.giftCardWrapper {
  min-height: 84px;
  padding: spacing(5);
  border: 1px solid $grayLighter;
  border-radius: radius('md');
  margin-bottom: spacing(7);
  position: relative;

  &.empty {
    background-color: $gray1;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.giftCardWrapperEmpty {
  min-height: 84px;
  padding: spacing(5);
  border: 1px solid $grayLighter;
  border-radius: radius('md');
  margin-bottom: spacing(7);
  background-color: $gray1;

  &:last-child {
    margin-bottom: 0;
  }
}

.giftCardAmount {
  margin-right: spacing(5);
  color: $black;
  @include typographyBodyBold;

  &.empty {
    color: $grayDark;
  }

  &:last-child {
    margin-right: 0;
  }
}

.giftCardAmountEmpty {
  margin-right: spacing(5);
  color: $black;
  @include typographyBodyBold;

  &:last-child {
    margin-right: 0;
  }
}

.giftCardTitle {
  word-break: break-all;
  @include typographyBodyBold;
}

.giftCardInfo {
  color: $grayDark;
  margin-bottom: spacing(1);
  @include typographySmallBody;

  @include media($from: $sm) {
    margin-bottom: 0;
  }
}

.icon {
  height: 16px;
  width: 16px;
  color: $black;
}

.controlsContainer {
  display: flex;
  align-items: center;
  margin-top: spacing(5);

  @include media($from: $sm) {
    margin-top: 0;
  }
}

.purchaseGiftCardButton {
  border: 1px solid $grayLighter;
  flex: 1;
  @include typographySmallBodyMedium;

  @include media($from: $lg) {
    @include typographyBodyMedium;
  }

  @include media($from: $sm) {
    flex: auto;
  }
}

.viewPdfButton {
  min-width: 105px;
  margin-right: spacing(2);
}

.pdfLink {
  background-color: transparent;
  border-radius: 40px;
  border: 1px solid $grayLighter;

  &Container {
    border: none;
  }

  &Text {
    text-decoration: none;
    color: $black;
    text-transform: none;
    line-height: 1.25;
  }

  &:hover &Text {
    color: $primary;
  }
}

.successAlert.successAlert {
  position: absolute;
  left: 0;
  right: 0;
  top: -55px;
  width: 100%;
  max-height: 48px;
  padding: spacing(4);
  box-shadow: 0 2px 12px rgb(0 0 0 / 0.1);

  & div {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.checkIcon {
  width: 13px;
  margin-right: spacing(2);
  color: $primary;
}
