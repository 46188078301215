@use '@styles/theme.module' as *;

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: spacing(7);
}

.title {
  color: $grayDark;
  margin: spacing(6) 0 spacing(2);
}

.phone_link {
  color: $primary;
  border-bottom: 1px solid $primary;
  @include typographyBodyBold;

  &:focus {
    outline: 1px dashed $grayDarker;
    outline-offset: spacing(1);
  }
}

.change_CTA_text {
  @include media($until: $sm) {
    max-width: 11rem;
  }
}
