@use '@styles/theme.module' as *;
@use 'sass:math';

$gap: spacing(6);

@function get-slide-width($columns-count, $gap) {
  $average-gap: calc($gap * ($columns-count - 1) / $columns-count);

  @return calc(100% / $columns-count - $average-gap);
}

.header {
  display: flex;
  margin-bottom: spacing(7);
  align-items: center;
}

.navigation {
  margin-left: auto;
}

.with_underline {
  border-bottom: 1px solid $black;
}

.carousel {
  width: 100%;
  background-color: $white;

  :global(.swiper-wrapper) {
    height: auto;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  :global(.swiper-slide) {
    height: unset;
    min-height: 100%;
  }
}

.slide {
  // сurrent styles needed for preventing content blinking in loading process
  height: unset;
  min-height: 100%;
  margin-right: $gap;
  margin-bottom: spacing(10);
  width: get-slide-width(2, $gap);

  @include media($from: $sm) {
    width: get-slide-width(4, $gap);
  }

  @include media($from: $lg) {
    width: get-slide-width(6, $gap);
  }
}

.separator::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;

  // related to .slide $gap value
  left: calc(-#{$gap / 2});
  width: 1px;
  background-color: $grayLighter;
}

.title {
  flex: 0 1 auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  @include typographyH5Medium;
}

.confirmationTitle {
  @include typographyH5Bold;

  color: $grayDark;
}

.title_button {
  @include typographySmallBody;

  display: none;
  cursor: pointer;
  color: $primary;
  margin-left: spacing(3);

  &::before {
    background-color: $primary;
  }

  &:hover {
    color: $black;

    &::before {
      background-color: $black;
    }
  }

  @media (min-height: 900px) {
    display: inline-block;
  }
}
