@use '@styles/theme.module' as *;
@import '../Input/Input.module';

.button {
  @include inputDefaultStyles;

  & :global(.MuiOutlinedInput-root) {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: 6px;

    @include media($xs) {
      & :global(.MuiButton-contained) {
        position: absolute;
        min-height: 36px;
        right: 6px;
        top: calc(50% - 36px / 2);
      }
    }

    @include media($lg) {
      & :global(.MuiButton-contained) {
        position: absolute;
        min-height: 36px;
        right: 6px;
        top: calc(50% - 36px / 2);
      }
    }

    @include media($xl) {
      & :global(.MuiButton-contained) {
        position: absolute;
        min-height: 40px;
        right: 6px;
        top: calc(50% - 40px / 2);
      }
    }
  }

  & :global(.MuiOutlinedInput-input) {
    padding-left: spacing(6);
  }

  .error {
    color: $sale !important;
  }

  &.fullWidth {
    width: 100%;
  }
}

.submitted :global(.MuiOutlinedInput-root) {
  padding-right: spacing(6);
}
