@use '@styles/theme.module.scss' as *;

.socials_list {
  list-style: none;
  padding: 0;
  margin: 0;

  &_item {
    display: flex;
    align-items: center;
    @include typographyBody;

    &_icon {
      display: flex;
      margin-right: spacing(3);
    }

    &_button {
      margin-left: spacing(3);
    }
  }
}
