@use '@styles/theme.module.scss' as *;

.media {
  .m_button {
    display: inline-block;
    padding: 1rem 1.5rem;
    margin: 0;
    line-height: 1.5;
    text-align: center;
    background-color: $primary;
    color: $white;
    cursor: pointer;
    text-decoration: none;
    border-radius: 2rem;

    &:focus,
    &:hover {
      background-color: $primaryDark;
    }
  }

  .m_fullwidth {
    width: 100%;
  }
}
