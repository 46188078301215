@use '@styles/theme.module' as *;

.modal {
  @include media($from: $sm) {
    width: fit-content;
  }
}

.modal_wrapper {
  max-width: 350px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  @include media($from: $xl) {
    max-width: 410px;
  }

  .heading {
    margin-bottom: spacing(7);
    @include typographyH3;
  }

  .info_text {
    color: $grayDark;
    margin-bottom: spacing(7);
    @include typographyBody;
  }

  .button {
    @include typographyBodyMedium;

    &_close {
      margin-bottom: spacing(4);
    }
  }
}
