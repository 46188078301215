@use '@styles/theme.module' as *;

.edit_modal {
  max-width: 100%;

  @include media($from: $sm) {
    max-width: 634px;
  }
}

.form {
  margin-top: spacing(2);
}

.title {
  margin-bottom: spacing(2);
  @include typographyH4Medium;
}

.divider {
  margin: spacing(4) 0;
}

.button {
  margin-top: spacing(4);
}

.with_margin_right {
  margin-right: spacing(2);
}

.field_wrapper {
  position: relative;
}

.image_wrapper {
  border: 1px solid $black;
  background-color: $white;
  position: absolute;
  right: 0;
  z-index: 100;
}

.icon {
  visibility: visible;
  height: 24px;
  cursor: pointer;
  fill: $primaryLight;
  color: $white;
}
