@use '@styles/theme.module' as *;

$hide-fields-transition: all 0.5s ease-in-out;

.hide_fields {
  transition: $hide-fields-transition;
  max-height: 0;
  overflow: hidden;
  padding-top: spacing(0);
  visibility: hidden;
}

.hide_fields_opened {
  transition: $hide-fields-transition;
  height: max-content;
  max-height: 500px;
  overflow: hidden;
  padding-top: spacing(2);
  visibility: visible;
}

.unattended_annotation {
  margin-bottom: spacing(4);

  &_title {
    @include typographyBodyBold;
  }
}

.field {
  margin-bottom: spacing(4);
}
