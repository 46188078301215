@use '@styles/theme.module' as *;

.payment_method {
  display: flex;
  justify-content: space-between;
  margin-bottom: spacing(5);
  width: 100%;

  label {
    width: 100%;
    align-items: center;
  }
}

.payment_info {
  width: 100%;
  margin: -#{spacing(1)} -#{spacing(7)} 0 0;
}

.email {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: spacing(8);
}
