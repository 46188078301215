@use '@styles/theme.module' as *;

.tile_label {
  display: flex;
  align-items: center;
  height: 12px;
  background-color: $white;
  text-transform: uppercase;
  padding: 0 spacing(1);
  @include typographyLabelBold;

  &_icon {
    height: 12px;
    width: 12px;
    padding-right: spacing(1);
  }
}

.tile_label_container {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;

  &.v2 {
    position: static;
    padding: 0;

    &.tile_label {
      @include typographyLabel;

      color: $gray8C;
      margin-top: spacing(1);
      padding: 0;
      text-transform: capitalize;
    }

    &.tile_prep_time {
      @include typographyLabelMedium;

      align-items: center;
      background-color: $grayF1;
      border: none;
      border-radius: 1rem;
      color: $gray12;
      height: 1rem;
      min-height: unset;
      margin-right: 4px;
      margin-top: calc(0.25rem);
      padding: 0 calc(0.75rem / 2);
      text-transform: lowercase;
      width: max-content;
    }
  }
}
