@use '@styles/theme.module' as *;

.content {
  gap: spacing(7);

  // override mui's styles
  .input.input {
    padding-left: 0;
  }
}

.save_button {
  width: 100%;
  @include typographyBodyMedium;
}
