@use '@styles/theme.module.scss' as *;

.time_slots_btn {
  :global(.MuiAlert-root) {
    display: flex;
  }

  @include media($from: $largeTablet) {
    > button {
      display: none;
    }
  }
}

.content_wrapper {
  padding: spacing(0);
  overflow: auto;
  height: 100%;

  @include media($from: $md) {
    padding-bottom: spacing(12);

    &.has_recommended_carousel {
      padding-bottom: 0;
    }
  }
}

.error_wrapper {
  margin-bottom: spacing(8);
}

.order_footer {
  padding: spacing(0);
  margin-bottom: spacing(8);

  @include media($from: $sm) {
    padding: spacing(0) spacing(5);
  }

  @include media($from: $lg) {
    margin-bottom: spacing(9);
  }
}

.sidecart_footer {
  background-color: $gray1;
  grid-area: footer;
}
