@use '@styles/theme.module' as *;

.input_address {
  margin-bottom: spacing(4);
}

.enter_address {
  color: $grayDark;
  margin-bottom: spacing(4);
  @include typographyBody;
}

.enter_address_input {
  margin-bottom: spacing(4);

  fieldset:global(.MuiOutlinedInput-notchedOutline) {
    top: 0;
  }
}

.notification_wrapper {
  margin-bottom: spacing(3);
}

.alert_wrapper {
  margin-bottom: spacing(3);
}
