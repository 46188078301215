@use '@styles/theme.module.scss' as *;

.alert {
  margin-left: -#{spacing(3.5)};
  z-index: 99999;
  @media (min-width: 375px) {
    left: 30px;
  }
  @include media($from: $sm) {
    position: absolute;
    top: auto;
    left: 0;
    margin: spacing(3) 0 0 spacing(3.5);
  }

  @include media($from: $md) {
    margin: spacing(2) 0 0 0;
  }

  @include media($from: $xl) {
    margin: spacing(2) 0 0 0;
  }
}

.alert_text_group {
  @include typographyBody;
}

.alert_link {
  white-space: normal;
  color: $errorFG;
  text-decoration-line: none;
  margin: 0;
  @include typographyBody;
}

.link {
  color: $errorFG;
  text-decoration-line: underline;
  vertical-align: unset;
  @include typographyBody;
}

.link::before {
  display: none;
}
