@use '@styles/theme.module.scss' as *;

.checkbox {
  &_text {
    @include typographyBodyMedium;

    color: $black;
  }

  &_additional_text {
    color: $grayDark;
    margin-left: 1.75rem;
    @include typographySmallBody;
  }
}
