@use '@styles/theme.module.scss' as *;
@use 'sass:map';

$header-bottom-margin: spacing(7);

.modal {
  height: 100vh;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;

  :global(.MuiModal-root) ~ & :global(.MuiBackdrop-root) {
    background-color: rgba(0 0 0 / 0.1);
  }

  :global(.MuiButtonBase-root[aria-label='close']) {
    svg {
      height: 22px;
      min-width: 22px;
    }
    @include media($from: $sm) {
      svg {
        height: 17px;
        min-width: 17px;
      }
    }
  }

  @include media($from: $sm) {
    align-items: center;
    justify-content: center;
  }

  &_button:global(.MuiButtonBase-root) {
    position: absolute;
    top: calc(-20px + -28px);
    background-color: transparent !important;
    border: 0 !important;
    color: $white;

    @include hover {
      & svg {
        color: $primary !important;
      }
    }

    @include media($from: $sm) {
      top: spacing(7);
      color: $black;

      @include hover {
        & svg {
          color: $black;
        }
      }
    }
  }

  &_inner {
    position: absolute;
    transform: scale(1) rotate(0) translate3d(0, 0, 0);
    width: 100%;
    max-height: 85%;
    display: flex;
    justify-content: center;
    outline: none;
    background-color: $white;
    border-radius: map.get($radius, 'lg') map.get($radius, 'lg') 0 0;

    @include media($from: $sm) {
      min-width: 520px;
      max-width: 85vw;
      max-height: 70vh;
      border-radius: 16px;
      overflow: hidden;
    }

    @include media($from: 1024) {
      max-width: 88vw;
      max-height: 65vh;
    }

    @include media($from: 1440) {
      max-width: 75vw;
      max-height: 75vh;
    }

    @include media($from: $xl) {
      max-width: 70vw;
      min-width: 520px;
      max-height: 70vh;
    }
  }

  &_exit_button:global(.MuiButtonBase-root) {
    right: spacing(5);
    z-index: 1;

    @include media($from: $xs, $until: $sm) {
      &:focus-visible {
        outline: 1px dashed $white;
        outline-offset: spacing(1);

        & svg {
          color: $white !important;
        }
      }
    }
  }

  &_back_button:global(.MuiButtonBase-root) {
    left: 20px;
    z-index: 1;

    @include media($from: $sm) {
      left: spacing(7);
    }
  }

  &_back_button_icon {
    height: 18px;
  }

  &_content_wrapper {
    position: relative;
    width: 100%;
    animation-duration: 0.3s;
    animation-name: slide-in;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: $white;
    border-radius: map.get($radius, 'lg') map.get($radius, 'lg') 0 0;
    padding: spacing(7) spacing(4) spacing(4) spacing(4);

    &:focus {
      outline-style: none;
    }

    @include scroll;

    @include media($from: $sm) {
      padding: spacing(9);
      animation-name: none;
      width: 100%;
      border-radius: map.get($radius, 'xlg');
    }

    @include media($from: $xl) {
      padding: spacing(10);
    }
  }

  &_content_inner {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    background-color: $white;

    &_header {
      margin-bottom: $header-bottom-margin;
      text-indent: spacing(1);
      padding: 0 spacing(2);

      @include media($from: $sm) {
        padding: 0 spacing(4);
      }

      &_subtitle {
        height: 20px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0;
        @include typographyBody;
        @include media($from: $xl) {
          height: fit-content;
        }
      }

      &_title {
        margin: 0;
        height: 30px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @include typographyH3;

        @include media($from: $lg) {
          height: 40px;
        }

        @include media($from: $xl) {
          height: fit-content;
        }
      }
    }

    &_noPadding_header {
      padding: spacing(0);
    }

    &_center_align_header {
      text-align: center;
    }

    &_small {
      @include media($from: $sm) {
        border-radius: map.get($radius, 'xlg');
      }
    }
  }

  &_content {
    width: 100%;
    height: calc(100% - 30px - #{$header-bottom-margin}); // 30px is the header height
  }

  & :global .top-rated {
    @include typographyBody;

    @include media($from: $sm) {
      width: 443px;
    }

    @include media($from: $md) {
      width: 424px;

      & :local .modal_content_wrapper {
        width: 100%;
        min-width: unset;
      }
    }

    @include media($from: $xl) {
      width: 520px;
    }
  }
}

@keyframes slide-in {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
}

.error_wrapper {
  display: flex;
  align-items: center;
  margin: -#{spacing(4)} 0 spacing(4);
  width: fit-content;
  padding-right: spacing(2);
  max-width: 600px;

  @include media($from: $sm) {
    padding-right: spacing(8);
  }

  @include media($from: $xl) {
    padding-right: spacing(9);
  }

  .icon {
    color: $errorFG;
    margin-right: spacing(1);
  }

  .error_message {
    width: fit-content;
    color: $errorFG;
  }
}

.transparent {
  background-color: transparent;

  :global(.MuiBackdrop-root) {
    background-color: transparent;
    opacity: 0;
  }
}
