@use '@styles/theme.module.scss' as *;

.table {
  border: 3px solid $black;
  padding: spacing(2);

  &_header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-bottom: 3px solid $black;
    padding-bottom: spacing(6);
    height: 108px;
    @include media($from: $xl) {
      height: 182px;
    }
  }

  &_extra_section {
    width: 100%;
    border-top: 10px solid $black;

    tr:first-child {
      border: none;
    }
  }

  &_main_section {
    width: 100%;
  }
}

.table_item {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid $black;
  padding-left: spacing(5);
  @include typographySmallBody;

  &_weight {
    margin-left: spacing(1);
    @include typographySmallBody;
  }

  &_v2_weight {
    margin-left: spacing(1);
    @include typographySmallBody;
  }
}

.deep_nested_item {
  margin-left: spacing(2);
}

.daily_value_text {
  display: flex;
  padding: 0 spacing(2) 0 spacing(1);
  max-width: 100px;
  width: 80px;
  @include typographyLabel;
}

.title {
  margin: 0;
  @include typographyH3Bold;
}

.servings_number {
  margin-top: spacing(1);
  @include typographySmallBody;
}

.daily_value_title {
  margin-top: spacing(1);
  @include typographyBodyBold;
}

.extra_weight {
  @include typographySmallBody;
}

.single_column {
  width: 277px;
  margin: auto;
  @include media($from: $xl) {
    width: 307px;
  }
}

.multi_column {
  width: fit-content;
  height: fit-content;
  display: flex;
}

.multi_column_header_title {
  margin: 0;
  text-align: right;
  align-content: end;
  width: 195px;
  @include typographySmallBody;
}

.multiple_column_header {
  border-left: 1px solid $black;
  padding-right: spacing(5);
}

.servings {
  display: flex;
  justify-content: space-between;

  &_size {
    padding-top: spacing(1);
    @include typographySmallBodyBold;
  }
}

.section_item {
  display: flex;
  @include typographySmallBody;

  &_pet {
    @include typographyLabel;
  }
}

.bulleted {
  display: list-item;
  list-style-type: disc;
  list-style-position: inside;
}

.important {
  border-top: 2px solid $black;
  padding: 0.5rem 0;
  @include typographySmallBodyBold;
}

.html_nutrition {
  border: 1px solid $black;
  padding: spacing(4);
  width: 275px;
  margin: auto;
  @include typographySmallBody;

  @include media($from: $xl) {
    font-size: 0.8125rem;
  }

  & .basic_table {
    border: none;

    & .vertical_align_top {
      vertical-align: top;
    }

    & .border {
      border: none;
      display: inline-block;
      margin-left: 0;
      margin-right: 0;
      background-color: $black;
    }

    & .padding_left_10 {
      padding-left: 10px;
    }

    & .padding_left_20 {
      padding-left: 20px;
    }
  }
}

.filters {
  text-align: right;
  display: flex;
  justify-content: flex-end;
  margin: spacing(3) 0 spacing(1) 0;

  &_item {
    width: 40px;
    font-size: 0.375rem;
    font-weight: 700;
  }
}

.left_item {
  margin-left: spacing(8);
}

.left_align {
  text-align: left;
}

.ingredient_value {
  margin-left: spacing(4);
}

.title_wrapper {
  margin-top: spacing(1);
  border-bottom: 1px solid $black;
}

.calories {
  margin-top: spacing(5);
  @include typographyH3Bold;
}

.daily_value_item_wrapper {
  text-align: right;
  border-top: 5px solid $black;
  width: 100%;
}

.empty_cell {
  padding: spacing(3);
}

.right_align {
  text-align: right;
}

.total_calories {
  text-align: right;
  margin-top: spacing(5);
  @include typographyH3Bold;
}

.meta_header {
  display: flex;
  width: 195px;
}

.reseted_item {
  padding: 1px;
  @include typographySmallBodyBold;
}

.multi_column_reseted_item {
  padding: 1px;
  @include typographySmallBody;
}

.multi_column_header_wrapper {
  border-bottom: 1px solid $black;
  max-height: 39px;
  padding-bottom: spacing(7);
}

.supplement_title_wrapper {
  display: flex;
  flex-direction: column;
  padding-top: spacing(1);

  .servings_number:last-child {
    display: flex;
    justify-content: space-between;
    @include typographySmallBodyBold;
  }
}

.single_daily_value_text {
  font-weight: 400;
  font-size: 0.5rem;
  line-height: 1;
  display: flex;
  padding: spacing(1) spacing(2) 0 spacing(1);
}

.thick_border {
  border-bottom: 1px solid $black;
}

.medium_border {
  border-bottom: 3px solid $black;
}

.top_border {
  border-top: 5px solid $black;
}

.medium_border_top {
  border-top: 3px solid $black;
}

.nutritionv2_item {
  text-align: left;
  display: flex;
  width: 200px;
}

.v2_column {
  width: 360px;
  margin: auto;
}

.top_large_border {
  border-top: 10px solid $black;
}

.v2_main_item {
  padding: spacing(1) 0 spacing(1) 0;
}

.pet_title {
  margin-bottom: spacing(7);
}

.freetext_title_wrapper {
  margin-bottom: spacing(1);
}

.freetext_title {
  @include typographyBodyBold;
}

.v2_values {
  max-width: 110px;
  width: 100px;
  display: flex;
  justify-content: space-between;
}

.single_title_border {
  border-bottom: 5px solid $black;
}

.single_header {
  padding: 0;
  border-bottom: 10px solid $black;
}

.single_amount_per_serving {
  @include typographyLabelBold;
}

.single_calories {
  @include typographyH3Bold;
}

.single_amount_wrapper {
  padding-top: 5px;
}

.nested {
  @include typographyLabel;
}
