@use '@styles/theme.module.scss' as *;

.create_so_button {
  margin-top: spacing(2);
  white-space: nowrap;
  background-color: $white;

  &_wrapper {
    position: absolute;
    width: 100%;
    padding: 0 spacing(2);
    display: flex;
    justify-content: center;
    z-index: 1;
  }

  &.v2 {
    background-color: $groupScaleBackgoundGreen;
    border: none;
    border-radius: 1rem;
    color: $primary;
    height: 1.25rem;
    min-height: unset;
    margin-top: calc(spacing(1));
    padding: 0 1rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &.loading {
      cursor: not-allowed;
      pointer-events: initial;
    }

    & .label {
      @include typographyLabelMedium;

      margin: 0;
      margin-right: calc(spacing(1) / 2);
      line-height: 1;
      @include media($from: $lg) {
        line-height: 1;
      }
    }

    & .label_icon {
      height: 8px;
      width: 8px;
      stroke: $primary;
      margin-right: spacing(1);
    }
  }
}

.is_hidden {
  opacity: 0;
}
