@use '@styles/theme.module' as *;

.container {
  display: flex;
  justify-content: space-between;
  color: $black;
  @include typographySmallBodyMedium;
}

.subtotal_label {
  @include typographySmallBody;
}

.items_added {
  flex-shrink: 0;
  color: $grayDarker;
  margin: spacing(0) spacing(2);

  @include media($from: $sm) {
    display: none;
  }
}

.subtotal_value {
  @include typographyBodyBold;

  &.margin_left {
    margin-left: spacing(3);
  }
}

.highlighted {
  color: $primary;
  @include typographyBody;
}
