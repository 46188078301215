@use '@styles/theme.module' as *;

.total_price {
  display: flex;
  color: #000;
  margin-bottom: spacing(2);

  &_value {
    @include typographyH5;

    @include media($from: $lg) {
      @include typographyH4Medium;
    }
  }
}

.annotation {
  display: inline-flex;
  align-items: center;
  margin: 0 spacing(2);
  color: #666;
  @include typographySmallBody;

  &_icon {
    height: 12px;
    width: 12px;
    cursor: pointer;

    &:focus-visible {
      outline: 1px dashed #444;
      outline-offset: 0.15rem;
    }
  }
}
