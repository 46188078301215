@use '@styles/theme.module' as *;

.main_action {
  width: 100%;
}

.heading {
  min-width: 254px;
  margin-right: spacing(8);
  min-height: 44px;
  left: 0;
  top: 0;
  @include typographyH4Bold;
  @include media($from: $lg) {
    @include typographyH4Medium;
  }

  @include media($from: $lg) {
    margin-bottom: spacing(3);
  }
}

.description {
  @include typographyBody;
}

.divider {
  width: 100%;
  margin: spacing(8) 0;

  @include media($from: $sm) {
    margin: spacing(7) 0;
  }

  @include media($from: $lg) {
    margin: spacing(6) 0;
  }

  @include media($from: $xl) {
    margin: spacing(7) 0;
  }
}

.request_credit_button:global(.MuiButton-root) {
  width: 100%;
  margin-top: spacing(3);

  @include media($from: $lg) {
    margin-bottom: 0;
  }
}
