@use '@styles/theme.module' as *;

.error_wrapper {
  margin-top: spacing(4);
  width: fit-content;
  padding-right: spacing(2);

  @include media($from: $sm) {
    padding-right: spacing(8);
  }

  @include media($from: $xl) {
    padding-right: spacing(9);
  }
}

.icon {
  color: $errorFG;
  margin-right: spacing(1);
}

.error_message {
  width: fit-content;
  color: $errorFG;
}
