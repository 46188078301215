@use '@styles/theme.module.scss' as *;

.tab {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  border: 1px solid $grayLight;
  border-radius: 8px;
  color: $black;
  text-transform: none;
  margin-right: spacing(2);
  padding: spacing(5) spacing(3);
  min-width: 128px;
  max-height: 58px;
  min-height: auto;
  @include typographySmallBodyBold;

  &:last-child {
    margin-right: 0;
  }

  &:focus-visible {
    outline: 1px dashed $grayDarker;
    outline-offset: spacing(1);
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }

  &.active {
    color: inherit;
    border-color: $black;
  }
}

.hidden {
  display: none;
}
