@use '@styles/theme.module' as *;

.button {
  max-width: 541px;

  &_wrapper {
    @include media($from: $largeTablet) {
      display: none;
    }

    display: flex;
    justify-content: center;
  }
}
