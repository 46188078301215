@use '@styles/theme.module.scss' as *;

.tile {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  border: 1px solid $grayLighter;
  padding: spacing(5);
  border-radius: spacing(2);
  cursor: pointer;
  height: 205px;
  width: 100%;
  background-color: $white;

  @include media($from: $sm) {
    height: 155px;
    width: 260px;
  }

  @include media($from: $largeTablet) {
    height: 190px;
    width: 100%;
  }

  &.selected {
    border: 1px solid $black;
  }

  &:focus-visible {
    outline: 1px dashed $grayDarker;
    outline-offset: spacing(1);
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }
}

.top_line,
.bottom_line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.title,
.card_number {
  color: $black;
  @include typographyBody;
}

.payment_icon {
  height: 24px;
  width: auto;
}

.selected_icon {
  height: 16px;
  width: 16px;
  color: $black;
}

.button {
  margin-top: spacing(3);
  display: inline;
}

.with_right_margin {
  margin-right: spacing(4);
}
