@use '@styles/theme.module.scss' as *;
@use 'sass:map';

@mixin default {
  color: $white;

  &:disabled {
    border-color: $gray2;
    background-color: $gray2;
    color: $grayDark;
    cursor: not-allowed;
  }
}

.button {
  &.primary {
    @include default;

    background-color: $primary;
    border-color: $primary;

    &:enabled:hover {
      border-color: $primaryDark;
      background-color: $primaryDark;
    }
  }

  &.secondary {
    @include default;

    background-color: $orange;
    border-color: $orange;

    &:enabled:hover {
      border-color: $orangeDark;
      background-color: $orangeDark;
    }
  }
}

.loading_icon {
  color: $white;
}
