@use '@styles/theme.module.scss' as *;

.wrapper {
  display: flex;
  position: relative;
}

.icon_shop {
  cursor: pointer;
  align-self: center;
  flex-shrink: 0;
  fill: $black;
}

.cart_wrapper {
  border: none;
  background-color: transparent;
  position: relative;
  align-self: center;

  &:focus-visible {
    outline: 1px dashed $grayDarker;
    outline-offset: spacing(1);
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }

  svg {
    width: 18px;
    height: 18px;

    @include media($from: $xl) {
      height: 30px;
      width: 30px;
    }
  }
}

.cart_counter {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translateX(20%);
  padding: spacing(0) spacing(1);
  width: max-content;
  min-width: spacing(4);
  height: 100%;
  max-height: spacing(4);
  background-color: $primary;
  color: $white;
  border-radius: 50%;
  text-align: center;
  @include typographyLabelBold;

  @include media($from: $xs) {
    line-height: spacing(0);
  }
}

.loading {
  background-color: $grayLighter;
}

.not_visible {
  visibility: hidden;
}

.tooltip_popper {
  margin: 0;
  padding: 0;
}

.cart_drawer {
  margin: 0;
}

.sidecart {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas: 'header' 'inner';
  height: 100vh;
  width: 425px;
}

.cart_drawer_bag {
  margin: 0;
}
