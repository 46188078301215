@use '@styles/theme.module.scss' as *;
@use 'sass:map';

.footer {
  background-color: $gray1;
  padding: spacing(8) spacing(4);

  @include media($from: $sm) {
    padding: spacing(11) spacing(5) spacing(7);
  }

  @include media($from: $md) {
    padding: spacing(11) spacing(7) spacing(7);
  }

  @include media($from: $lg) {
    padding: spacing(11) spacing(10) spacing(7);
  }

  @include media($from: $xl) {
    padding: spacing(11) spacing(13) spacing(7);
  }
}

.top_line {
  display: grid;
  grid-template-columns: 1fr 1fr;
  color: $grayDarker;
  grid-gap: spacing(8);
  margin-bottom: spacing(8);
  grid-template-areas:
    'col-1 col-3'
    'col-2 col-4';

  @include media($from: $sm) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin-bottom: spacing(9);
    grid-template-areas: 'col-1 col-2 col-3 col-4';
  }

  @include media($from: $md) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin-bottom: spacing(9);
    grid-template-areas: 'col-1 col-2 col-3 col-4';
  }

  .top_line_item {
    display: flex;
    flex-direction: column;

    .social_icon {
      width: 12px;
      height: 12px;
      margin-right: spacing(2);
    }

    .store_icon {
      width: 120px;
      height: 40px;
      color: $black;
      cursor: pointer;
    }

    .title {
      color: $grayDarker;
      margin-bottom: spacing(4);
      @include typographySmallBodyBold;

      @include media($from: $sm) {
        margin-bottom: spacing(5);
      }

      @include media($from: $lg) {
        @include typographyBodyBold;
      }
    }
  }
}

.divider {
  background-color: $grayLighter;
  margin-bottom: spacing(5);
}

.inner_item {
  display: flex;
  align-items: center;
  margin-bottom: spacing(2);

  &.inner_title {
    flex-direction: column;
    align-items: baseline;
    margin-top: spacing(3);

    .title {
      margin-bottom: spacing(1);
    }
  }

  @include media($from: $md) {
    margin-bottom: spacing(3);
  }
}

.inner_item_button {
  color: $grayDark;
  border-radius: map.get($radius, 'md');

  &:focus-visible {
    color: $grayDark;
  }

  &::before {
    height: 0;
  }
}

.subtitle {
  border-radius: map.get($radius, 'md');
  color: $grayDark;
  @include typographySmallBody;

  @include media($from: $lg) {
    @include typographyBody;
  }

  &:focus-visible {
    outline-offset: spacing(1);
    outline: 1px dashed $grayDarker;
  }

  &:active,
  &:not(:focus):hover {
    color: $primary;
    cursor: pointer;
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }
}

.apk_link {
  &:focus-visible {
    outline: 1px dashed $grayDarker;
    outline-offset: spacing(1);
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }
}

.inner_bottom_items {
  display: grid;
  grid-template-columns: 95px auto;
  justify-self: flex-start;
  grid-row-gap: spacing(2);
  margin-top: spacing(2);

  @include media($from: $sm) {
    display: flex;
    margin-top: 0;
  }
}

.bottom_subtitle {
  margin: 0 spacing(2) 0 0;
  width: fit-content;
  @include typographySmallBody;

  &:nth-child(odd) {
    border-right: 1px solid $grayLighter;
    padding-right: spacing(2);
  }

  &:nth-child(even) {
    margin-right: 0;
  }

  @include media($from: $sm) {
    border-right: 1px solid $grayLighter;
    padding-right: spacing(3);

    &:nth-child(even) {
      margin-right: spacing(3);
    }
  }

  &:last-child {
    margin-right: 0;
    border-right: none;
    padding-right: 0;
  }

  &:hover {
    color: $primary;
    cursor: pointer;
  }
}

.bottom_line {
  @include media($from: $sm) {
    display: flex;
    justify-content: space-between;
    padding-right: spacing(9);
  }

  @include media($from: $lg) {
    justify-content: normal;
    padding-right: initial;
  }
}

.with_margin {
  @include typographySmallBody;

  @include media($from: $lg) {
    margin-right: spacing(13);
  }
}

.to_top {
  width: 100%;
  height: 45px;
  background-color: rgba($black, 0);
  margin-bottom: spacing(6);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  border: 1px solid $grayLight;
  border-radius: 40px;
  color: $grayDarker;
  transition: background-color 100ms linear;
  cursor: pointer;
  @include typographyBodyBold;

  &:hover,
  &:focus-visible {
    text-decoration: underline;
    background-color: rgba($black, 0.07);
    border: 1px solid $grayDarker;
  }

  &:focus-visible {
    outline: 1px dashed $grayDarker;
    outline-offset: spacing(1);
  }
}

.logo {
  display: flex;
  align-items: center;
  width: max-content;
  cursor: pointer;

  &:focus-visible {
    outline: 1px dashed $grayDarker;
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }
}

.item_list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.refer_friend {
  border-radius: map.get($radius, 'md');
  color: $grayDark;

  &:hover {
    color: $primary;
  }

  &:focus-visible {
    outline-offset: spacing(1);
    outline: 1px dashed $grayDarker;
  }
}
