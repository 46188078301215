@use '@styles/theme.module' as *;

.wrapper {
  display: flex;
  flex-direction: column;
}

.icon {
  color: $deliveryPassFG;
  margin-bottom: spacing(2);
}

.title {
  margin-bottom: spacing(6);
  @include typographyH4;
}

.description {
  margin-bottom: spacing(6);
  @include typographyBody;
}

.colored {
  color: $deliveryPassFG;
}

.button {
  height: 48px;
  background-color: $deliveryPassFG !important;
  @include typographyBody;

  &:active {
    background-color: $deliveryPassFG !important;
  }

  &:hover {
    background-color: $deliveryPassDark !important;
  }
}
