@use 'sass:map';
@use 'sass:string';

$shadows: (
  '0': none,
  '2': '0px 2px 12px rgba(0, 0, 0, 0.1)',
  '3': '0px 0px 12px rgba(0, 0, 0, 0.02), 0px 4px 16px rgba(0, 0, 0, 0.1)',
  '5': '0px 0px 20px rgba(0, 0, 0, 0.06), 0px 16px 32px rgba(0, 0, 0, 0.15)',
);

@function shadow($number) {
  @return string.unquote($string: map.get($shadows, '' + $number));
}

@each $shadow, $value in $shadows {
  /* stylelint-disable selector-pseudo-class-no-unknown */
  :export {
    #{unquote($shadow)}: $value;
  }
  /* stylelint-enable */
}
