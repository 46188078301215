@use '@styles/theme.module' as *;

.iframe_wrapper {
  height: 100%;

  iframe {
    width: 100%;
    min-height: 840px;
    border: 0;
  }
}
