@use '@styles/theme.module.scss' as *;

.wrapper {
  display: flex;
  flex-direction: column;
  margin: 0;
  border-bottom: 1px solid $grayLighter;
  padding: spacing(4) 0;

  &:last-child {
    border-bottom: none;
  }

  &.is_substituted {
    margin-bottom: spacing(2);
    opacity: 0.5;
  }

  @include media($from: $sm) {
    border: none;
    justify-content: space-between;
    padding: spacing(3) spacing(5);
  }

  @include media($from: $md) {
    border-radius: 16px;

    &.wrapper:not(.drawer_opened) {
      flex-direction: row;
    }
  }
}

.available {
  @include hover {
    background-color: $gray2;
  }
}

.tile {
  display: flex;
  align-items: center;
}

.tile_clickable {
  cursor: pointer;
  text-align: left;
  border: none;
  padding: 0;
  background: transparent;
  color: $black;

  &:focus-visible {
    outline: 1px dashed $grayDarker;
    outline-offset: spacing(1);
  }
}

.image {
  height: 60px;
  margin-right: spacing(6);
  flex-shrink: 0;

  img {
    object-fit: contain;
  }
}

.basic_info {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-right: spacing(3.5);

  .modified_label {
    @include typographyExtraSmallBodyBold;

    margin-bottom: 2px;
    padding: spacing(1) spacing(2);
    border: 1px solid $primary;
    background-color: $primaryLightest;
    border-radius: 25px;
  }
}

.name_and_info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.tag {
  text-transform: uppercase;
}
