@use '@styles/theme.module' as *;

.tile {
  padding: spacing(3);
  height: 100%;
  margin: spacing(8) 0;

  @include media($from: $lgbp) {
    margin-bottom: spacing(10);
  }
}

.image {
  margin: 0 auto spacing(8);

  @include media($from: $lgbp) {
    margin-bottom: spacing(10);
  }
}
