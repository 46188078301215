@use '@styles/theme.module' as *;

.edit_button {
  &:not(.no_margin) {
    margin-bottom: spacing(2);
  }
  @include typographySmallBody;

  @include media($from: $lg) {
    @include typographyBody;
  }
}
