@use '@styles/theme.module' as *;

.input {
  margin-right: spacing(2);
}

.opened_trash {
  display: none;
}

.container {
  margin-top: spacing(7);
}

.add_button {
  width: 100%;
}

.delete_button:hover {
  .closed_trash {
    display: none;
  }

  .opened_trash {
    display: block;
  }
}

.fields_container {
  display: flex;
  margin-top: spacing(7);
}

.number_field {
  flex-shrink: 3;
  min-width: 145px;
}

.extension_field {
  flex-shrink: 5;
  min-width: 85px;
}
