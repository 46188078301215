@use '@styles/theme.module' as *;

.selectWrapper {
  position: relative;
  height: 100%;
  padding-top: spacing(4);

  & .checkboxWrapper {
    position: absolute;
    top: 2.8rem;
    right: -0.7rem;
    z-index: 2;
  }

  & .selectProductWrapper {
    height: 100%;
  }

  & .productWrapper {
    opacity: 0.5;
    pointer-events: none;

    &.selected {
      opacity: 1;
    }
  }
}
