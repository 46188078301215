@use '@styles/theme.module' as *;

.modal {
  @include media($from: $sm) {
    max-width: 443px;
  }
}

.heading {
  margin-bottom: spacing(7);
  @include typographyH3;
}

.info_text {
  max-width: 385px;
  margin-bottom: spacing(7);
  color: $grayDark;
  @include typographyBody;
}

.button {
  margin-bottom: spacing(4);
}
