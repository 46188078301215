@use '@styles/theme.module.scss' as *;

$padding-content: spacing(6);
$pic-size: 54px;

.configurations {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: spacing(6);
  position: relative;

  &_string_item {
    height: 48px;
    display: flex;
    align-items: center;
    padding-left: spacing(5);
  }

  &_string_item,
  &_dropdown {
    @include typographyButtonsAndLinksMedium;
  }

  &_dropdown_wrapper {
    width: 100%;
    flex: 1 1 auto;
    margin-bottom: spacing(4);

    &:last-child {
      margin-bottom: 0;
    }

    &:nth-child(3) {
      margin-left: 0;
    }

    &.halfLine {
      max-width: calc(50% - (#{spacing(3)} / 2));

      :global(.MuiFormControl-root) {
        label {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  & .halfLine + .halfLine {
    @include media($from: $lg) {
      margin-left: spacing(3);
    }
  }

  & :global(.MuiInputLabel-root) {
    max-width: calc(100% - #{spacing(9)});
  }
}

.more_info {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: spacing(4);

  &_without_label {
    justify-content: flex-end;
    padding-right: spacing(4);
  }

  &_button {
    vertical-align: unset;
    @include typographyBody;
  }
}

.item_with_pic {
  width: 100%;
  display: flex;
  align-items: center;

  & > div:first-child {
    display: flex;
    align-items: center;

    span:first-child {
      margin-left: spacing(4);
      white-space: pre-line;
      min-width: $pic-size;
      min-height: $pic-size;
    }

    span:last-child {
      margin-left: spacing(4);
      white-space: pre-line;
    }
  }

  .info_icon {
    margin-left: auto;
    margin-right: spacing(2);

    svg {
      width: 14px;
    }
  }
}
