@use '@styles/theme.module.scss' as *;

.modal_window {
  width: 100%;

  @include media($from: $sm) {
    max-width: 523px;
  }

  @include media($from: $xl) {
    max-width: 643px;
  }

  & :global(.MuiInputLabel-root) {
    @include typographyBody;
  }
}
