@use '@styles/theme.module.scss' as *;
@use 'sass:map';

.buttonBase {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: spacing(3.5) spacing(6);
  border: 1px solid transparent;
  border-radius: map.get($radius, 'button');
  cursor: pointer;

  @include typographyButtonsAndLinksMedium;

  &:focus-visible {
    outline: 1px dashed $grayDarker;
    outline-offset: spacing(1);
  }

  &.full_width {
    width: 100%;
  }

  &.extra_small {
    padding: spacing(2) spacing(3);
  }

  &.small {
    padding: spacing(2) spacing(5);
  }
}
