@use '@styles/theme.module' as *;

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
}

.payments {
  width: 100%;
  position: relative;
}

.payments_list {
  display: grid;
  grid-gap: spacing(5);
  padding: spacing(7) spacing(0);
  grid-template-columns: 1fr;
  grid-auto-rows: 1fr;

  @include media($from: $sm) {
    grid-template-columns: 1fr 1fr;
  }
}

.alert {
  width: 100%;
  grid-column: span 2;
  position: relative;
  top: 0;
  z-index: 0;

  @include media($from: $sm) {
    position: relative;
  }

  @include media($from: $md) {
    position: absolute;
    top: -8px;
  }
}

.container {
  @include media($from: $largeTablet) {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-gap: spacing(5);
  }
}

.content {
  grid-column: 2 / span 10;
  @include media($from: $lgbp) {
    grid-column: 2 / span 6;
  }
}
