@use '@styles/theme.module' as *;

.modal {
  @include media($from: $sm) {
    max-width: fit-content;
  }
}

.title {
  margin-bottom: spacing(7);
  @include typographyH3;
}

.message {
  max-width: 385px;
  margin-bottom: spacing(7);
  color: $grayDark;
}
