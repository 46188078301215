@use '@styles/theme.module' as *;

.title {
  margin-bottom: spacing(6);
  @include typographyH3;
}

.subtitle {
  @include typographyH5Medium;
}

.subtitle_wrapper {
  display: flex;
  margin-bottom: spacing(6);
}

.questions_link {
  margin-left: spacing(3);
  @include typographyBodyMedium;
}

.separator {
  display: block;
  width: 100%;
  height: 1px;
  margin: spacing(7) 0;
  background-color: $grayLighter;
}

.instructions_field {
  margin-top: spacing(4);
  margin-bottom: spacing(14);

  ::placeholder {
    color: $grayDark;
    opacity: 1;
  }

  :global(.MuiOutlinedInput-input) {
    color: $black;

    ::placeholder {
      opacity: 1;
    }
  }

  @include media($from: $xs, $until: $sm) {
    margin-bottom: spacing(14);
  }
}

.field {
  margin-bottom: spacing(4);
}

.button {
  @include typographyBodyMedium;
}

.sticky_container {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: $modal;
  padding: spacing(0) spacing(10) spacing(7);
  width: 100%;
  background-color: $white;

  @include media($from: $xs, $until: $sm) {
    padding: spacing(0) spacing(4) spacing(7);
  }
}
