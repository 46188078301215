@use '@styles/theme.module' as *;

.text_wrapper {
  margin-bottom: spacing(5);
}

.button_wrapper {
  width: 100%;
}

.input {
  margin-bottom: spacing(5);
}

.button {
  margin-top: spacing(2);
}

.form {
  width: 100%;
}
