@use '@styles/theme.module.scss' as *;

.list_link {
  margin: 0;
  display: flex;
  align-items: center;
}

.link,
.separator {
  color: $grayDark;
  @include typographySmallBody;

  @include media($from: $xl) {
    @include typographyBody;

    .link,
    .separator {
      @include typographyBody;
    }
  }
}

.separator {
  margin: 0 spacing(1);
}
