@use '@styles/theme.module.scss' as *;

.coupon {
  margin-top: spacing(2);
}

.group_scale {
  color: $sale;
  line-height: 1rem;
}

.group_scale_label {
  @include typographySmallBodyBold;
}

.group_scale_value {
  @include typographySmallBody;
}

.cart_quantity {
  display: flex;

  @include media($until: $md) {
    flex-direction: column;
  }
}

.promo_container {
  padding-left: spacing(1);
  flex: 1 1 content;
  min-width: 35%;
}

.promo_discount_description {
  padding-left: spacing(1);
  border-left: 1px solid $beige;
}

.promo_description {
  padding-left: spacing(1);
  border-left: 1px solid $beige;
  color: $errorFG;
}

.sample_product_text {
  color: $grayDark;
  @include typographySmallBody;
}
