@import './fontSize';

@mixin typographyH1 {
  font-family: var(--font-circular-std), sans-serif;
  font-size: get-font-size($h1, $xs);
  font-weight: 400;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: -0.01em;

  @include media($from: $lg) {
    font-size: get-font-size($h1, $lg);
  }

  @include media($from: $xl) {
    font-size: get-font-size($h1, $xl);
  }
}

@mixin typographyH1Medium {
  @include typographyH1;

  font-weight: 500;
}

@mixin typographyH1Bold {
  @include typographyH1;

  font-weight: 700;
}

@mixin typographyH2 {
  font-family: var(--font-circular-std), sans-serif;
  font-size: get-font-size($h2, $xs);
  font-weight: 400;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: -0.01em;

  @include media($from: $lg) {
    font-size: get-font-size($h2, $lg);
  }

  @include media($from: $xl) {
    font-size: get-font-size($h2, $xl);
  }
}

@mixin typographyH2Medium {
  @include typographyH2;

  font-weight: 500;
}

@mixin typographyH2Bold {
  @include typographyH2;

  font-weight: 700;
}

@mixin typographyH3 {
  font-family: var(--font-circular-std), sans-serif;
  font-size: get-font-size($h3, $xs);
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: -0.01em;

  @include media($from: $lg) {
    font-size: get-font-size($h3, $lg);
  }

  @include media($from: $xl) {
    font-size: get-font-size($h3, $xl);
  }
}

@mixin typographyH3Medium {
  @include typographyH3;

  font-weight: 500;
}

@mixin typographyH3Bold {
  @include typographyH3;

  font-weight: 700;
}

@mixin typographyH4 {
  font-family: var(--font-circular-std), sans-serif;
  font-size: get-font-size($h4, $xs);
  font-weight: 400;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: -0.01em;

  @include media($from: $lg) {
    font-size: get-font-size($h4, $lg);
  }
}

@mixin typographyH4Medium {
  @include typographyH4;

  font-weight: 500;
}

@mixin typographyH4Bold {
  @include typographyH4;

  font-weight: 700;
}

@mixin typographyH5 {
  font-family: var(--font-circular-std), sans-serif;
  font-size: get-font-size($h5, $xs);
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: -0.01em;

  @include media($from: $xl) {
    font-size: get-font-size($h5, $xl);
  }
}

@mixin typographyH5Medium {
  @include typographyH5;

  font-weight: 500;
}

@mixin typographyH5Bold {
  @include typographyH5;

  font-weight: 700;
}

@mixin typographyH6 {
  font-family: var(--font-circular-std), sans-serif;
  font-size: get-font-size($h6, $xs);
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: -0.01em;

  @include media($from: $xl) {
    font-size: get-font-size($h6, $xl);
  }
}

@mixin typographyH6Medium {
  @include typographyH6;

  font-weight: 500;
}

@mixin typographyH6Bold {
  @include typographyH6;

  font-weight: 700;
}

@mixin typographyBody {
  font-family: var(--font-circular-std), sans-serif;
  font-size: get-font-size($body, $xs);
  font-weight: 400;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: -0.01em;

  @include media($from: $xl) {
    font-size: get-font-size($body, $xl);
    line-height: 1.4;
  }
}

@mixin typographyBodyMedium {
  @include typographyBody;

  font-weight: 500;
}

@mixin typographyBodyBold {
  @include typographyBody;

  font-weight: 700;
}

@mixin typographyBigBody {
  @include typographyBody;

  font-size: get-font-size($body, $xl);
}

@mixin typographyBigBodyBold {
  @include typographyBodyBold;

  font-size: get-font-size($body, $xl);
}

@mixin typographySmallBody {
  font-family: var(--font-circular-std), sans-serif;
  font-size: get-font-size($small-body, $xs);
  font-style: normal;
  font-weight: 400;
  line-height: 1.3;
  letter-spacing: -0.01em;

  @include media($from: $xl) {
    font-size: get-font-size($small-body, $xl);
    line-height: 1.4;
  }
}

@mixin typographySmallBodyMedium {
  @include typographySmallBody;

  font-weight: 500;
}

@mixin typographySmallBodyBold {
  @include typographySmallBody;

  font-weight: 700;
}

@mixin typographyExtraSmallBody {
  font-family: var(--font-circular-std), sans-serif;
  font-size: get-font-size($extra-small-body, $xs);
  font-style: normal;
  font-weight: 400;
  line-height: 1.3;
  letter-spacing: -0.01em;

  @include media($from: $xl) {
    font-size: get-font-size($extra-small-body, $xl);
    line-height: 1.4;
  }
}

@mixin typographyExtraSmallBodyMedium {
  @include typographyExtraSmallBody;

  font-weight: 500;
}

@mixin typographyExtraSmallBodyBold {
  @include typographyExtraSmallBody;

  font-weight: 700;
}

@mixin typographyButtonsAndLinks {
  font-family: var(--font-circular-std), sans-serif;
  font-size: get-font-size($buttons-and-links, $xs);
  font-weight: 400;
  line-height: 1.3;
  letter-spacing: -0.01em;

  @include media($from: $xl) {
    font-size: get-font-size($buttons-and-links, $xl);
    line-height: 1.4;
  }
}

@mixin typographyButtonsAndLinksMedium {
  @include typographyButtonsAndLinks;

  font-weight: 500;
}

@mixin typographyButtonsAndLinksBold {
  @include typographyButtonsAndLinks;

  font-weight: 700;
}

@mixin typographyButtonsAndLinksSmall {
  font-family: var(--font-circular-std), sans-serif;
  font-size: get-font-size($buttons-and-links-small, $xs);
  font-weight: 400;
  line-height: 1.3;
  letter-spacing: -0.01em;

  @include media($from: $xl) {
    font-size: get-font-size($buttons-and-links-small, $xl);
    line-height: 1.4;
  }
}

@mixin typographyButtonsAndLinksSmallMedium {
  @include typographyButtonsAndLinksSmall;

  font-weight: 500;
}

@mixin typographyButtonsAndLinksSmallBold {
  @include typographyButtonsAndLinksSmall;

  font-weight: 700;
}

@mixin typographyLabel {
  font-family: var(--font-circular-std), sans-serif;
  font-size: get-font-size($label, $xs);
  line-height: 1.2;
  font-weight: 400;
  letter-spacing: -0.01em;

  @include media($from: $lg) {
    line-height: 1.3;
  }

  @include media($from: $xl) {
    font-size: get-font-size($label, $xl);
  }
}

@mixin typographyLabelMedium {
  @include typographyLabel;

  font-weight: 500;
}

@mixin typographyLabelBold {
  @include typographyLabel;

  font-weight: 700;
}
