@use '@styles/theme.module' as *;

.wrapper {
  display: flex;
  padding: 0;
  flex-direction: column;

  @include media($from: $sm) {
    justify-content: space-between;

    &:not(.align_left) {
      flex-direction: row-reverse;
    }
  }
}

.empty_cart {
  display: inline-flex;
  width: fit-content;
  align-self: flex-end;
  color: $black;
  height: fit-content;
  margin-bottom: spacing(6);
  text-align: end;
  @include typographyBody;

  @include media($from: $sm) {
    align-self: unset;
    margin: 0;
  }
}

.note {
  color: $grayDark;
}

.note_item {
  display: inline-block;
  margin-right: spacing(2);

  &:last-child {
    margin: 0;
  }

  button {
    @include typographySmallBody;

    color: $grayDark;
  }
}

.definition_list {
  margin-bottom: spacing(6);
  @include typographySmallBody;

  @include media($from: $sm) {
    margin-bottom: spacing(1);
  }
}

.trigger_list {
  @include typographySmallBody;

  margin: 0;
  padding: 0;
  list-style-type: none;
}

.modal {
  @include media($from: $sm) {
    width: 443px;
  }
}
