@use '@styles/theme.module' as *;

.radio_group {
  flex-direction: column;

  &.rowed {
    flex-direction: row;
  }
}

.label {
  margin-bottom: spacing(3);
}

.radio_button {
  margin-bottom: spacing(3);
}
