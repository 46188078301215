@use '@styles/theme.module.scss' as *;

.wrapper {
  padding: spacing(2) spacing(8) 0;

  :global(.title_section) {
    display: grid;
    gap: spacing(1);
    margin-bottom: spacing(2);
    grid-auto-flow: column;
    grid-auto-columns: max-content;

    h2 {
      margin: 0;
      @include typographyH5Medium;

      font-weight: 500 !important;
    }

    :global(.plan_duration) {
      color: $grayDark !important;
    }
  }

  :global(.subtitle) {
    color: $grayDark !important;
    @include typographyBody;
  }

  ul {
    margin-top: spacing(7);
    color: $grayDark !important;
    display: grid;
    grid-template-columns: 1fr 1fr;

    @include media($until: $sm) {
      display: flex;
      flex-direction: column;
      margin: spacing(3) 0 0;
    }
  }

  hr {
    display: none;
  }

  @include media($until: $sm) {
    padding: spacing(6) spacing(6) 0;
  }
}

.terms {
  padding-bottom: spacing(8);

  &_button {
    color: $grayDark;
    @include typographyH4Medium;
  }
}
