@use '@styles/theme.module' as *;

.title {
  margin-bottom: spacing(6);
  @include typographyH3;
}

.separator {
  display: block;
  width: 100%;
  height: 1px;
  margin: spacing(7) spacing(0) spacing(6);
  background-color: $grayLighter;
}

.instructions_field {
  margin-top: spacing(4);

  ::placeholder {
    color: $grayDark;
    opacity: 1;
  }

  :global(.MuiOutlinedInput-input) {
    color: $black;

    ::placeholder {
      opacity: 1;
    }
  }
}

.field {
  margin-bottom: spacing(4);
}

.button {
  @include typographyBodyMedium;
}
