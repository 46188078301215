@use '@styles/theme.module.scss' as *;
@use 'sass:map';

$collapse-transition: 0.5s ease-in-out;

.input {
  border: none;
  background-color: transparent;
  width: 25px;
  margin: 0;
  padding: 0;
  text-align: center;
  color: $black;
  @include typographyBody;

  &_wrapper {
    display: flex;
    justify-content: center;
    width: 25px;
    flex-shrink: 0;
  }

  // Hides native arrows
  appearance: textfield;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
    opacity: 1;
  }
}

.wrapper {
  border-radius: map.get($radius, 'button');
  border: 1px solid $grayLighter;
  background-color: $white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  box-sizing: border-box;
  transition: 0.2s ease-in-out;
  text-align: center;
  @include typographyBody;

  .button {
    height: 25px;
    width: 25px;
    border-radius: 50%;

    * {
      color: $primary;
      width: 18px;
    }

    &:disabled {
      opacity: 0.2 !important;
    }

    &:focus-visible {
      outline: 1px dashed $grayDarker;
    }
  }

  &.small {
    padding: 0;
    max-width: 86px;
    height: 27px;

    &.collapsed {
      width: 27px;
    }

    .button * {
      width: 12px;
      height: 12px;
    }
  }

  &.medium {
    padding: spacing(1) spacing(1);
    max-width: 86px;
    height: 35px;

    .button * {
      width: 12px;
      height: 12px;
    }

    &.collapsed {
      width: 35px;
    }
  }

  &.large {
    padding: spacing(2) spacing(6);
    max-width: 200px;
    height: 43px;
    @include typographyBodyBold;

    &.collapsed {
      width: 43px;
    }
  }

  &.extraLarge {
    padding: spacing(2) spacing(6);
    height: 48px;

    &.collapsed {
      width: 48px;
    }
  }

  @include hover {
    border-color: $primary;
    color: $primary;
  }

  &.full_width {
    max-width: 100%;
  }

  &.collapsed {
    float: right;
    cursor: pointer;
  }

  &.collapse_animate.collapsed {
    transition: $collapse-transition;
    background-color: $primary;

    .input {
      color: $white;
    }

    .button {
      visibility: hidden;
      opacity: 0;
      width: 0;
      height: 0;
    }

    @include hover {
      width: 200px;
      background-color: $white;
      color: $black;

      .input {
        color: $black;
      }

      .button {
        visibility: initial;
        opacity: 1;
        width: 25px;
        height: 25px;
      }
    }
  }
}
