@use '@styles/theme.module.scss' as *;

.price_wrapper {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  min-width: 70px;

  @include media($from: $sm) {
    min-width: 90px;
    flex-direction: column;
    align-items: flex-end;
    margin-left: calc(#{spacing(5) / 2});
  }

  &.drawer_opened {
    align-items: flex-start;
    margin-left: calc(60px + spacing(6));
    flex-direction: row;
  }

  &.drawer_opened > span {
    padding-right: spacing(1);
  }
}

.price {
  color: $black;
  @include typographyBody;
}

.current_price {
  color: $sale;
  @include typographyBody;
}

.previous_price {
  color: $grayDark;
  text-decoration: line-through;

  @include media($until: $sm) {
    margin-left: spacing(1);
  }
}

.free {
  display: flex;
  justify-content: flex-start;
  min-width: 54px;
  color: $black;
  @include typographyBodyBold;

  @include media($from: $sm) {
    justify-content: flex-end;
  }
}
