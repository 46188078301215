@use '@styles/theme.module' as *;

.edit_modal {
  @include media($from: $sm) {
    max-width: 634px;
  }
}

.header {
  margin-bottom: spacing(7);
  @include media($from: $lg) {
    margin-bottom: spacing(8);
  }
}

.title {
  color: $grayDark;
}

.link {
  display: block;
}

.input {
  margin-bottom: spacing(5);
}

.divider {
  margin: spacing(8) 0;
  @include media($from: $sm) {
    margin: spacing(5) 0;
  }
  @include media($from: $lg) {
    margin-top: spacing(11);
    margin-bottom: spacing(7);
  }
}

.address_header {
  margin-bottom: spacing(6);
}

.addresses_wrapper {
  padding-left: spacing(1);
  margin-bottom: spacing(8);
  @include media($from: $sm) {
    margin-bottom: spacing(7);
  }
}

.address {
  text-transform: capitalize;
  margin-bottom: spacing(2);
}
