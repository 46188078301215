@use '@styles/theme.module.scss' as *;

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: spacing(0);
  @include media($from: $largeTablet) {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-gap: spacing(5);
  }
}

.content {
  grid-column: 2 / span 10;
  @include media($from: $lgbp) {
    grid-column: 2 / span 6;
  }
}

.title {
  margin-bottom: spacing(6);
}

.no_addresses_title {
  @include typographyH4;

  color: $grayDark;
  margin: spacing(3) spacing(0) spacing(3);
}

.add_address_desktop {
  @include media($until: $largeTablet) {
    display: none;
  }
}

.add_button_mobile {
  @include media($from: $largeTablet) {
    display: none;
  }

  margin-top: spacing(7);
  width: 100%;
}
