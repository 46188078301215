@use '@styles/theme.module' as *;

.button {
  margin-bottom: spacing(2);
  @include typographySmallBody;

  @include media($from: $lg) {
    @include typographyBody;
  }
}
