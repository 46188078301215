@use '@styles/theme.module' as *;

.tabs_wrapper {
  min-height: 0;

  :global(.MuiButtonBase-root) {
    min-width: 0;
    width: fit-content;
    padding: 0;
    text-transform: none;
    margin-right: spacing(2);
    min-height: 0;
    color: $black;
    @include typographyBody;

    @include media($from: $xs) {
      margin-right: spacing(3);
    }

    @include media($from: $sm) {
      margin-right: spacing(5);
    }
  }

  :global(.MuiTabs-root) {
    min-height: 0;
  }

  :global(.MuiTabs-flexContainer) {
    border-bottom: 0.135rem solid $grayLighter;
  }
}

.tab {
  margin-bottom: spacing(2);

  &:global(.Mui-selected),
  &:global(.MuiTab-root) {
    .tab_link {
      &::after {
        margin-top: 0;
        border-bottom: none;
      }
    }
  }
}

.tab:focus-visible {
  outline: 1px dashed #444;
  outline-offset: 4px;
}

.divider {
  ::after {
    content: '';
    display: block;
  }
}

.tab_alcohol_wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: spacing(5);

  @include media($from: $lg) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.modal_wrapper {
  height: 100%;

  @include media($from: $sm) {
    width: 650px;
  }

  @include media($from: $lg) {
    width: 720px;
  }

  @include media($from: $xl) {
    width: 840px;
  }
}

.modal_inner_content {
  height: 100%;
}

.title_with_bundle {
  padding-top: spacing(7);
  padding-bottom: spacing(4);
  @include typographyH3;
}
