@use '@styles/theme.module' as *;

/*  IDEA: use grid  */

.container {
  width: 100%;
  max-width: 378px;
  margin: auto;
  padding: spacing(5) spacing(0);
}

.title {
  display: flex;
  max-width: 325px;
  margin-bottom: spacing(2);
  @include typographyH3;
}

.additional_info {
  margin-bottom: spacing(7);
  color: $grayDark;
  @include typographyBody;
}

.link {
  color: $primary;
}

.add_to_order_btn {
  margin-bottom: spacing(4);
}

.orders_container {
  max-height: 110px;
  overflow-y: auto;
  margin-bottom: 1rem;
  @include scroll;
}
