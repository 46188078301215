@use '@styles/theme.module' as *;

.wrapper {
  z-index: 2;
  max-width: 195px;
  background: $white;
  box-shadow: 0 0 16px rgb(0 0 0 / 0.04), 0 8px 20px rgb(0 0 0 / 0.1);
  border-radius: 16px;
  padding: spacing(3);

  @include media($from: $largeTablet) {
    max-width: max-content;
  }

  .product_wrapper {
    padding: 0 spacing(3) spacing(3);
    max-width: 250px;

    @include media($from: $largeTablet) {
      width: 250px;
    }
  }

  .title {
    text-transform: uppercase;
    color: $primary;
    margin-bottom: spacing(2);

    @include typographyLabelBold;
  }

  &.v2 {
    border: 1px solid $black10;
    border-radius: spacing(2);
    box-shadow: 0 spacing(4) spacing(8) $black15, 0 0 spacing(5) $black06;
    padding: spacing(6);

    .product_wrapper {
      padding: 0;
      @include media($from: $largeTablet) {
        width: max-content;
      }
    }

    .title {
      margin-bottom: spacing(2);
    }
  }
}

.with_botttom_padding {
  padding: spacing(3) spacing(3) spacing(8);
}
