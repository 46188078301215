// TODO: containes mixed goal. Should be granulated
@mixin hover {
  // Make sure that focus-visible is placed above hover as Safari less 15.4 doesn't support it.
  &:focus-visible {
    @content;
  }

  &:hover,
  &:global(.Mui-focusVisible) {
    @content;
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }
}
