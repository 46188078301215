@use '@styles/theme.module' as *;

.title {
  margin-top: spacing(7);
  margin-bottom: spacing(7);

  @include media($from: $sm) {
    margin-top: spacing(9);
  }
}

.subtitle {
  color: $grayDark;
  margin-top: spacing(8);
  margin-bottom: spacing(3);
  @include typographyBody;
}

.divider {
  margin-top: spacing(7);
  margin-bottom: spacing(7);
  color: $grayLighter;
}
