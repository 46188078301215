@use '@styles/theme.module' as *;
@use 'sass:string';

.bottom_wrapper {
  justify-content: space-between;
  align-items: center;

  @include media($from: $lg) {
    padding-left: spacing(5);
  }
}

.wrapper {
  width: 100%;
}

.image_container {
  width: 100%;
}

.coupon {
  width: 100%;

  &_with_spacing {
    margin-bottom: spacing(3);
  }
}

.add_cart {
  width: 100%;
}

.alternatives_button {
  margin-bottom: spacing(7);
}

.sold_out_label {
  margin-bottom: spacing(5);
  @include typographyH5Medium;
}

.sold_out_block {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-end;

  @include media($from: $sm, $until: $lg) {
    margin-top: spacing(12);
  }
}

.layout {
  display: grid;
  grid-template-areas:
    'alert'
    'topbar'
    'summary'
    'image'
    'group_scale'
    'coupon'
    'actions'
    'description'
    'info';

  @include media($from: $sm) {
    grid-template-columns: 1fr 1fr;
    column-gap: 1.25rem;
    grid-template-areas:
      'alert alert'
      'topbar image'
      'summary image'
      'coupon image'
      'group_scale image'
      'actions image'
      'description image'
      'info image';
  }

  @include media($from: $md) {
    grid-template-columns: 5fr 7fr;
  }

  @include media($from: $lg) {
    grid-template-columns: 4fr 8fr;
    grid-template-areas:
      'alert alert'
      'topbar topbar'
      'summary image'
      'coupon image'
      'group_scale image'
      'actions info'
      'description info';
  }
}

.layout_summary {
  grid-area: summary;
}

.layout_group_scale {
  grid-area: group_scale;
}

.layout_coupon {
  grid-area: coupon;
}

.layout_image {
  grid-area: image;

  @include media($until: $sm) {
    margin-bottom: spacing(7);
  }
}

.layout_actions {
  grid-area: actions;
}

.layout_info {
  grid-area: info;
}

.layout_topbar {
  grid-area: topbar;
}

.layout_description {
  grid-area: description;
}

.layout_alert {
  grid-area: alert;

  &_content {
    @include typographyBody;
  }

  &:has(*) {
    padding-bottom: spacing(5);
  }
}

.group_scale_label {
  margin-bottom: spacing(7);
}

.skeleton {
  &_text {
    width: 100%;
    height: 24px;
    margin-bottom: spacing(4);
  }

  &_button {
    width: 100%;
    height: 48px;
    border-radius: 30px;
  }
}

.carousel {
  width: 100%;
}

.carousel_wrapper {
  margin-top: spacing(8);

  @include media($from: $md) {
    margin-top: spacing(10);
  }
}

.divider {
  display: none;

  @include media($from: $sm) {
    display: block;
    margin-top: spacing(8);
  }
}

.content_layout {
  width: 100%;
  margin: spacing(5) spacing(0) spacing(8) spacing(0);

  @include media($from: $md) {
    margin-bottom: spacing(10);
  }
}
