@use '@styles/theme.module.scss' as *;

.arrow {
  color: $deliveryPassFG;

  &::before {
    border: 1px solid $deliveryPassFG;
  }
}

.tooltip {
  margin: spacing(0);

  .overlay {
    min-width: 255px;
    display: flex;

    p {
      @include typographySmallBody;

      padding: spacing(4);
    }

    svg {
      height: 12px;
      width: 12px;
      margin: spacing(1);
      cursor: pointer;
    }
  }

  &:global(.MuiTooltip-tooltip) {
    padding: 0;
    background-color: $deliveryPassFG;
    border-radius: 4px;
    border: 1px solid #c5521c;
    box-shadow: 0 16px 32px 0 rgb(0 0 0 / 0.15), 0 0 20px 0 rgb(0 0 0 / 0.06);
  }
}
