@use '@styles/theme.module.scss' as *;

.title {
  margin-bottom: spacing(3);
}

.small_title {
  @include typographyH4;
}

.content_container {
  margin-bottom: spacing(9);
}
