@use '@styles/theme.module' as *;

.title {
  color: $grayDark;
  white-space: pre-line;
}

.unavailable_title {
  color: $black;
  max-width: 80%;
  margin-top: spacing(5);
  white-space: pre-line;
  @include typographySmallBody;
  @include media($until: $sm) {
    font-size: 0.875rem;
    margin-top: 0;
  }
}

.department_title {
  color: $black;
  text-transform: uppercase;
  @include typographySmallBody;

  font-weight: 700;
  font-size: 0.75rem;
  line-height: 0.975rem;
}
