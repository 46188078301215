@use '@styles/theme.module' as *;

.tiles {
  display: grid;
  grid-template-columns: 104px repeat(4, 100px);
  overflow-x: auto;
  gap: spacing(2);
  min-height: 84px;
  align-items: center;

  @include scroll;

  padding-bottom: 5px;

  // remove below once styles of scroll on firefox is changed
  margin-left: -2px;
}

.tile,
.label_wrapper,
.input {
  height: 58px;
  width: 100px;
  margin: 0;
}

.label_wrapper:first-child {
  margin-left: 4px;
}

.tile {
  align-items: baseline;
  cursor: pointer;
}

.input:focus + .tile {
  outline: 2px solid $black;
  outline-offset: 0;
}

.input:checked + .tile .tile_text {
  @include typographyBodyBold;
}

.tile_content {
  margin-top: spacing(3);
}

.input:checked + .tile .custom_amount_subtitle {
  @include typographyBodyBold;
}

.fallback_subtitle {
  text-align: center;
  margin: spacing(10) 0;
}
