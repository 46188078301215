@use '@styles/theme.module' as *;

.ad_text {
  max-width: 100%;
  width: 100%;
  text-align: center;

  a {
    color: $primary;
    text-decoration: underline;
  }
}

.ad_container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 100vw;
  position: relative;

  a:focus {
    outline: 1px dashed $grayDarker;
    outline-offset: 2px;
  }

  img {
    max-width: 100%;
    object-fit: contain;
  }

  .dev_banner_controls {
    position: absolute;
    bottom: 0;
    right: 0.5rem;
    z-index: 1;

    button {
      background-color: $white;
      border: 1px solid $grayDarker;
      border-radius: 50%;
      overflow: hidden;
      width: 5px;
      height: 5px;
      padding: 0;
      margin: 0.2rem;
      cursor: pointer;
    }

    button.active {
      background-color: $primary;
    }
  }
}

.developer .ad_container {
  outline: 1px dashed $primary;
}

.ad_banner_content {
  flex: 1;
  display: flex;
  justify-content: center;
}

.ad_banner_text {
  max-width: 95vw;
  width: var(--desktop-width);
  background-image: var(--desktop-image);
  aspect-ratio: var(--desktop-aspect-ratio);
  background-size: cover;
  background-position: center center;
  padding: spacing(2) spacing(4);
  color: $white;
  text-shadow: 1px 1px 1px $black, -1px -1px 1px $black, 1px -1px 1px $black, -1px 1px 1px $black;

  @include media($until: $sm) {
    width: var(--mobile-width);
    background-image: var(--mobile-image);
    aspect-ratio: var(--mobile-aspect-ratio);
  }
}

.ad_dismiss {
  position: absolute;
  z-index: $dismiss;
  right: spacing(2);
}

.visible_on_mobile {
  display: none;

  @include media($until: $sm) {
    display: block;
  }
}

.visible_on_desktop {
  display: block;

  @include media($until: $sm) {
    display: none;
  }
}
