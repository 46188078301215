@use '@styles/theme.module' as *;

.layout {
  display: grid;
  grid-template-areas:
    'summary'
    'image'
    'group_scale'
    'info'
    'actions';

  @include media($from: $sm) {
    grid-template-columns: 1fr 1fr;
    column-gap: 1.25rem;
    grid-template-areas:
      'summary image'
      'summary image'
      'actions image'
      'description image'
      'info image';
  }

  @include media($from: $md) {
    grid-template-columns: 5fr 7fr;
  }

  @include media($from: $lg) {
    grid-template-areas:
      'summary image'
      'summary image'
      'actions image'
      'description info';

    .layout_description {
      display: block;
    }
  }

  &.layout_actions {
    box-shadow: none;

    @include media($from: $sm) {
      box-shadow: none;
    }
  }
}

.layout_summary {
  grid-area: summary;

  .product_name {
    font-size: 2rem;
  }
}

.layout_group_scale {
  grid-area: group_scale;
}

.layout_image {
  grid-area: image;

  .image_container {
    height: 390px;
  }
}

.layout_actions {
  grid-area: actions;

  .infobox {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .info_count {
    color: $primary;
    margin-bottom: spacing(2);
  }
}

.layout_info {
  grid-area: info;
  padding-bottom: spacing(4);
}

.layout_description {
  grid-area: description;
  display: none;
}

.group_scale_label {
  margin-bottom: spacing(7);
}
