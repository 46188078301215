@use '@styles/theme.module' as *;

.wrapper {
  width: 100%;

  & :global(.MuiInputLabel-root) {
    color: $grayDark;
  }

  & :global(.MuiInputBase-root) {
    background-color: $white;
    height: 48px;

    & :global(.MuiInputBase-input) {
      padding: spacing(4) spacing(0) spacing(4) spacing(6);
    }
  }

  & :global(.MuiFormHelperText-root) {
    color: $errorFG;
  }
}
