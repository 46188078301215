@use '@styles/theme.module' as *;

.title {
  padding-top: spacing(7);
  padding-bottom: spacing(4);
  @include typographyH3;
}

.price_container {
  margin-bottom: spacing(4);
  padding-bottom: spacing(4);
  border-bottom: 1px solid $grayLighter;

  @include media($from: $sm) {
    margin-bottom: spacing(6);
    padding-bottom: spacing(6);
  }

  @include media($from: $lg) {
    margin-bottom: spacing(7);
    padding-bottom: spacing(7);
  }
}

.divider {
  width: 100%;
  height: 1px;
  background-color: $black;
  opacity: 0.1;
  margin-bottom: spacing(4);

  @include media($from: $sm) {
    margin-bottom: spacing(6);
  }

  @include media($from: $lg) {
    margin-bottom: spacing(7);
  }
}

.price {
  @include typographyH5Medium;
  @include media($from: $lg) {
    @include typographyH4Medium;
  }
}

.counter {
  margin-bottom: spacing(3);
}

.standing_orders_dropdown {
  margin-top: spacing(3);
  margin-bottom: spacing(2);
}

.product_link {
  display: flex;
  justify-content: center;
  margin-top: spacing(3);
  @include typographySmallBody;

  @include media($from: $sm) {
    margin-top: spacing(4);
  }

  @include media($from: $lg) {
    margin-top: spacing(2);
  }
}

.warnings {
  margin-bottom: spacing(7);
}

.alert[role='alert'] {
  position: absolute;
  z-index: 100;
  min-width: calc(100% - #{spacing(13)});
  transform: translateY(calc(-100% - #{spacing(1)}));

  @include media($from: $xs, $until: $sm) {
    min-width: calc(100% - #{spacing(7)});
  }
}

.add_to_shopping_list_button {
  display: flex;
  margin-top: spacing(2);
  align-items: center;
  cursor: pointer;
  width: fit-content;

  svg {
    margin-right: spacing(2);
  }

  &:hover {
    svg path,
    span {
      color: $primary;
      stroke: $primary;
    }
  }

  &:focus-visible {
    outline: 1px dashed $grayDarker;
    outline-offset: spacing(1);
  }
}

.error_message_icon {
  margin-right: spacing(1);
}

.error_message {
  color: $errorFG;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: spacing(2);
}

.required_note {
  margin-bottom: spacing(6);
  margin-left: spacing(5);
}
