@use '@styles/theme.module.scss' as *;

.wrapper {
  padding-bottom: spacing(9);
  margin-bottom: spacing(9);
  border-bottom: 1px solid $grayLighter;

  @include media($until: $sm) {
    background-color: $warningBG;
    border: 1px solid rgb(0 0 0 / 0.1);
    border-left: none;
    border-right: none;
    margin-left: -1rem;
    margin-right: -1rem;
    padding-bottom: 0;
    width: calc(100% + 2rem);

    &.gray {
      background-color: $gray2;
    }
  }
}

.title {
  color: $grayDark;
}

.capitalize {
  text-transform: capitalize;
}

.tiles_container {
  @include media($until: $sm) {
    .cart_tile {
      margin: 0 spacing(4);
      border-bottom: 1px solid rgb(0 0 0 / 0.1);
    }

    .cart_tile:first-child {
      margin-top: calc(-1 * spacing(4));
    }
  }

  @include media($from: $sm) {
    .cart_tile:first-child {
      margin: 0;
    }

    .cart_tile:last-child {
      border-bottom: none;
    }
  }
}

.subtotal {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-top: spacing(6);
  color: $black;
  margin-left: spacing(5);

  @include media($until: $sm) {
    margin: spacing(6) spacing(4);
  }
}

.subtotal_value {
  @include typographyBodyBold;

  margin-left: 0.75rem;
}

.note {
  margin-top: spacing(6);
  color: $grayDark;
}

.unavailable {
  background-color: $warningBG;
  border-radius: 16px;
  border: 1px solid rgb(0 0 0 / 0.1);

  &.gray {
    background-color: $gray2;
  }

  @include media($until: $sm) {
    border-radius: 0;
    border: none;
  }
}

.unavailable_title {
  color: $black;
  @include typographySmallBody;

  @include media($until: $sm) {
    margin: spacing(6) spacing(4);
  }
}

.out_of_stock {
  background-color: $gray2;
  border-radius: 16px;
  border: 1px solid rgb(0 0 0 / 0.1);
}

.title_wrapper {
  display: flex;
  margin-bottom: spacing(6);
  margin-top: spacing(6);
  margin-left: spacing(5);

  @include media($from: $md) {
    margin-top: spacing(0);
  }
}

.green {
  color: $primary;
  @include typographyBody;
}

.alternative_modal_title_link {
  color: $primary;
}
