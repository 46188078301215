@use '@styles/theme.module' as *;

.image_container {
  position: relative;
  width: 100%;
  min-height: 216px;

  @include media($from: $sm) {
    width: 352px;
    height: 221px;
  }
}

.image {
  border-radius: 18px;
  object-fit: cover;
}

.info {
  margin-top: spacing(7);
  @include typographyBody;

  &_item {
    margin-top: 6px;
    @include typographyBody;

    &:first-child {
      margin-top: 0;
    }

    .to,
    .from {
      @include typographyBodyBold;
    }

    &.message {
      color: $grayDark;
    }
  }
}

.title {
  @include typographyBodyBold;
}
