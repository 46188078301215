@use '@styles/theme.module.scss' as *;

$offset-top: 57px;
$sm-offset-top: 73px;
$md-header-height: 112px;
$lg-header-height: 200px;
$lg-header-height-collapsed: 100px;

.wrapper {
  --top: #{$offset-top};

  top: calc(var(--top) + #{spacing(5)});
  transition: top 0.5s;
  position: sticky;
  height: 0;
  width: 100%;
  z-index: 1000;

  @include media($from: $sm) {
    --top: #{$sm-offset-top};
  }

  @include media($from: $md) {
    --top: #{$md-header-height};
  }
}

@include media($from: $lg) {
  .wrapper {
    --top: #{$lg-header-height};
  }

  .wrapper_scrolled {
    --top: #{$lg-header-height-collapsed};
  }
}

.alert {
  position: absolute;
  width: 100% !important;
  padding: spacing(4);
  @include typographySmallBody;
}
