@use 'sass:map';

@mixin buttonGeneralStyles {
  letter-spacing: -0.01em;
  text-transform: none;
  border-radius: map.get($radius, 'button');
  text-align: center;
}

@mixin focusDashedBorderStyles {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    border: 1px dashed transparent;
    transition: all 0.25s ease-out;
    border-radius: 2.5rem;
  }

  &:focus,
  &:focus-within {
    outline: none;

    &::before {
      top: -#{spacing(1)};
      bottom: -#{spacing(1)};
      right: -#{spacing(1)};
      left: -#{spacing(1)};
      border-color: $grayDarker;
    }
  }
}
