@use '@styles/theme.module.scss' as *;
@use 'sass:map';

$icon-space: 28px;

%label-general-styles {
  display: inline-flex;
  align-items: center;
  margin-left: $icon-space;
}

.wrapper {
  display: flex;
  position: relative;
}

.reverse {
  flex-direction: row-reverse;
}

.label {
  @extend %label-general-styles;
  @include typographySmallBody;
}

.label_xlarge {
  @extend %label-general-styles;
  @include typographyBody;
}

.default_checkbox_label {
  position: relative;
  cursor: pointer;

  .checkmark {
    position: absolute;
    left: -$icon-space;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    padding: 1px 0 0;
    width: 16px;
    height: 16px;
    border: 1px solid $grayLight;
    border-radius: map.get($radius, 'xs');
    margin-right: spacing(3);
    color: transparent;
  }

  &:hover {
    color: $primary;

    .checkmark {
      border-color: $primary;
    }
  }

  &.center {
    .checkmark {
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &.top {
    .checkmark {
      top: 0.15em; // label line-height compensation

      @include media($from: $xl) {
        top: 0.2em;
      }
    }
  }
}

.default_checkbox {
  position: absolute;
  opacity: 0;
  z-index: -1;

  &:focus {
    + .default_checkbox_label {
      outline: none;
    }
  }

  &:focus-visible {
    + .default_checkbox_label .checkmark {
      outline: 2px solid -webkit-focus-ring-color;
    }
  }

  @-moz-document url-prefix('') {
    &:focus-visible {
      + .default_checkbox_label .checkmark {
        outline: 2px solid $grayDark;
      }
    }
  }

  /* stylelint-disable no-descending-specificity */

  &:disabled ~ .default_checkbox_label {
    color: $grayDark;
    cursor: not-allowed;

    .checkmark {
      background-color: $grayLighter;
      border: 1px solid $grayLighter;
    }

    &:hover {
      color: $grayDark;
    }
  }

  &:checked ~ .default_checkbox_label {
    .checkmark {
      color: $white;
      background-color: $black;
      border: 1px solid $black;
    }

    &:hover {
      color: $black;
    }
  }

  /* stylelint-enable no-descending-specificity */

  &:disabled:checked ~ .default_checkbox_label {
    color: $grayDark;
    cursor: not-allowed;

    .checkmark {
      background-color: $gray2;
      border: 1px solid $gray2;
      color: $black;
    }

    &:hover {
      // disable default hover effect for this case
      color: $grayDark;
    }
  }
}

.default_checkbox:checked {
  &:focus-visible {
    + .default_checkbox_label .checkmark {
      color: inherit;
      outline: 2px solid $grayDark;
      outline-offset: 2px;
    }
  }
}
