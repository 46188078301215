@use '@styles/theme.module' as *;

.wrapper {
  display: grid;
  grid-gap: spacing(5);
  grid-template-columns: 1fr 1fr 1fr;

  @include media($until: $sm) {
    display: grid;
    grid-template-columns: 1fr;
    gap: spacing(6);
  }
}
