@use '@styles/theme.module' as *;

.title {
  margin-top: spacing(7);
  margin-bottom: spacing(7);
  @include typographyBody;

  @include media($from: $sm) {
    margin-top: spacing(9);
  }
}

.subtitle {
  color: $grayDark;
  margin-top: spacing(8);
  margin-bottom: spacing(3);
  @include typographyBody;
}

.divider {
  margin-top: spacing(7);
  margin-bottom: spacing(7);
  color: $grayLighter;
}

.imageContainer {
  position: relative;
  border-radius: spacing(3);
  height: 216px;
  max-width: 100%;
  box-shadow: 0 2px 12px rgb(0 0 0 / 0.1);
}

.imageTitle {
  margin-top: spacing(7);
  max-width: calc(100% - 80px);
}

.image {
  border-radius: spacing(3);
}

@include media($from: $sm) {
  .imageContainer {
    border-radius: spacing(5);
    height: 340px;
    width: 544px;
  }

  .imageTitle {
    margin-top: spacing(8);
    max-width: calc(100% - 100px);
  }

  .image {
    border-radius: spacing(5);
  }
}

.submit_button {
  margin-bottom: spacing(7);
}
