@use '@styles/theme.module' as *;

.modal {
  @include media($from: $sm) {
    max-width: fit-content;
  }
}

.title {
  margin-bottom: spacing(7);
  max-width: 70%;
}

.spinner {
  text-align: center;
}

.subtitle {
  color: $grayDark;
  margin-bottom: spacing(7);
}

.loading_icon {
  color: $grayLighter;
}
