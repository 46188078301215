@use '@styles/theme.module.scss' as *;

.description {
  color: $grayDark;
  margin-left: 1.75rem;
}

.phone {
  color: $primaryLight;
}

.phone_wrapper {
  border-color: $primaryLight;
  margin-right: spacing(1);
}

.link_wrapper {
  margin-right: spacing(1);

  span {
    color: $primaryLight;
    border-bottom: none;
  }
}

.and_sign {
  margin-right: spacing(1);
}

.modal {
  @include media($from: $sm) {
    width: 443px;
  }
}

.show_btn {
  color: $grayDark;
  padding: 0;
  @include typographySmallBody;

  &:focus {
    color: $grayDark;

    &::before {
      background-color: $black;
    }
  }
}
