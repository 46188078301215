@use '@styles/theme.module' as *;

.password:first-of-type {
  margin-bottom: spacing(4);

  &:global(.MuiFormHelperText-root) {
    @include typographySmallBody;
  }
}

.helper_text_icon {
  height: 12px;
  width: 12px;
  color: $primary;
  margin-left: spacing(1);
}

.create_password {
  display: block;
  margin-bottom: spacing(7);
}
