@use '@styles/theme.module' as *;

.container {
  height: 100%;
}

.title {
  margin-top: spacing(7);
  @include typographyH3;
}

.subtitle {
  margin: spacing(7) spacing(0);
  @include typographyBody;
}
