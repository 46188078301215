@use '@styles/theme.module.scss' as *;
@use 'sass:map';

.buttonText {
  padding: spacing(0);
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  background: none;
  border: none;
  border-radius: map.get($radius, 'md'); // to round out the outline
  cursor: pointer;
  color: $black;
  text-decoration: underline;
  @include typographyButtonsAndLinksMedium;

  &:disabled {
    color: $grayLight;
    cursor: not-allowed;
  }

  &:focus-visible {
    outline: 1px dashed $grayDarker;
    outline-offset: spacing(1);
  }

  &.primary {
    &:hover {
      color: $primary;
    }
  }

  &.secondary {
    &:hover {
      color: $orange;
    }
  }
}

.icon_container {
  display: flex;
  align-items: center;
  margin-left: spacing(1);
}

.icon > svg {
  color: $black;
}
