/* stylelint-disable no-descending-specificity */
@use '@styles/layouts' as *;
@use '@styles/theme.module' as *;

.left_nav,
.additional_left_nav {
  display: none;

  @include media($from: $lg) {
    display: flex;
    position: relative;
    width: 300px;
    flex-direction: column;
    padding: spacing(7) spacing(2) spacing(8) spacing(0);
    list-style: none;
  }

  @include media($from: $lgbp) {
    padding-right: spacing(5);
  }

  h2 {
    display: flex;
    margin: 0;
    padding: 0 spacing(2) spacing(3);

    a.header {
      @include typographyH6Bold;

      text-decoration: none;
    }
  }

  ul {
    display: none;
    margin: 0;
    padding: 0;
    list-style: none;

    &.is_open,
    &.is_root {
      display: flex;
      flex-direction: column;
    }
  }

  li {
    @include typographyBody;

    cursor: pointer;

    &.list_item {
      position: relative;
      padding: spacing(2) 0;
      overflow: hidden;

      &:last-child {
        padding-bottom: 0;
      }

      &.is_active {
        @include typographyBodyBold;

        &::before {
          position: absolute;
          content: ' ';
          height: 22px;
          margin: 0 spacing(1) 0 0;
          border-left: 4px solid $primary;
        }
      }
    }

    .link_wrapper {
      display: flex;
      align-items: center;
    }

    a {
      margin-left: spacing(2);
      text-decoration: none;

      &:hover {
        color: $primary;
      }
    }

    ul {
      margin: spacing(2) 0 0 spacing(4);
    }

    button {
      min-height: 22px;
      cursor: pointer;

      &.rotate svg {
        transform: rotate(180deg);
      }
    }

    button:focus > svg,
    a:active,
    a:focus {
      outline: 1px dashed $grayDarker;
      outline-offset: spacing(1);
      border: none;
    }
  }

  .arrow_down {
    margin: 0;
    border: 0;
    background: none;
  }
}

.additional_left_nav {
  display: flex;
  flex-direction: column;

  ul {
    display: flex;
    flex-direction: column;
  }

  li {
    margin: spacing(2) 0;
    padding: 0;

    &.list_item {
      display: flex;
      align-items: center;
      margin: 0;
    }
  }
}
