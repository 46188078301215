@use '@styles/theme.module.scss' as *;
@use '@styles/layouts' as *;

@mixin footerContainer() {
  width: 100%;
  padding: spacing(4);
  max-width: $xl + px;

  @include media($sm) {
    padding: spacing(5);
  }

  @include media($md) {
    padding: spacing(7);
  }

  @include media($lg) {
    padding: spacing(11);
  }

  @include media($xl) {
    padding: spacing(13);
  }
}

.footer {
  @include footerContainer;

  background-color: $gray1;
  margin-top: spacing(10);

  .container {
    display: flex;
    justify-content: space-between;
    margin-top: spacing(10);
    margin-bottom: spacing(9);
    gap: spacing(8);

    .column {
      flex: 1;
      max-width: 352px;
    }

    @include media($until: $sm) {
      flex-direction: column;

      .column {
        max-width: 100%;
      }
    }
  }
}

.icon {
  fill: currentcolor;
}

.terms_and_conditions {
  @include container;

  background-color: $white;

  .terms_and_conditions_title {
    padding: spacing(4) 0;
    color: $grayDark;

    @include typographyBody;

    line-height: 0.975rem;
  }
}
