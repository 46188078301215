@use '@styles/theme.module' as *;
@use 'sass:map';

.link {
  text-decoration: none;
  letter-spacing: -0.01em;
  display: inline-flex;
  align-items: center;
  border-radius: map.get($radius, 'md');

  &.inherit_parent {
    display: inline;
  }

  &:focus {
    outline: 1px dashed #444;
    outline-offset: 3px;
  }

  &_icon {
    width: 16px;
    height: 7px;
    display: inherit;
    margin-left: spacing(2);
  }

  &_text {
    color: $black;
    border-bottom: 1px solid $black;
    transition: 0.2s ease-in-out;

    &.inherit_parent {
      color: inherit;
      border-bottom-color: inherit;
    }
  }

  &.active,
  &.active > span {
    .link_text {
      color: $primaryDark;
      border-color: $primaryDark;
    }
  }

  @include hover {
    .link_text,
    .link_text > span {
      color: $primaryDark;
      border-color: $primaryDark;
    }

    .link_text.inherit_parent,
    .link_text.inherit_parent > span {
      color: inherit;
      border-color: inherit;
    }
  }

  &:focus-visible {
    outline: 1px dashed $grayDarker;
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }
}

.disable_border {
  border-bottom: none;
}

.current_selected {
  color: $primaryDark;
}
