@use '@styles/theme.module' as *;

.email {
  margin-bottom: spacing(4);
}

.checkbox_additional_text {
  color: $grayDark;
  margin-left: 1.75rem;
}

.alert {
  min-width: 100%;
  margin-bottom: spacing(4);

  &.success {
    padding: spacing(2) spacing(4);
  }
}

.email_text_label {
  @include typographySmallBody;

  color: $grayDark;
  text-transform: uppercase;
}

.email_text {
  @include typographyH5;

  margin-bottom: spacing(4);
}

.contact_us {
  @include typographySmallBodyMedium;

  color: $black;
  cursor: pointer;
  margin-bottom: 0.3em;

  &:hover {
    color: $primaryDark;
  }
}
