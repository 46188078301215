@use '@styles/theme.module.scss' as *;

.alert.alert {
  position: fixed;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 4px;
  border: 1px solid $deliveryPassFG;
  background-color: $deliveryPassBG;
  z-index: 9;
  box-shadow: 0 0 12px rgb(0 0 0 / 0.02), 0 4px 16px rgb(0 0 0 / 0.1);
  padding: spacing(4) spacing(4) 0;
  max-width: 223px;
  height: 116px;
  align-items: flex-start;

  @include media($from: $sm) {
    transform: none;
    left: 60px;
  }
}

.text {
  color: $grayDark;
}

.highlight {
  color: $black;
  text-decoration: underline;
  @include typographySmallBodyBold;
}

.action.action {
  @include media($from: $xs, $until: $sm) {
    width: 24px;
    height: 24px;
    background: $white;
    border: 1px solid $grayLighter;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -12px;
    right: -12px;

    button {
      padding: 0;
    }
  }
}
