@use '@styles/theme.module' as *;
@use 'sass:map';

$switch-background: rgb(120 120 128 / 0.16);

.wrapper {
  height: 31px;
  min-width: 51px;
  background-color: $switch-background;
  display: block;
  border-radius: 50px;
  transition: all ease 0.5s;
  position: relative;

  &:focus-within {
    outline: 1px dashed $grayDarker;
    outline-offset: spacing(1);
  }
}

.track {
  border-radius: 16px;
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: $switch-background;
}

.thumb {
  height: 27px;
  width: 27px;
  border-radius: 50%;
  position: absolute;
  background-color: $white;
  margin: auto;
  top: 0;
  left: 2px;
  bottom: 0;
  box-shadow: 0 3px 8px rgb(0 0 0 / 0.15), 0 3px 1px rgb(0 0 0 / 0.06);
}

.input {
  cursor: pointer;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 1;
  margin: 0;
}

.checked {
  .track {
    background-color: $primary;
  }

  .thumb {
    right: 2px;
    left: auto;
  }
}

.checked:hover {
  .track {
    background-color: $primaryDark;
  }
}

.unChecked:hover {
  .track {
    outline: 1px solid $primary;
  }
}
