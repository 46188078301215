@use '@styles/theme.module.scss' as *;
@use 'sass:map';

$radio-circle-size: 16px;

%label-general-styles {
  display: inline-flex;
  align-items: center;
  position: relative;
  color: $black;
  padding-left: spacing(6);
  cursor: pointer;
}

.wrapper {
  display: flex;
  position: relative;
  padding-left: 1px;
}

.label {
  @extend %label-general-styles;
  @include typographySmallBody;
}

.labelXLarge {
  @extend %label-general-styles;
  @include typographyBody;
}

.full_width {
  width: 100%;
  padding: 0;
}

.label,
.labelXLarge {
  &::before {
    content: '';
    width: $radio-circle-size;
    height: $radio-circle-size;
    background-color: transparent;
    box-shadow: 0 0 0 1px $grayLight;
    border: 2px solid $white;
    border-radius: map.get($radius, 'full');
    position: absolute;
    top: 0;
    left: 0;
  }

  &:hover {
    // enable hover effect by default
    color: $primary;

    &::before {
      box-shadow: 0 0 0 1px $primary;
    }
  }
}

.orange {
  &:hover {
    color: $orange;

    &::before {
      box-shadow: 0 0 0 1px $orange;
    }
  }
}

.defaultCheckbox {
  position: absolute;
  opacity: 0;
  z-index: -1;

  &:focus {
    + .defaultRadioLabel::before {
      outline: 2px solid -webkit-focus-ring-color;
    }
  }

  @-moz-document url-prefix('') {
    &:focus {
      + .defaultRadioLabel::before {
        outline: 2px solid $grayDark;
      }
    }
  }

  &:checked ~ .label {
    &::before {
      background-color: $white;
      border: 2px solid transparent;
    }
  }

  &:checked ~ .labelXLarge {
    &::before {
      background-color: $black;
      border: 2px solid $white;
    }
  }

  &:disabled ~ .label,
  &:disabled ~ .labelXLarge {
    color: $grayDark;
    cursor: not-allowed;

    &::before {
      box-shadow: 0 0 0 1px $grayLighter;
      background-color: $grayLighter;
      border: 2px solid $grayLighter;
    }
  }

  &:checked ~ .dark {
    &::before {
      border: 2px solid $primary;
      box-shadow: 0 0 0 1px $white;
    }
  }

  &:checked:focus {
    + .label::before {
      outline: 2px solid $white;
      width: calc(#{$radio-circle-size} - 4px);
      height: calc(#{$radio-circle-size} - 4px);
      outline-offset: 2px;
      background-color: $white;
    }

    + .labelXLarge::before {
      outline: 2px solid $black;
    }
  }

  &:disabled:checked ~ .label,
  &:disabled:checked ~ .labelXLarge {
    color: $grayDark;

    &::before {
      box-shadow: 0 0 0 1px $grayLighter;
      background-color: $grayDark;
      border: 2px solid $white;
    }
  }

  &:disabled ~ .label:hover,
  &:disabled ~ .labelXLarge:hover {
    // disable default hover effect for this case
    color: $grayDark;
  }

  &:checked ~ .label:hover,
  &:checked ~ .labelXLarge:hover {
    &:hover {
      // disable default hover effect for this case
      color: $black;
    }
  }

  &:disabled:checked ~ .label:hover,
  &:disabled:checked ~ .labelXLarge:hover {
    // disable default hover effect for this case
    color: $grayDark;
  }
}
