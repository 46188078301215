@use '@styles/theme.module' as *;

.preview_block {
  width: 100%;

  @include media($from: $sm, $until: $lg) {
    margin-bottom: spacing(12);
  }
}

.spacing {
  display: block;
  margin-bottom: spacing(2);

  &:last-child {
    margin: 0;
  }
}

.freshness {
  margin: spacing(2) 0 spacing(4);
  @include typographyBody;
}

.product_name {
  margin-bottom: spacing(4);
  @include typographyH3Bold;
  @include media($from: $sm) {
    @include typographyH1Bold;
  }
}

.wine_and_spirits_logo {
  height: 22px;
  width: 205px;
  fill: $grayDarker;
  margin-bottom: spacing(7);
}

.product_page_tags {
  display: flex;
  @include typographyBody;
}

.product_details {
  margin-bottom: spacing(4);
}

.product_info_items {
  display: inline;

  & * {
    @include typographyBodyBold;
  }
}

.product_page_quantity {
  padding-left: spacing(1);
  border-left: 1px solid $beige;

  & * {
    color: $black;
    @include typographyBodyBold;
  }
}

.breadcrumbs {
  display: flex;
  margin-bottom: spacing(2);
}

.weight_disclaimer {
  margin: 0;
  color: $grayDark;
}

.wine_description {
  color: $grayDark;
  @include typographyBody;
}

.scale_price {
  color: $sale;
  @include typographyBodyBold;
}

.product_price_and_quantity {
  display: flex;
}

.header {
  display: inline-block;

  svg {
    cursor: pointer;
    fill: $grayLight;
    color: $white;
  }

  .product_name {
    display: inline;
    margin-right: spacing(2);
  }
}
