@use '@styles/theme.module.scss' as *;

.container {
  margin-bottom: spacing(4);
}

.details_wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;

  @include media($from: $largeTablet) {
    margin-bottom: spacing(0);
  }

  .no_left_padding {
    padding-left: spacing(0);
  }

  &.order_error {
    align-items: felx-start;
  }

  & .prices_wrapper {
    padding-left: spacing(0);
  }
}

.detail_items {
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
}

.order_minimum_desktop {
  display: none !important;

  @include media($from: $largeTablet) {
    display: block !important;
  }
}

.promo_applied {
  display: inline-block;
  margin-top: spacing(2);

  @include media($from: $lg) {
    margin-left: spacing(3);
  }
}
