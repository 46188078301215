@use '@styles/theme.module' as *;

.indicator_container {
  width: 100%;
  margin: spacing(8) 0;

  .message_input {
    width: 100%;

    :global(.MuiOutlinedInput-root) {
      border-radius: 0;
      color: $black;
      min-height: 48px;
      width: 100%;
      border: 0;
      border-bottom: 1px solid $grayLight;

      input {
        padding: 0;
      }

      @include media($from: $xl) {
        min-height: 52px;
      }

      fieldset {
        border: 0;
      }

      &:hover fieldset {
        @include media($from: $xs) {
          border: 0;
          background: none;
          border-bottom: 1px solid $grayLight;
        }
      }

      &:global(.Mui-focused) > :global(.MuiOutlinedInput-notchedOutline) {
        border: 0;
        background: none;
      }

      &:global(.Mui-disabled) > :global(.MuiOutlinedInput-notchedOutline) {
        border: 0;
        border-bottom: 1px solid $grayLighter;
      }

      &:global(.Mui-disabled) {
        background-color: transparent;
        border: 0;
      }
    }
  }

  .message_section {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    button {
      max-width: 100px;
      min-height: 40px;
      margin: spacing(2);
      padding: spacing(2);
      left: calc(100% - 120px);
    }
  }

  .checkbox {
    margin: spacing(1) 0;
  }

  .warning {
    color: $errorFG;
    margin: spacing(1) spacing(2);
  }

  .checkbox_section {
    display: flex;
    align-items: baseline;
  }

  .label {
    margin-top: spacing(1);
  }
}
