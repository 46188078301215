@use '../theme.module.scss' as *;

$input-border-size: 1px;
$label-scale-size: get-scale(get-font-size($label, $xs), get-font-size($body, $xs));
$label-scale-size-xl: get-scale(get-font-size($label, $xl), get-font-size($body, $xl));
$label-vertical-translate: calc(
  -#{get-font-size($label, $xs)} / #{$label-scale-size} / 2 + #{$input-border-size}
);
$label-vertical-translate-xl: calc(
  -#{get-font-size($label, $xl)} / #{$label-scale-size-xl} / 2 + #{$input-border-size}
);
$shrink-label-height: calc(
  #{get-font-size($label, $xs)} / #{$label-scale-size} / #{$label-scale-size}
);
$shrink-label-height-xl: calc(
  #{get-font-size($label, $xl)} / #{$label-scale-size-xl} / #{$label-scale-size-xl}
);

@mixin labelSelectTransformation {
  transform: translate(31px, $label-vertical-translate) scale($label-scale-size);

  @include media($from: $xl) {
    transform: translate(28px, $label-vertical-translate-xl) scale($label-scale-size-xl);
  }
}

@mixin formControl {
  position: relative;

  &.mini {
    height: 36px;
  }

  &.medium {
    height: 40px;
  }

  &.standard {
    height: 48px;
  }

  &.large {
    height: 52px;
  }

  & :global(.MuiInputLabel-root) {
    transform: translate(spacing(5), -50%) scale(1);
    color: $black !important;
    top: 50%;

    @include labelTransition;
  }

  &.withPlaceholder {
    & :global(.MuiInputLabel-root),
    &.selected :global(.Mui-focused):global(.MuiInputLabel-shrink) {
      position: absolute;
      top: 0;
      color: $grayDark !important;
      font-weight: 700;
      text-transform: uppercase;
      background-color: $white;
      padding: 0 spacing(1);
      z-index: 1;

      @include labelTransition;
      @include labelSelectTransformation;
    }
  }

  &.selected :global(.Mui-focused):global(.MuiInputLabel-shrink) {
    transform: translate(spacing(5), -50%) scale(1);
    top: 50%;
    color: $black !important;
    font-weight: 400;
  }

  &.selected :global(.Mui-focused) legend span {
    display: none;
  }

  &:not(.selected) :global(.MuiInputLabel-shrink) {
    text-transform: uppercase;
    padding: 0;
  }

  &.disabled :global(.MuiInputLabel-root):not(:global(.Mui-focused)) {
    color: $grayDark !important;
  }

  & :global(.MuiInputLabel-shrink) {
    top: 0;
    color: $grayDark !important;
    font-weight: 700;
    pointer-events: none;

    @include labelSelectTransformation;
  }

  &.error :global(.MuiInputLabel-shrink) {
    color: $errorFG !important;
    top: 0;

    @include labelSelectTransformation;
  }

  & legend {
    margin: 0 spacing(4);
    text-transform: uppercase;
  }
}
