@use '@styles/theme.module.scss' as *;

.title {
  margin-bottom: spacing(2);
  @include typographyH4Medium;
  @include media($from: $sm) {
    @include typographyBodyBold;
  }
}

.titleDescriptions {
  color: $grayDark;
  margin-bottom: spacing(5);
  max-width: 400px;
  @include typographyBody;

  @include media($from: $sm) {
    margin-bottom: spacing(8);
  }
}

.titleAmount {
  margin-bottom: spacing(4);
  @include typographyH3;
}

.amount {
  margin-bottom: spacing(5);
  display: block;
  @include typographyH1Bold;
}

.pageTitleWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1px solid $grayLighter;
  margin-bottom: spacing(8);

  @include media($from: $sm) {
    flex-direction: row;
    align-items: center;
  }
}

.alert {
  position: relative;
  top: -50px;
}

.pageSubTitleWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: spacing(2);

  @include media($from: $sm) {
    flex-direction: row;
    align-items: center;
  }
}

.purchaseTitle {
  margin-top: spacing(8);
}

.noMargin {
  margin-bottom: 0;
}

.flexWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @include media($from: $sm) {
    width: auto;
  }
}

.button {
  width: 200px;
  margin-bottom: spacing(2);
  @include typographyBodyMedium;
}

.purchase_wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @include media($from: $sm) {
    p {
      text-align: right;
    }

    align-items: flex-end;
  }
}

.allGiftCardBtn {
  margin-bottom: spacing(8);
  @include typographyBodyMedium;
}

.container {
  @include media($from: $largeTablet) {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-gap: spacing(5);
  }
}

.content {
  grid-column: 2 / span 10;
  @include media($from: $lgbp) {
    grid-column: 2 / span 6;
  }
}
