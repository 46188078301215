@use '@styles/theme.module' as *;

.order_wrapper {
  margin: spacing(1) 0 spacing(4) spacing(1);

  &_info {
    display: flex;
    flex-direction: column;
  }

  &_order_id {
    @include typographySmallBody;
  }
}
