/* stylelint-disable */
$xs: 0;
$xsMobile: 320; //smallest acceptable reflow
$smMobile: 400; //TUE-16744
$sm: 768;
$largeTablet: 1024; //TUE-12673
$md: 1180;
$lg: 1180;
$lgbp: 1440;
$xl: 1920;

$breakpoints: (
  'xs': $xs,
  'xsMobile': $xsMobile,
  'smMobile': $smMobile,
  'sm': $sm,
  'largeTablet': $largeTablet,
  'md': $md,
  'lg': $lg,
  'lgbp': $lgbp,
  'xl': $xl,
);

@each $breakpoint, $value in $breakpoints {
  :export {
    #{unquote($breakpoint)}: $value;
  }
}
