@use '@styles/theme.module' as *;

.block {
  margin-top: spacing(5);
}

.heading {
  margin: 0 0 spacing(7) 0;
  @include typographyH3;

  *:first-child {
    margin-right: spacing(1);
  }

  @include media($from: $sm) {
    margin-bottom: spacing(5);
    display: flex;
    flex-direction: column;
  }

  @include media($from: $lg) {
    margin-bottom: spacing(4);
  }

  @include media($from: $xl) {
    margin-bottom: spacing(7);
  }
}

.title,
.text,
.list {
  margin: 0;
  @include typographyBody;
}

.title {
  color: $black;
  @include typographyBodyBold;
}

.text,
.list {
  color: $grayDark;
}

.list {
  padding-left: spacing(4);
}
