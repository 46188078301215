@use '@styles/theme.module' as *;

.collapse_button {
  margin: 0 auto;

  .icon {
    color: $primary;
    width: 14px;
    height: 14px;
  }

  &:global(.MuiIconButton-root) {
    border-width: 2px;
  }

  &.v2,
  &:hover {
    background-color: $primary;

    .icon {
      color: $white;
    }
  }

  &.v2 {
    height: 32px;
    width: 32px;

    &:global(.MuiIconButton-root) {
      border: none;
    }

    &:hover {
      background-color: $primaryDark;
      border-color: $primaryDark;
      box-shadow: 0 4px 4px 0 $black06;
    }

    .icon {
      width: initial;
      height: initial;
    }
  }
}
