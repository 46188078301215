@use '@styles/theme.module.scss' as *;

@mixin container() {
  width: 100%;
  padding: spacing(4);
  max-width: $xl + px;

  @include media($sm) {
    padding: spacing(5);
  }

  @include media($md) {
    padding: spacing(7);
  }

  @include media($lg) {
    padding: spacing(11);
  }

  @include media($xl) {
    padding: spacing(13);
  }
}

.content {
  @include container;

  margin: 0;
  max-width: 650px;
  grid-column: 2 / span 10;
  @include media($from: $lgbp) {
    grid-column: 2 / span 6;
  }
}

.margin_bottom {
  margin-bottom: spacing(6);
}

.credit {
  color: $grayDarker;
  font-size: 28px;
}

.share {
  color: $grayDarker;
  font-size: 14px;
}

.error,
.success {
  background-color: $primaryLightest;
  color: $primary;
  border: 1px solid #0000001a;
  border-radius: 4px;
  padding: 16px;
}

.error {
  background-color: $errorBG;
  color: $errorFG;
}

.copy_button {
  border: 1px solid #ddd;
  border-radius: 40px;
  background-color: $white;
  font-size: 14px;
  font-weight: 500;
  padding: spacing(3) spacing(9);
}

.copy_button:hover {
  cursor: pointer;
  border: 1px solid $black;
}

.start_shopping {
  padding: spacing(4) spacing(8);
}
