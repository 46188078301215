@use '@styles/theme.module.scss' as *;

.wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 0 spacing(5) 0 spacing(13);

  &.drawer_opened {
    flex-direction: column;
    align-items: flex-start;
    padding: spacing(3) spacing(5);
  }
}

.title {
  flex-shrink: 0;
  margin-right: spacing(3);
  min-width: spacing(15);
  @include typographyBodyBold;
}

.button {
  flex-shrink: 0;
  margin-left: auto;
}

.dropdown {
  max-width: 250px;
  margin-right: spacing(3);
}

.freeItemMark {
  position: absolute;
  right: 0;
  top: 30px;
}
