@use '@styles/theme.module' as *;

.modal {
  @include media($from: $sm) {
    max-width: 634px;
  }
}

.dropdown {
  margin-bottom: spacing(8);
}
