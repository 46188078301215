@mixin scroll {
  scrollbar-width: thin;
  scrollbar-color: $grayLighter transparent;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $grayLighter;
    border-radius: 3px;
  }
}

@mixin scrollMargin {
  &::-webkit-scrollbar-track {
    margin: 10px;
  }
}
