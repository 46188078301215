@use '@styles/theme.module' as *;

.service_type_error {
  &:global(.MuiPaper-root) {
    margin-bottom: spacing(4);
    border-radius: spacing(1);
    padding-top: spacing(2);
    padding-bottom: spacing(2);
  }

  &_link {
    @include typographyButtonsAndLinksMedium;

    display: inline-block;
    color: $errorFG;
  }
}
