@use '@styles/theme.module' as *;

.wine_logo {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  background-color: $white;

  &_select_mode {
    right: spacing(5);
  }
}

.tile_image {
  width: 100%;
  height: 100%;
  object-fit: contain;

  &_container {
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: 0;
    width: 100%;
    height: 120px;
    cursor: pointer;

    &.disabled {
      opacity: 0.25;
    }

    &.v2 {
      aspect-ratio: 1;
      display: block;
      height: unset;
      overflow: hidden;
      padding: 1px;

      @supports not (aspect-ratio: 1) {
        &::before {
          content: '';
          float: left;
          padding-top: 100%;
        }

        &::after {
          content: '';
          display: block;
          clear: both;
        }
      }

      img {
        border: 1px solid $grayF1;
        border-radius: radius('xlg');
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
      }

      &.disabled {
        opacity: 1;

        img {
          border-color: $grayLighter;
          opacity: 0.4;
        }
      }
    }
  }
}

.skeleton {
  width: 100%;
  height: 100%;
}
