@use '@styles/theme.module' as *;
@use 'sass:map';

.additional_info {
  width: 100%;

  @include media($from: $lg) {
    flex-grow: 1;
    width: auto;
  }
}

.wrapper {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  border-top: 1px solid $grayLighter;
  padding-top: spacing(7);
  margin-top: spacing(7);

  @include media($from: $lg) {
    justify-content: flex-end;
    border: none;
  }
}

.item {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: spacing(3) spacing(2);
  background-color: $white;
  border: 1px solid $grayLighter;
  border-radius: map.get($radius, 'md');
  margin-left: spacing(2);
  color: $black;
  cursor: pointer;

  &:focus-visible {
    outline: 1px dashed $grayDarker;
    outline-offset: spacing(1);
  }

  &:first-child {
    margin-left: 0;
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }

  @include media($from: $lg) {
    max-width: 90px;
  }

  &_name {
    margin-top: spacing(2);
    width: max-content;
  }
}

.skeleton {
  &_item {
    height: 70px;
    width: 100%;
    margin-left: spacing(2);
    @include media($from: $lg) {
      width: 90px;
      margin-top: spacing(2);
    }
  }
}
