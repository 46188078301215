/* stylelint-disable selector-pseudo-element-no-unknown */
/* stylelint-disable selector-pseudo-class-no-unknown */
@use '@styles/theme.module' as *;
@use '../Input/Input.module' as *;

.search_input {
  @include inputDefaultStyles;

  input {
    appearance: none;

    &::placeholder {
      color: $grayDark;
      opacity: 1;

      @include media($from: $xl) {
        @include typographySmallBody;
      }
    }

    &:focus {
      &::input-placeholder {
        color: transparent;
      }

      &:placeholder {
        color: transparent;
      }

      &::placeholder {
        color: transparent;
      }

      &:input-placeholder {
        color: transparent;
      }
    }
  }

  &:global(.MuiFormControl-root) {
    fieldset {
      border: none;
    }

    & :global(.MuiInputBase-root) {
      padding-left: 24.5px;
    }

    & :global(.MuiOutlinedInput-root) {
      background-color: $gray1;
      color: $black;
      border-radius: 100px;
      filter: drop-shadow(0 2px 1px rgb(0 0 0 / 0.3));
      transition: all 0.25s ease-out, visibility 0s;

      &:global(.Mui-focused),
      &:focus-within {
        background-color: $white;
        filter: drop-shadow(0 5px 18px rgb(0 0 0 / 0.15));
        border-radius: 30px;
      }

      &:hover fieldset {
        border: none;
      }

      &:global(.Mui-focused) > :global(.MuiOutlinedInput-notchedOutline) {
        border: none;
      }
    }
  }
}

.search_icon {
  @include media($from: $lg) {
    width: 15px;
    height: 15px;
  }
}
