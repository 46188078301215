@use '@styles/theme.module.scss' as *;
@use 'sass:map';

.link {
  color: $black;

  &:hover {
    color: $primary;
  }
}
