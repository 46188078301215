@use '@styles/theme.module.scss' as *;

.product_card {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $white;
  cursor: pointer;
  outline: none;

  &_main_info {
    flex: 1 1 auto;
    margin: 0 0 spacing(3) 0;
  }

  a {
    display: block;
    padding-top: 1rem;
  }

  &.v2 {
    max-width: $product-tile-max-width;

    .product_card_main_info {
      margin: 0;
    }

    a {
      padding-top: 0;
    }
  }
}
