@use '@styles/theme.module' as *;

.wrapper {
  padding: spacing(7) 0;

  @include media($until: $sm) {
    align-items: flex-start;
  }
}

.inner_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  color: $grayDark;
  margin-bottom: spacing(2);
}

.link {
  display: block;
  margin: 0;
  margin-top: spacing(2);
  text-decoration: underline;
  width: fit-content;

  &:focus {
    outline: 1px dashed $grayDarker;
    outline-offset: spacing(1);
  }
}

.cta_title {
  display: inline-block;
  @include media($until: $md) {
    max-width: 70%;
  }
}

.description {
  @include typographyBody;

  span {
    text-transform: capitalize;
    @include typographyBodyBold;
  }
}

.phone_link {
  color: $primary;
  border-bottom: 1px solid $primary;
  @include typographyBodyBold;

  &:focus {
    outline: 1px dashed $grayDarker;
    outline-offset: spacing(1);
  }
}
