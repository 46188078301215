@use 'src/styles/theme.module' as *;

.tile {
  &__tag_list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    &.v2 {
      display: block;
      padding-top: 8px;
      top: 0;
    }
  }

  &_divider {
    margin: calc(#{spacing(1) / 2});
  }

  &_empty_space {
    width: 100%;
    height: 16px;
    background-color: transparent;
  }
}
