@use '@styles/theme.module.scss' as *;

.tab_panel_wrapper {
  height: 100%;

  &_content {
    margin-top: spacing(5);
    height: 100%;
    overflow: auto;
    @include typographyBody;

    @include scroll;
  }
}
