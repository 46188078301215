@use '@styles/theme.module' as *;

.table {
  &_header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-bottom: 3px solid $black;
    max-height: 90px;
    padding-bottom: spacing(6);
  }
}

.single_header {
  padding: 0;
  border-bottom: 10px solid $black;
}

.title {
  margin: 0;
  @include typographyH3Bold;
}

.thick_border {
  border-bottom: 1px solid $black;
}

.servings {
  display: flex;
  justify-content: space-between;

  &_size {
    padding-top: spacing(1);
    @include typographySmallBodyBold;
  }
}

.servings_number {
  margin-top: spacing(1);
  @include typographySmallBody;
}

.top_header_title {
  @include typographyH3Bold;
}

.top_header_item {
  position: relative;
  text-align: right;
  border-left: 1px solid $black;
  @include typographyH3Bold;
}

.top_column_name {
  text-align: right;
  border-left: 1px solid $black;
  @include typographyBodyBold;
}

.category {
  @include typographySmallBody;
}

.selected {
  @include typographySmallBodyBold;
}

.nested {
  margin-left: spacing(3);
  margin-right: -#{spacing(3)};
}

.deepNested {
  margin-left: spacing(5);
  margin-right: -#{spacing(5)};
}

.table_item {
  position: relative;
  display: flex;
  flex-direction: column;
  border-left: 1px solid $black;
  @include typographyLabelBold;
}

.item_content {
  display: flex;
  justify-content: space-between;
}

.percentage {
  @include typographyLabelBold;
}

.disclaimer {
  margin-top: spacing(4);
  padding-top: spacing(1);
  border-top: 4px solid $black;
  @include typographyLabelBold;
}

.sm_divider {
  height: 1px;
  background: $black;
}

.mid_divider {
  width: 100%;
  height: 3px;
  background: $black;
}

.high_divider {
  width: 100%;
  height: 9px;
  background: $black;
}

.divider_wrapper {
  width: 100%;
}

.padding_right_05 {
  padding-right: spacing(2);
}

.padding_left_1 {
  padding-left: spacing(4);
}
