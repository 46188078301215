@use '@styles/theme.module.scss' as *;

$bottom-panel-height-from-md: 100px;

.content_wrapper {
  padding: 0;
  overflow: auto;
  height: 100%;
  padding-top: spacing(7);
}

.bottom_panel_wrapper {
  border-top: 1px solid $grayLighter;
  background-color: $white;
  padding: 0 spacing(4);

  &:not(.drawer_opened) {
    position: fixed;
    bottom: 34px;
    right: 0;
    left: 0;
    z-index: 11;
  }

  @include media($from: $sm) {
    padding: 0 spacing(14);
  }

  @include media($from: $largeTablet) {
    padding: 0 spacing(2);

    &:not(.drawer_opened) {
      border: none;
      background-color: $gray1;
    }

    width: 61.5%;
  }

  @include media($from: $xl) {
    padding: 0 spacing(13) 0 spacing(12);
  }

  &.drawer_opened {
    width: 100%;
    position: relative;
    text-align: center;
    height: 137px;
    @include media($from: $sm) {
      padding: spacing(0) spacing(2);
    }
  }
}

.bottom_panel_inner {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  border: none;
  padding-top: spacing(4);
  padding-bottom: spacing(4);
  position: relative;

  @include media($from: $sm) {
    margin: 0;
    padding: spacing(4) spacing(5);
  }

  @include media($from: $largeTablet) {
    width: 100%;
    margin: auto;
    display: flex;
    height: $bottom-panel-height-from-md;
    box-shadow: 0 -1px 0 rgba(0 0 0 / 0.02);
  }
}

.cta_button {
  margin-top: 2.4rem;
}
