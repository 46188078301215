@use '@styles/theme.module' as *;

.subtotal {
  display: flex;
  align-items: baseline;
}

.subtotal_label {
  color: $grayDark;
}

.subtotal_clarification {
  margin-left: spacing(1);
}
