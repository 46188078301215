@use '@styles/theme.module' as *;

@mixin spacingToRight {
  margin-bottom: 0;
  margin-right: spacing(2);

  &:last-child {
    margin-right: 0;
  }
}

.info {
  margin-bottom: spacing(5);
  width: 100%;

  @include media($from: $sm) {
    margin-bottom: 0;
  }
}

.buttons_wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;

  .button {
    flex-grow: 1;
    padding: spacing(2) spacing(5);
    white-space: nowrap;
    @include typographySmallBody;

    @include media($from: $lg) {
      @include typographyBodyMedium;
    }

    @include spacingToRight;
  }

  @include media($from: $sm) {
    flex-flow: row nowrap;

    .button {
      @include spacingToRight;
    }
  }
}

.status {
  text-transform: capitalize;
  @include typographyBodyBold;
}

.details_item {
  width: 50%;
}

.order_date {
  color: $grayDark;
}
