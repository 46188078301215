@use '@styles/theme.module' as *;
@use 'sass:map';

.tile_wrapper {
  @include media($from: $sm) {
    margin-top: spacing(0);
  }
}

.tile {
  padding-bottom: spacing(5);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &.with_hover {
    &:hover {
      background-color: $gray2;
    }

    @include media($from: $sm) {
      padding: spacing(3) spacing(5);

      &:not(.drawer_opened) {
        flex-direction: row;
      }
    }

    @include media($from: $md) {
      border-radius: spacing(3);
    }
  }
}

.basic_info_wrapper {
  display: flex;
  word-break: break-word;
  @include typographyBodyMedium;

  .image_wrapper {
    display: flex;
    margin-right: spacing(6);
    border-radius: 4px;

    .image {
      border-radius: map.get($radius, 'md');
      object-fit: cover;
    }
  }

  .top_text {
    margin: 0;
  }

  .bottom_text {
    margin: 0;
    color: $grayDark;
    @include typographySmallBody;
  }
}
