@use '@styles/theme.module.scss' as *;

.tile_quantity {
  line-height: 0;
  flex: 1 1 content;
}

.tile_quantity_item {
  color: $grayDark;
  line-height: 1;
  border-right: 1px solid $grayLighter;
  padding-right: spacing(1);
  margin-right: spacing(1);
  @include typographySmallBody;

  &:last-child {
    border-right: 0;
    margin: 0;
    padding-right: 0;
  }
}
