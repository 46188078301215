@use '@styles/theme.module' as *;

.categories-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.categories-container {
  display: grid;
  grid-auto-flow: column;
}

.element_link {
  color: $black;
  text-decoration: none;
  margin-top: spacing(3);

  &:hover {
    color: $primary;
  }

  &:focus {
    color: $primary;
    outline: 1px dashed $grayDarker;
  }
}

.divider {
  border-right: 1px solid $gray4;
}
