@use '@styles/theme.module.scss' as *;

.toggle {
  position: fixed;
  bottom: 0;
  right: 0;
  background: transparent;
  color: $primaryDark;
  border: none;
  padding: none;
  cursor: pointer;
  z-index: 10000;
}

.popover {
  --button-color: #{$primaryDark};

  top: auto;
  bottom: auto;
  right: auto;
  left: auto;
  border: 3px solid $primaryDark;
  background-color: $primaryLightest;
  padding: 0.5em 1em;

  a {
    color: $primaryDark;
    text-decoration: underline;
  }

  button {
    background-color: $white;
    border: 3px solid var(--button-color);
    color: var(--button-color);
    cursor: pointer;
  }

  &.top-left {
    top: 1em;
    left: 1em;
  }

  &.top-right {
    top: 1em;
    right: 1em;
  }

  &.bottom-left {
    bottom: 1em;
    left: 1em;
  }

  &.bottom-right {
    bottom: 1em;
    right: 1em;
  }

  .posbutton {
    position: absolute;
    background-color: transparent;
    border: 6px solid transparent;
    padding: 0;
    height: 0;
    width: 0;

    span {
      display: none;
    }

    &.posbutton_tl {
      top: 2px;
      left: 2px;
      border-top-color: var(--button-color);
      border-left-color: var(--button-color);
    }

    &.posbutton_tr {
      top: 2px;
      right: 2px;
      border-top-color: var(--button-color);
      border-right-color: var(--button-color);
    }

    &.posbutton_bl {
      bottom: 2px;
      left: 2px;
      border-bottom-color: var(--button-color);
      border-left-color: var(--button-color);
    }

    &.posbutton_br {
      bottom: 2px;
      right: 2px;
      border-bottom-color: var(--button-color);
      border-right-color: var(--button-color);
    }

    &:hover {
      --button-color: #{$redDark};
    }
  }
}

.toolbar {
  display: flex;
  justify-content: space-between;
  gap: 1em;

  > h2 {
    margin: 0;
  }

  > button {
    background-color: transparent;
    color: $primaryDark;
    border: none;
    cursor: pointer;
  }
}

.content {
  max-width: 50vw;
  max-height: 50vh;
  overflow: auto;

  @include media($until: $lg) {
    max-width: calc(90vw - 2em);
  }

  details > summary {
    cursor: pointer;
  }

  details[open] > summary {
    color: $primaryDark;

    + * {
      display: block;
      margin-left: 1em;
    }
  }
}

.marks {
  overflow: auto;

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .mark_refresh,
  .mark_details {
    margin: 0 0.5em;
    padding: 0;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
}

.features,
.buildinfo,
.runtimeconfig {
  overflow: auto;

  > div {
    white-space: nowrap;

    > dt {
      font-weight: bold;
    }

    > dd {
      margin: 0 0 0 1em;
    }
  }
}

.feature_reset {
  --button-color: #{$redDark};
}

.changed_feature {
  color: $redDark;
}

.feature_select {
  width: 100%;
}

.feature_options {
  width: 100%;
  max-width: 100%;
  field-sizing: content; /* stylelint-disable-line property-no-unknown */
  border: 3px solid $primaryDark;

  &.invalid_options {
    border-color: $redDark;
  }
}

.tracker_blocked {
  color: $redDark;
}
