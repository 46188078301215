@use '@styles/layouts' as *;
@use '@styles/theme.module' as *;

.wrapper {
  .content {
    width: calc(100% - 300px);
    padding-left: spacing(2);
    display: flex;
    flex-flow: column wrap;
    flex-grow: 1;

    @include media($from: $lgbp) {
      padding-left: spacing(5);
    }

    > div {
      max-width: 100%;
    }
  }

  &.is_small_screen {
    .content {
      padding-left: 0;
    }
  }
}

.container {
  @include container;

  display: flex;
  flex-direction: row;
}
