@use '@styles/theme.module' as *;

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  color: $grayDark;
  margin: spacing(7) 0 spacing(2);
}

.payment_info_container {
  display: grid;
  gap: spacing(2);
  align-items: center;
  margin-bottom: spacing(6);
  grid-auto-flow: column;
}

.payment_icon {
  height: 18px;
  width: auto;
}

.link {
  color: $grayDark;
  margin-bottom: spacing(6);

  @include media($until: $sm) {
    max-width: 8rem;
  }

  @include media($until: $md) {
    text-decoration: underline;
  }
}

.link span {
  @include media($until: $md) {
    border-bottom: 0;
  }
}
