@use '@styles/theme.module.scss' as *;

.wrapper {
  position: relative;
  height: 100%;
  margin-right: spacing(7);

  @include media($until: $sm) {
    display: none;
  }
}

.standing_orders {
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: inherit;
  border: none;
  color: $primary;
  cursor: pointer;
  white-space: nowrap;

  .icon_container {
    margin-right: spacing(1);

    & > svg {
      width: 32px;
      height: 32px;
      flex: 1;
    }
  }

  @include typographySmallBodyBold;

  @include media($from: $xl) {
    @include typographyBody;
  }

  @include focusVisible {
    outline: auto;
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }
}
