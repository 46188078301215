@use '@styles/theme.module' as *;

.container {
  overflow: visible;
  margin: 0;
  padding: spacing(9);
  border-radius: spacing(4);

  @include media($until: $sm) {
    margin-top: auto;
    padding: spacing(4);
    border-radius: spacing(2) spacing(2) 0 0;
  }
}

.title {
  padding: 0;
  padding-bottom: spacing(7);

  @include media($until: $sm) {
    padding-top: spacing(4);
  }

  > h3 {
    @include typographyH3;
  }
}

.close_button {
  position: absolute;

  @include media($until: $sm) {
    right: 12px;
    top: -46px;
  }

  @include media($from: $sm) {
    right: 27px;
    top: 27px;
  }

  > svg {
    @include media($until: $sm) {
      color: $white;
      height: 22px;
    }

    @include media($from: $sm) {
      color: black;
      height: 17px;
    }
  }
}

.content {
  @include media($until: $sm) {
    padding: 0 0 spacing(10);
  }

  padding: 0 0 spacing(7);

  > span {
    color: $grayDark;
  }
}

.buttons {
  display: flex;
  flex-direction: column-reverse;

  @include media($from: $sm) {
    flex-direction: row;
  }

  .confirm_button {
    @include media($until: $sm) {
      margin-bottom: spacing(5);
    }
  }

  .remove_button {
    @include media($from: $sm) {
      margin-right: spacing(5);
    }
  }
}
