@use '@styles/theme.module' as *;

.left_nav {
  width: 300px;
  height: 100%;
  padding: spacing(8) spacing(2) spacing(8) spacing(0);

  .list_item {
    margin-top: spacing(4);
  }
}
