@use '@styles/theme.module' as *;

.wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: spacing(4);
}

.product {
  display: flex;
  align-items: center;

  & > :first-child {
    margin-right: spacing(2);
    width: 45px;
    height: 40px;

    @include media($from: $md) {
      width: 65px;
      height: 55px;
    }
  }
}

.details {
  & > :first-child {
    width: 160px;
    height: 24px;
  }

  & > :last-child {
    width: 100px;
    height: 24px;
  }

  @include media($from: $md) {
    & > :first-child {
      width: 260px;
      height: 32px;
    }

    & > :last-child {
      width: 200px;
      height: 32px;
    }
  }
}

.price {
  & > :first-child {
    width: 100px;
    height: 24px;
  }

  & > :last-child {
    width: 60px;
    height: 24px;
  }

  @include media($from: $md) {
    & > :first-child {
      width: 200px;
      height: 32px;
    }

    & > :last-child {
      width: 140px;
      height: 32px;
    }
  }
}
