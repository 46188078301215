@use '@styles/theme.module.scss' as *;

.container {
  margin-bottom: spacing(5);
  @include media($from: $largeTablet) {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-gap: spacing(5);
  }
}

.content {
  grid-column: 2 / span 10;
  @include media($from: $lgbp) {
    grid-column: 2 / span 6;
  }
}

.invites {
  margin-top: spacing(5);

  ul {
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  li {
    list-style-type: none;
  }
}

.invite {
  border: 1px solid $grayLighter;
  border-radius: spacing(2);
  padding: 20px;
}

.email {
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
}

.date {
  color: $grayDarker;
}

.loader_wrapper {
  display: flex;
  justify-content: center;
}

.footer_wrapper {
  @include media($from: $largeTablet) {
    width: calc(100% + $account-page-side-padding);
  }
}
