@use '@styles/theme.module.scss' as *;

.link {
  &:focus-visible {
    display: block;
    outline: 1px dashed $grayDarker;
  }
}

.tile_name {
  color: $black;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin: 0;
  @include typographyBody;

  &.hover {
    text-decoration: underline;
  }

  @include media($from: $md) {
    -webkit-line-clamp: 4;
  }

  &_text {
    @include typographyBigBody;
  }

  &_brand {
    @include typographyBigBodyBold;

    &::after {
      content: ' ';
    }
  }

  &.v2 {
    margin-top: spacing(2);
    margin-bottom: spacing(1);
    display: box;
    -webkit-line-clamp: 2;

    @include media($from: $sm) {
      -webkit-line-clamp: 3;
    }

    & .tile_name_text,
    & .tile_name_brand {
      color: $gray12;
    }
  }
}

.large_tile {
  &_name {
    @include typographyBody;
  }

  &_brand {
    @include typographyBodyBold;
  }
}

.unavailable_text {
  @include typographyBodyBold;

  color: $red;
}
