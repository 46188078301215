@use '@styles/theme.module' as *;

.collapse_button {
  width: 28px;
  height: 28px;
  max-width: 28px;
  max-height: 28px;
  border: 2px solid $primary;
  background-color: transparent;
  transition: color 0.2s ease-in-out, border 0.2s ease-in-out;

  &.initial {
    background-color: $white;
  }

  .icon {
    color: $primary;
    width: 14px;
    height: 14px;
    transition: 0.2s ease-in-out;
  }

  &:hover {
    background-color: $primary;

    .icon {
      color: $white;
    }
  }
}

.quantity {
  background-color: $primary;
  color: $white;
  max-width: 26px;
  min-width: 26px;
  max-height: 26px;
  min-height: 26px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  cursor: pointer;
  @include typographyBody;

  &.v2,
  &.v2:hover {
    width: 32px;
    height: 32px;
    max-width: 32px;
    max-height: 32px;
    background-color: $primary;
    transition: none;

    &:hover {
      background-color: $primaryDark;
      border-color: $primaryDark;
      box-shadow: 0 4px 4px 0 $black06;
    }

    .icon {
      color: $white;
      width: initial;
      height: initial;

      path {
        fill: $white;
      }
    }
  }
}
