@use '@styles/theme.module' as *;

$transition-duration: 0.5s;

.header {
  width: 100%;
}

.is_static_header {
  height: auto;
}

.content {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  z-index: $appBar;
  background-color: $white;
}

.without_shop_by > .content {
  border-bottom: 1px solid $grayLighter;
}

.is_sticky {
  position: sticky;
  top: 0;
  z-index: 1003;

  @media (hover: none) and (max-height: 420px) {
    position: static;
  }
}

.wrapper_shop_by {
  position: absolute;
  transition-property: transform;
  transition-duration: $transition-duration;
  transform: translateY(calc(#{spacing(4)} + 100%));
  right: spacing(4);
  bottom: 0;
  overflow: hidden;

  @include media($from: $sm) {
    transform: translateY(calc(#{spacing(5)} + 100%));
    right: spacing(5);
  }

  @include media($from: $lg) {
    right: spacing(10);
    transform: translateY(calc(#{spacing(7)} + 100%));
  }

  @include media($from: $xl) {
    right: spacing(13);
  }
}

.is_scrolling_to_bottom {
  @include media($from: $lg) {
    transform: translateY(calc(#{spacing(6)} + 100%));
  }
}

.is_open {
  position: static;
  padding: spacing(4) 0 spacing(5) spacing(5);
  animation-name: show-up;
  animation-duration: 1s;
  transform: translateY(0);
  display: flex;
  justify-content: flex-start;
  border-top: 1px solid $grayLighter;

  @include media($from: $sm) {
    justify-content: flex-end;
    margin-bottom: 0;
    padding: spacing(5) 0 spacing(5) spacing(5);
  }

  @include media($from: $md) {
    margin-bottom: 0;
    padding: spacing(5) spacing(6) spacing(5) spacing(5);
  }

  @include media($from: $lg) {
    padding: spacing(7) spacing(11) spacing(5) spacing(5);
  }
}

@keyframes show-up {
  from {
    max-height: 0;
  }

  to {
    max-height: 100px;
  }
}
