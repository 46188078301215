@use '@styles/theme.module.scss' as *;
@use 'sass:map';

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  position: absolute;
  top: calc(100% + spacing(3));
  right: 0;
  z-index: 99;
  background-color: $white;
  box-shadow: 0 0 16px rgb(0 0 0 / 0.04), 0 8px 20px rgb(0 0 0 / 0.1);
  border-radius: map.get($radius, 'xlg');
  padding: spacing(9) spacing(11);
  width: 368px;
}
