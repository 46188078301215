@use '@styles/theme.module.scss' as *;

.name {
  white-space: nowrap;
  text-align: end;
  margin: 0;
  color: $grayDark;
  @include typographySmallBody;

  @include media($from: $xl) {
    @include typographyBody;
  }

  svg {
    margin-left: spacing(1);
  }
}
