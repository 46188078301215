@use '@styles/theme.module.scss' as *;

.dialogs {
  :global(.MuiPaper-root) {
    background-color: $primaryDark;
  }

  @include media($from: $md) {
    width: 420px;
  }
}

.account_navigation_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}

.standing_order_icon {
  & > svg {
    & > g {
      & > rect {
        fill: $primaryDark !important;
      }
    }
  }
}

.account_list_item {
  padding: spacing(4) 0 spacing(4) spacing(4);

  @include media($from: $sm) {
    padding: spacing(5) 0 spacing(5) spacing(6);
  }

  .list_image {
    @include media($from: $sm) {
      min-width: 75px;
    }

    .icon {
      width: 32px;
      height: 32px;
    }

    svg path {
      color: $white;
    }

    svg rect {
      color: $white;
    }
  }
}

.account_item:focus-within {
  background-color: rgb(0 0 0 / 0.1);
}

.list_text {
  color: $white;
  margin-right: spacing(2);
  background-color: transparent;
  border: 0;
  padding: 0;
  @include typographyH5Medium;
  @include hover {
    background-color: transparent;
    color: $white;
  }
}

.account_navigation_inner_wrapper {
  overflow: auto;

  .top_menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: spacing(8);
    padding: spacing(5) spacing(4) spacing(0);

    @include media($from: $sm) {
      padding: spacing(6) spacing(6) spacing(0);
      margin-bottom: spacing(7);
    }

    .close_button {
      width: 25px;
      height: 25px;
      padding: 0;

      svg path {
        stroke: $white;
      }
    }

    .top_menu_inner_wrapper {
      display: flex;
      align-items: center;

      .back_button {
        display: flex;
        justify-content: flex-start;
        width: 32px;
        height: 32px;
        padding: 0;
        margin-right: spacing(9);

        svg path {
          stroke: $white;
        }
      }
    }
  }
}

.text {
  color: $white;
  text-decoration: none;
  @include typographyH5Medium;
}

.headline {
  color: $white;
  margin-bottom: spacing(7);
  padding: spacing(0) spacing(4);

  @include media($from: $sm) {
    margin-bottom: spacing(6);
    padding: 0 spacing(4) 0 spacing(13);
  }
}

.divider {
  margin: 0 spacing(4);
  background-color: $white;
}

.secondary_divider {
  margin: 0 spacing(4);
  background-color: $white;
  opacity: 0.2;

  @include media($from: $sm) {
    width: 350px;
    margin-left: spacing(13);
  }

  @include media($from: $md) {
    width: auto;
    margin: 0 spacing(4);
  }
}
