@use '@styles/theme.module' as *;

$transition-duration: 0.5s;

.wrapper_bottom_line {
  transition-property: max-height, padding, opacity;
  transition-duration: $transition-duration;
  max-height: 200px;
  padding: 2px 2px 0;
  display: none;

  @include media($from: $lg) {
    display: block;
  }

  @include media($from: $lg, $until: $lgbp) {
    padding-left: 60px;
    padding-right: 60px;
  }
}

.hidden {
  max-height: 0;
  padding: 0;
  opacity: 0;
  overflow: hidden;
}

.refer_text {
  background-color: $primary;
  padding: 20px 60px;
  color: $white;
  text-align: center;
}
