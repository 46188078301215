@use '@styles/theme.module' as *;

.container {
  display: flex;
  align-items: center;
  line-height: 1.3;
}

.icon {
  margin-right: spacing(2);
}

.error_button {
  vertical-align: baseline;
}
