@use '@styles/theme.module' as *;

.heading {
  margin-bottom: spacing(5);
}

.text {
  display: block;
  margin-bottom: spacing(7);
  color: $grayDark;
}

.button {
  margin-top: spacing(3);
}

.modal_content {
  width: 100%;
  padding-right: 0;
  @include media($from: $sm) {
    max-width: 350px;
  }
}
