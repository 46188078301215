@use '@styles/theme.module.scss' as *;
@use 'sass:map';

$padding-size: spacing(3);

.wrapper {
  width: 100%;
  position: relative;
}

.container {
  color: $black;
  background-color: $white;
  border: 1px solid $grayLight;
  border-radius: map.get($radius, 'md');
  padding: $padding-size;
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  cursor: pointer;
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: spacing(2);
}

.icon_wrapper {
  margin-top: spacing(3);
}

.check_icon {
  color: $black;
  opacity: 0;
  transition: ease-in-out 0.2s;
  pointer-events: none;
}

.radio_input {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;

  &:checked ~ .container {
    border-color: $black;
    color: $black;

    .date {
      @include typographyBodyBold;
    }

    .check_icon {
      opacity: 1;
    }
  }

  &:focus-within ~ .container {
    outline: 1px dashed $grayDarker;
    outline-offset: spacing(1);
  }

  &:disabled ~ .container {
    cursor: not-allowed;
  }
}
