@use '@styles/theme.module' as *;

.edit_modal {
  max-width: 100%;

  @include media($from: $sm) {
    max-width: 634px;
  }
}

.form {
  margin-top: spacing(2);
}

.payment_icon {
  visibility: visible;
  width: 100%;
  height: 24px;
}

.title {
  margin-bottom: spacing(2);
  @include typographyH5Medium;
}

.divider {
  margin: spacing(4) 0;
}

.button {
  margin-top: spacing(4);
}

.with_margin_right {
  margin-right: spacing(2);
}
