@use '@styles/theme.module.scss' as *;

.additional_info {
  @include media($from: $sm) {
    margin-top: spacing(4);
    margin-left: spacing(4);
  }
}

.title {
  color: $black;
  @include typographyH3;
}
