@use '@styles/theme.module.scss' as *;
@use 'sass:map';

.iconButton {
  border-radius: map.get($radius, 'full');
  padding: 5px;
  transition: color 0.2s ease-in-out, border 0.2s ease-in-out;

  // for increase specificity
  &:global(.MuiButtonBase-root) {
    background-color: $white;
    color: rgba($black, 1);
  }

  svg {
    transition: 0.2s ease-in-out;
  }

  &.default {
    border: 1px solid $grayLight;
  }

  &.outlined {
    border: 1px solid $primary;
  }

  &.small {
    width: 28px;
    height: 28px;
  }

  &.medium {
    width: 36px;
    height: 36px;
  }

  &.large {
    width: 48px;
    height: 48px;
  }

  @include hover {
    border-color: $primary;

    svg {
      color: $primary;
    }
  }

  &:focus-visible {
    outline: 1px dashed $grayDarker;
    outline-offset: spacing(1);
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }
}
