@use '@styles/theme.module.scss' as *;

.wrapper {
  position: relative;
}

.title {
  margin-bottom: spacing(7);
}

.amount {
  margin-bottom: spacing(4);
  @include typographyH1Bold;
}

.info_text {
  color: $grayDark;
  margin-bottom: spacing(5);

  @include media($from: $sm) {
    margin-bottom: spacing(7);
  }
}

.button {
  width: 100%;
  margin-right: spacing(0);
  margin-bottom: spacing(5);

  @include media($from: $sm) {
    width: 200px;
    margin-right: spacing(4);
    margin-bottom: spacing(0);
  }
}

.container {
  @include media($from: $largeTablet) {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-gap: spacing(5);
  }
}

.content {
  grid-column: 2 / span 10;
  @include media($from: $lgbp) {
    grid-column: 2 / span 6;
  }
}

.refer_friend {
  color: $primary;
  border: none;
  background-color: $white;
  font-weight: 500;
}

.refer_friend:hover {
  text-decoration: underline;
  cursor: pointer;
}
