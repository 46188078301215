@use '@styles/theme.module' as *;

.container {
  width: 100%;
}

.selected_slot {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include typographyBodyBold;

  p {
    margin: 0;
  }

  button {
    text-transform: capitalize;
    @include typographyBodyBold;
  }
}

.divider {
  margin: spacing(6) 0;

  @include media($until: $md) {
    margin: spacing(4) 0;
  }
}
