// TODO: Need update that file by the linter rules
/* stylelint-disable */
@use '@styles/theme.module.scss' as *;
@use 'sass:map';

$input-height: 48px;
$input-height-xl: 52px;
$padding-left: spacing(5);
$input-border-size: 1px;
$label-scale-size: get-scale(get-font-size($label, $xs), get-font-size($body, $xs));
$label-scale-size-xl: get-scale(get-font-size($label, $xl), get-font-size($body, $xl));
$label-vertical-translate: calc(
  -#{get-font-size($label, $xs)} / #{$label-scale-size} / 2 + #{$input-border-size}
);
$label-vertical-translate-xl: calc(
  -#{get-font-size($label, $xl)} / #{$label-scale-size-xl} / 2 + #{$input-border-size}
);
$shrink-label-height: calc(
  #{get-font-size($label, $xs)} / #{$label-scale-size} / #{$label-scale-size}
);
$shrink-label-height-xl: calc(
  #{get-font-size($label, $xl)} / #{$label-scale-size-xl} / #{$label-scale-size-xl}
);

@mixin inputDefaultStyles {
  &:global(.MuiFormControl-root) {
    & > :global(.MuiInputLabel-root) {
      transform: translate(spacing(6), -50%) scale(1);
      color: #000;
      top: 50%;

      @include labelTransition;
    }

    & > :global(.MuiInputLabel-root) {
      transform: translate($padding-left, -50%) scale(1);
    }

    & > :global(.MuiInputLabel-shrink) {
      color: $black !important;
      transform: translate($padding-left, $label-vertical-translate) scale($label-scale-size) !important;
      top: 0;

      @include media($from: $xl) {
        transform: translate($padding-left, $label-vertical-translate-xl)
          scale($label-scale-size-xl) !important;
      }
    }

    & :global(.MuiInputBase-root) {
      width: 100%;
    }

    & :global(.css-1z7n62) {
      margin: 10px;
    }

    &:hover .icon_false {
      visibility: visible;
    }

    textarea {
      @include typographyBody;
    }

    input {
      padding: 13px spacing(5);
      @include typographyBody;

      @include media($from: $md) {
        padding: 13px spacing(6);
      }
    }

    & fieldset legend span {
      display: none;
    }

    & fieldset :hover {
      @include media($from: $xs) {
        border: 1px solid $black;
      }

      @include media($from: $md) {
        border: 1px solid $grayDarker;
      }
    }

    label {
      @include typographyBody;
      transform: translate(spacing(6), 14px) scale(1);
      background-color: $white;
      color: $grayDark;
    }

    label.shrink,
    & :global(.MuiInputLabel-shrink) {
      height: $shrink-label-height;
      display: flex;
      align-items: center;
      transform: translate($padding-left, $label-vertical-translate) scale($label-scale-size);
      padding: spacing(0) spacing(1);
      text-transform: uppercase;
      @include typographyBodyBold;

      font-size: $label-scale-size;

      @include media($from: $xl) {
        height: $shrink-label-height-xl;
        transform: translate($padding-left, $label-vertical-translate-xl)
          scale($label-scale-size-xl);
      }
    }

    & :global(.MuiOutlinedInput-notchedOutline) {
      border-color: $grayLight;
    }

    & :global(.MuiOutlinedInput-root) {
      border-radius: 100px;
      color: $black;
      min-height: $input-height;

      @include media($from: $xl) {
        min-height: $input-height-xl;
      }

      &:global(.Mui-focused) > :global(.MuiOutlinedInput-notchedOutline) {
        border: 1px solid $grayDark;
      }

      &:global(.Mui-disabled) {
        background-color: $gray2;
      }

      &:global(.Mui-disabled) > :global(.MuiOutlinedInput-notchedOutline) {
        pointer-events: none;
        color: $grayDark;

        @include media($from: $xs) {
          border: 1px solid $grayDark;
        }

        @include media($from: $md) {
          border: 1px solid $grayLight;
        }
      }

      &:hover fieldset {
        @include media($from: $xs) {
          border: 1px solid $black;
        }

        @include media($from: $md) {
          border: 1px solid $grayDarker;
        }
      }
    }

    & :global(.MuiInputBase-multiline) {
      letter-spacing: -0.01em;
      border-radius: spacing(4);
      padding: spacing(3) spacing(6);

      &:global(.MuiOutlinedInput-input) {
        padding-top: spacing(3);
        padding-bottom: spacing(3);
      }
    }
  }
}

.input_wrapper {
  position: relative;

  & > :global(.Mui-focused) {
    color: $grayDark;
  }

  &.error_text {
    & > :global(.MuiInputLabel-shrink) {
      color: $errorFG;
      @include typographyBodyBold;
    }

    & fieldset {
      border-color: $errorFG !important;
    }

    & :global(.MuiInputLabel-root.MuiInputLabel-shrink) {
      color: $errorFG;
    }
  }

  & > :global(.MuiInputLabel-root),
  & > :global(.MuiInputLabel-root.MuiInputLabel-shrink) {
    color: $grayDark;
  }
}

.password_icon {
  cursor: pointer;
  display: flex;
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  background: transparent;

  &:focus-visible {
    outline: 1px dashed $grayDarker;
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }
}

.icon_false {
  &:global(.MuiInputAdornment-root.MuiInputAdornment-positionEnd) {
    visibility: hidden;
  }
}

.default_input {
  @include inputDefaultStyles;
  display: flex;
  width: 100%;
}

.search_input {
  input {
    width: 100%;

    &::-webkit-search-cancel-button {
      display: none;
    }
  }
}

.password_input {
  &:global(.MuiFormControl-root) {
    input {
      padding-right: 45px;

      &::-ms-reveal {
        display: none;
      }
    }
  }
}

.formHelperText {
  &:global(.MuiFormHelperText-root) {
    display: flex;
    align-items: center;
    color: $black;

    &[data-qa='field_error'] {
      @include typographySmallBodyMedium;
    }
  }

  &:global(.MuiFormHelperText-root.Mui-error) {
    color: $errorFG;
  }
}
