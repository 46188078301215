@use '@styles/theme.module' as *;

.no_results_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;

  @include media($from: 0) {
    padding: spacing(4) 0;
    min-height: calc(100vh - 128px);
  }

  @include media($from: $sm) {
    min-height: calc(100vh - 144px);
  }

  @include media($from: $md) {
    min-height: calc(100vh - 109px);
  }

  @include media($from: $lg) {
    min-height: calc(100vh - 200px);
  }

  @include media($from: $xl) {
    min-height: calc(100vh - 205px);
  }
}

.no_results {
  display: flex;
  justify-content: center;
  flex-direction: column;

  @include media($from: $sm) {
    max-width: 354px;
  }

  @include media($from: $md) {
    max-width: 472px;
  }

  @include media($from: $lg) {
    max-width: 654px;
  }

  @include media($from: $xl) {
    max-width: 854px;
  }
}

.with_recommended_carousel {
  min-height: calc(70vh - 205px);
}

.no_results_title {
  text-align: center;

  @include media($from: 0) {
    margin: 0 0 spacing(3) 0 !important;
  }

  @include media($from: $sm) {
    margin: 0 0 spacing(5) 0 !important;
  }
}

.no_results_description {
  text-align: center;
  margin: 0 0 spacing(8) 0;
  color: $grayDark;
  @include typographyBody;

  @include media($from: $xl) {
    @include typographySmallBody;
  }
}

.no_results_button_wrapper {
  display: flex;
  justify-content: center;
}
