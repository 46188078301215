@use '@styles/theme.module' as *;

.wrapper {
  width: 100%;
  height: 70px;

  & button {
    @include typographySmallBodyMedium;

    @include media($from: $lg) {
      @include typographyBodyMedium;
    }
  }

  @include media($from: $sm) {
    width: auto;
  }

  & :global(.MuiInputLabel-root) {
    color: $grayDark;
  }

  & :global(.MuiFormHelperText-root) {
    color: $errorFG;
  }

  input {
    @include media($from: $lg) {
      min-width: 170px;
    }
  }
}
