@use '@styles/theme.module' as *;

.text_wrapper {
  margin-bottom: spacing(7);
}

.button_wrapper {
  width: 100%;
}

.title {
  margin-bottom: spacing(3);
}

.social_name {
  text-transform: capitalize;
}

.button {
  &:not(:last-child) {
    margin-bottom: spacing(4);
  }
}
