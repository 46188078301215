@use '@styles/theme.module' as *;

.container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  overflow: auto;
  gap: spacing(2);
}

.tile {
  height: 58px;
  width: 101px;
  flex-shrink: 0;
  border-radius: spacing(1);
}
