@use '@styles/theme.module.scss' as *;

.box {
  position: relative;
  border: 1px solid $grayLighter;
  border-radius: 2px;
  display: flex;
  align-content: center;
  justify-content: center;

  &.checked {
    border-color: $black;
  }

  &:hover {
    border-color: $primary;
  }
}
