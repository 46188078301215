@use '@styles/theme.module' as *;

.title {
  margin-bottom: spacing(6);
}

.text_container {
  display: flex;
  flex-direction: column;
  gap: spacing(4);
}

.alert {
  max-width: 550px;

  :global(.MuiAlert-icon) {
    align-self: flex-start;
    transform: translateY(spacing(1));
  }
}

.bold {
  @include typographyBodyBold;

  display: contents;
}
