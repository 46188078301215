@use '@styles/theme.module.scss' as *;

.message {
  color: $errorFG;
  text-align: center;
  margin-bottom: spacing(3);
}

.wrapper {
  position: relative;
  align-self: center;
  width: 100%;
}

.icon {
  margin-right: spacing(1);
}
