@use '@styles/theme.module' as *;

.mobile_promo {
  display: flex;
  align-items: center;

  &_button {
    @include typographyBody;
  }
}

.promo_container {
  width: 360px;

  @include media($from: $md) {
    max-width: 260px;
  }

  @include media($from: $xl) {
    width: 300px;
  }
}

.condition {
  color: $errorFG;

  &::before {
    background-color: $errorFG;
  }
}

.warning_icon {
  color: $errorFG;
  margin-left: spacing(1);
}
