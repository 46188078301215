@use '@styles/theme.module.scss' as *;

.wrapper {
  position: relative;
  height: 100%;
}

.title {
  margin: 0;
  white-space: nowrap;
  color: $grayDark;
  @include typographySmallBodyBold;

  @include media($from: $xl) {
    @include typographyBodyBold;
  }
}

.description {
  white-space: nowrap;
  margin: 0;
  color: $grayDark;
  @include typographySmallBody;

  @include media($from: $xl) {
    @include typographyBody;
  }

  & > svg {
    margin-right: spacing(1);
  }

  & > svg:last-child {
    margin-left: spacing(1);
  }
}

.fdSwitch {
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: inherit;
  border: none;
  cursor: pointer;

  &:focus-visible {
    outline: 1px dashed $grayDarker;
    outline-offset: spacing(1);
  }

  &:hover {
    .title,
    .description {
      color: $black;
    }
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }
}
