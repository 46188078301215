@use '@styles/theme.module' as *;

.heading {
  margin-right: spacing(13);
  margin-bottom: spacing(3);
  @include typographyH4Bold;
  @include media($from: $lg) {
    @include typographyH4Medium;
  }
}

.text {
  @include typographyLabel;

  margin-bottom: spacing(3);

  @include media($until: $xl) {
    @include typographySmallBody;

    line-height: 1rem;
  }
}

.text_link {
  @include typographyButtonsAndLinksBold;
}

.secondary_actions {
  width: 100%;
  margin-top: spacing(8);

  @include media($from: $lg) {
    margin-top: 0;
  }
}

.group_icon {
  width: 17px;
  height: 17px;
  flex: none;
  margin: 0 spacing(3);
  color: $grayDark;
}

.request_credit_navigation {
  margin-bottom: 0;
}

.request_credit_secondary {
  margin-top: 0;
}

.showMore {
  align-items: center;
  background-color: transparent;
  cursor: pointer;
  border: none;
  display: flex;
  justify-content: space-between;
  height: 44px;
  margin: 0;
  padding: 0;
  width: 100%;

  p {
    margin: 0;
  }
}
