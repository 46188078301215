@use '@styles/theme.module' as *;

.heading {
  margin-bottom: spacing(5);
}

.text {
  display: block;
  margin-bottom: spacing(7);
  color: $grayDark;
}

.button {
  margin-top: spacing(3);
}

.error_wrapper {
  display: flex;
  align-items: center;
  width: fit-content;
  margin: spacing(3) 0 0;

  .icon {
    color: $errorFG;
    margin-right: spacing(1);
    height: 12px;
    width: 12px;
  }

  .error_message {
    width: fit-content;
    color: $errorFG;
  }
}

.modal_content {
  padding-right: 0;
  @include media($from: $sm) {
    width: 350px;
  }
}

.title {
  height: fit-content;
}
