@use '@styles/theme.module' as *;

.alert_text {
  @include typographyExtraSmallBody;

  @include media($from: $sm) {
    @include typographyBody;
  }
}

.text_container {
  max-width: 165px;

  @include media($from: $smMobile) {
    max-width: 190px;
  }

  @include media($from: $sm) {
    max-width: 100%;
  }
}

.alert_content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.checkbox {
  @include typographyExtraSmallBody;

  display: flex;
  align-items: center;
  padding: spacing(2) spacing(3);
  border: 1px solid $primary;
  border-radius: spacing(8);
  background-color: $white;
  cursor: pointer;
  color: $gray12;

  &:hover {
    color: $primary;
  }

  @include media($from: $sm) {
    @include typographyBody;
  }
}
