@use '@styles/theme.module.scss' as *;

.empty_order_history {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &_container {
    width: 100%;
  }

  &_title {
    margin-bottom: spacing(7);
  }

  &_button {
    @include typographyBody;
  }
}
