@use '@styles/theme.module.scss' as *;

@mixin spacingToBottom {
  margin: 0 0 spacing(2);

  &:last-child {
    margin-bottom: 0;
  }
}

@mixin spacingToRight {
  margin: 0 spacing(2) 0 0;

  &:last-child {
    margin-right: 0;
  }
}

.info {
  margin-bottom: spacing(5);
  width: 100%;
}

.bold {
  @include typographyBodyBold;
}

.status {
  text-transform: capitalize;
  @include typographyBodyBold;
}

.order {
  color: $grayDark;
}

.top_line {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $grayLighter;
  padding-bottom: spacing(5);
  margin-bottom: spacing(5);
  @include typographyBody;
}

.details {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  &_item {
    width: 50%;
  }
}

.buttons_wrapper {
  display: flex;
  width: 100%;
  flex-flow: column wrap;

  .button {
    flex-grow: 1;
    @include typographySmallBody;

    @include media($from: $lg) {
      @include typographyBody;
    }

    @include spacingToBottom;
  }

  @include media($from: $sm) {
    flex-flow: row nowrap;

    .button {
      @include spacingToRight;
    }
  }
}

.active_order_card {
  @include media($from: $sm) {
    flex-flow: column wrap;
  }
}
