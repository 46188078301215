@use '@styles/theme.module.scss' as *;

.wrapper {
  position: relative;
  margin-top: spacing(5);

  @include media($from: $sm) {
    margin-top: spacing(6);
  }
}

.description {
  color: $errorFG;
  margin-left: spacing(1);
  @include typographySmallBody;
}

.icon {
  color: $errorFG;
}

.unavailable_info_section {
  display: flex;
  align-items: center;

  &.gray {
    .description,
    .icon {
      color: $black;
    }
  }
}
