@use '@styles/theme.module.scss' as *;

.nav {
  display: flex;
  padding: spacing(6) 0 0;

  &_item {
    border-bottom: 1px solid $grayLighter;
    justify-content: center;
    flex: 1;
    display: flex;
    text-decoration: none;
    cursor: pointer;
    @include typographySmallBodyMedium;

    &_button {
      display: flex;
      border: none;
      padding: 0;
      background: inherit;
      cursor: pointer;
      flex: 1 1;
      justify-content: center;
      padding-bottom: spacing(5);

      @include focusVisible {
        outline: auto;
      }
    }

    &_name {
      color: $grayDarker;
      @include typographyBody;
    }

    &_quantity {
      margin-left: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 16px;
      height: 16px;
      background-color: $primary;
      color: $white;
      border-radius: 100%;
      text-align: center;
      @include typographyLabelBold;

      @include media($from: $xl) {
        line-height: 1;
      }
    }

    &.active {
      border-bottom: 1px solid $primary;

      .nav_item_name {
        color: $black;
        @include typographyBodyBold;
      }
    }
  }
}
