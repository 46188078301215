@use '@styles/theme.module.scss' as *;

$bottom-panel-height-from-md: 100px;

.bottom_panel_wrapper {
  border-top: 1px solid $grayLighter;
  background-color: $white;
  padding: 0 spacing(4);

  &:not(.drawer_opened) {
    border-top: none;
    background-color: $gray1;
    position: fixed;
    bottom: 34px;
    right: 0;
    left: 0;
    z-index: 11;
  }

  @include media($from: $sm) {
    padding: 0 spacing(14);
  }

  @include media($from: $largeTablet) {
    padding: spacing(0) spacing(2);
    border-right: 1px solid $grayLighter;

    &:not(.drawer_opened) {
      border-top: none;
      background-color: $gray1;
    }

    width: 61.5%;
  }

  @include media($from: $xl) {
    padding: 0 spacing(13) 0 spacing(12);
  }

  &.drawer_opened {
    width: 100%;
    position: relative;
    text-align: center;
    height: 137px;
    padding: 0 0.5rem;
    @include media($sm) {
      height: 153px;
    }
  }

  .refer_text_wrapper {
    padding: spacing(4);
    position: absolute;
    left: 0;
    right: 0;
    bottom: 100%;

    .refer_text_container {
      background-color: $primaryLightest;
      color: $primary;
      border: 1px solid #0000001a;
      border-radius: 4px;
      padding: spacing(4);
      position: relative;
      text-align: left;

      .close_button {
        position: absolute;
        top: 0;
        right: 0;

        & svg {
          color: $primary;
        }
      }
    }
  }
}

.bottom_panel_inner {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  border: none;
  padding-top: spacing(4);
  padding-bottom: spacing(4);
  position: relative;

  @include media($from: $sm) {
    margin: 0;
    padding: spacing(4) spacing(5);
  }

  @include media($from: $largeTablet) {
    width: 100%;
    margin: auto;
    display: flex;
    box-shadow: 0 -1px 0 rgba(0 0 0 / 0.02);
  }

  &.drawer_opened {
    width: 100%;
    margin: auto;
    display: flex;
    height: 100px;
  }
}

.order_minimum_mobile {
  @include media($from: $largeTablet) {
    display: none !important;
  }
}

.error_pop_up {
  position: absolute;
  top: 15px;
  right: 0;
  transform: translateY(-100%);

  @include media($until: $largeTablet) {
    right: 50%;
    transform: translate(50%, -100%);
  }

  :global(.MuiAlert-icon) {
    align-self: flex-start;
    transform: translateY(spacing(1));
  }
}
