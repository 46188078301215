@use '@styles/theme.module.scss' as *;

.modal {
  @include media($from: $sm) {
    width: fit-content;
  }
}

.modal_wrapper,
.err_modal_wrapper {
  max-width: 350px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  .heading {
    margin-bottom: spacing(7);
    @include typographyH3;
  }

  .info_text {
    color: $grayDark;
    margin-bottom: spacing(7);
    @include typographyBody;
  }

  .button {
    margin-bottom: spacing(4);
  }
}

.err_modal_wrapper {
  min-height: 120px;
  justify-content: space-between;
}
