@use '@styles/theme.module' as *;

.alert {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid rgb(0 0 0 / 0.1);
  position: relative;
  box-sizing: border-box;
  width: 345px;

  &.medium {
    padding: spacing(2);

    @include media($from: $sm) {
      padding: spacing(3);
    }
  }

  &.large {
    padding: spacing(4);
  }

  & > :global(.MuiAlert-message) {
    padding: 0;
  }

  & > :global(.MuiAlert-icon) {
    padding: 0;
    margin: 0 8px 0 0;
  }

  & > :global(.MuiAlert-action) {
    padding: 0;
    margin: 0;
    position: absolute;
    top: 2px;
    right: 2px;

    svg {
      color: $black;
    }
  }

  &.is_icon_close {
    padding: spacing(6);
  }

  &.error,
  &.is_hover {
    box-shadow: 0 0 12px rgb(0 0 0 / 0.02), 0 4px 16px rgb(0 0 0 / 0.1);
  }

  &.error,
  &.errorTimeSlot {
    background-color: $errorBG;

    & > :global(.MuiAlert-message),
    & > :global(.MuiAlert-icon) svg {
      color: $errorFG;
    }
  }

  &.errorTimeSlot {
    box-shadow: none;
  }

  &.error {
    & > :global(.MuiAlert-icon) {
      margin: 0 10px 0 6px;
    }
  }

  &.success {
    background-color: $successBG;

    & > :global(.MuiAlert-message) {
      color: $primaryDark;
    }
  }

  &.warning {
    background-color: $warningBG;

    & > :global(.MuiAlert-message),
    & > :global(.MuiAlert-icon) {
      color: $warningFG;
    }
  }

  &.info {
    background-color: $gray1;
    stroke-width: 1.2;

    & > :global(.MuiAlert-icon) {
      color: $primaryDark;
      margin-bottom: spacing(3);
    }

    & > :global(.MuiAlert-message) {
      color: $grayDarker;
      width: 100%;
    }

    @include media($until: $sm) {
      & > :global(.MuiAlert-icon) {
        width: 16px;
        height: 16px;
        margin-bottom: spacing(7);
      }
    }
  }

  @media (min-width: 768px) {
    &.errorTimeSlot,
    &.success,
    &.warning,
    &.info {
      width: 540px;
    }
  }

  @media (min-width: 1024px) {
    &.errorTimeSlot,
    &.success,
    &.warning,
    &.info {
      width: 308px;
    }

    &.is_hover.success {
      width: 258px;
    }

    &.warning.is_hover {
      max-width: 400px;
      width: fit-content;
      padding-right: spacing(8);
    }
  }

  @media (min-width: 1440px) {
    &.success.is_hover {
      width: 230px;
    }

    &.warning.is_hover {
      max-width: 375px;
      width: fit-content;
    }

    &.error {
      width: 338px;
    }

    &.errorTimeSlot {
      width: 427px;
    }
  }

  @media (min-width: 1920px) {
    &.error,
    &.success,
    &.warning,
    &.info {
      padding: spacing(6);
    }

    &.warning.is_hover {
      max-width: 440px;
      width: fit-content;
    }

    &.success.is_hover {
      width: 280px;
    }

    &.error {
      width: 350px;
    }

    &.errorTimeSlot {
      padding: 18px;
      width: 562px;
    }
  }

  &.full_width {
    width: 100%;
  }

  &.without_shadow {
    box-shadow: none;
  }

  &.without_border {
    border: none;
  }
}
