@use '@styles/theme.module' as *;

.payment_tabs_container {
  display: flex;
  align-items: center;

  & :global(.MuiTabs-scroller) {
    padding: 5px 0;
  }
}

.payment_tab {
  @include media($from: $xs) {
    max-width: 101px;
  }

  @include media($from: $sm) {
    max-width: 128px;
  }
}

.icon {
  margin-right: spacing(1);

  &_tab {
    display: flex;
    align-items: center;
  }
}

.text_tab {
  width: 45px;
  text-align: left;

  @include media($from: $sm) {
    width: 100%;
  }
}

.divider {
  margin-top: spacing(4);
}
