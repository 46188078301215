@use '@styles/theme.module' as *;

.scale_price {
  color: $sale;
  @include typographySmallBody;

  @include media($until: $md) {
    display: block;
  }
}

.tile_price {
  display: block;
  flex-wrap: wrap;
  align-items: center;
  @include typographySmallBody;

  @include media($from: $md) {
    display: inline-flex;
  }

  &_current {
    display: inline-block;
    margin-bottom: calc(#{spacing(1)} / 2);
    margin-right: spacing(1);
    @include typographyBody;

    @include media($from: $md) {
      margin-bottom: spacing(0);
    }

    &_min {
      margin: spacing(0);
      @include typographyLabel;
    }
  }

  &_free {
    @include typographyBodyBold;
  }

  &_previous {
    color: $grayDark;
    margin-right: spacing(1);
  }

  &_percents {
    display: block;
    color: $sale;

    @include media($from: $md) {
      display: inline;
    }
  }

  &.v2 {
    & .tile_price_current {
      @include typographyH6Medium;

      color: $gray12;
      margin-bottom: 0;
    }

    & .tile_price_current_min {
      display: inline-flex;
    }

    & .tile_price_previous {
      @include typographySmallBodyMedium;

      color: $gray8C;
    }

    & .scale_price {
      @include typographySmallBody;
    }
  }
}
