@use '@styles/theme.module' as *;

.venmo_button {
  background-color: #3d95ce;
  margin-top: spacing(3);
  @include hover {
    background-color: #008cff !important;
  }
}

.venmo_title {
  color: $grayDark;
  @include typographyBody;
}
