@use '@styles/theme.module' as *;

.wrapper {
  max-width: 443px;
}

.heading {
  margin-bottom: spacing(5);
}

.text {
  display: block;
  margin-bottom: spacing(7);
}

.button {
  margin-top: spacing(3);
}
