@use 'sass:string';

// Comments below contain spacings defined in UI design

$spacing: (
  '0': 0,
  // 4px
  '1': 0.25,
  // 8px
  '2': 0.5,
  // 12px
  '3': 0.75,
  // 14px
  '3.5': 0.875,
  // 16px
  '4': 1,
  // 20px
  '5': 1.25,
  // 24px
  '6': 1.5,
  // 32px
  '7': 2,
  // 40px
  '8': 2.5,
  // 48px
  '9': 3,
  // 56px
  '10': 3.5,
  // 64px
  '11': 4,
  // 80px
  '12': 5,
  // 88px
  '12.5': 5.5,
  // 96px
  '13': 6,
  // 120px
  '14': 7.5,
  // 160px
  '15': 10,
);

@function spacing($number) {
  @return map-get($spacing, '' + $number) + rem; // '' + $number converts number to string
}

@each $space, $value in $spacing {
  // stylelint-disable selector-pseudo-class-no-unknown
  :export {
    #{unquote($space)}: $value;
  }
}
