@use '@styles/theme.module.scss' as *;

.tag {
  display: flex;
  align-items: center;
  height: 16px;
  text-transform: uppercase;
  color: $primary;
  @include typographyLabelBold;

  &_text {
    display: inline-block;
    margin-left: spacing(1);
    @include typographyLabelBold;

    line-height: 0;

    @include media($from: $lg) {
      line-height: 0;
    }
  }

  &_icon {
    height: 12px;
    width: 12px;
  }

  &.v2 {
    color: $gray12;
    position: absolute;
    padding: 2px 8px;

    &.express_eligible {
      background-color: $expressTagBg;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      border-top-left-radius: 6px;
      top: 6px;
      left: 1px;
    }

    &.peak_quality {
      bottom: 1px;
      padding: 4px 8px;
      border-top-right-radius: 15px;
      border-bottom-left-radius: radius('xlg');
      background-color: $peakQualityTagBg;
    }

    & .tag_text {
      text-transform: none;
      @include typographyLabelMedium;

      font-size: 0.5rem;
    }

    & .tag_icon {
      height: 8px;
      width: 8px;
    }
  }

  &.wine_logo {
    position: absolute;
    height: auto;
    bottom: 36px;
    right: -10px;
    padding-right: 0;

    & svg {
      height: 11px;
    }
  }
}
