@use '@styles/theme.module.scss' as *;

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;

  & :global(.MuiInputLabel-root) {
    color: $grayDark;
    @include typographyBody;
  }

  & :global(.MuiFormHelperText-root) {
    color: $black;
    @include typographyBody;
  }

  & :global(.MuiFormHelperText-root.Mui-error) {
    color: $errorFG;
  }

  &_loading {
    max-height: 100%;
  }
}

.container {
  padding-bottom: spacing(9);

  @include media($from: $largeTablet) {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-gap: 20px;
  }

  &_loading {
    display: none;
  }
}

.content {
  min-width: auto;
  grid-column: 2 / span 4;

  @include media($from: $lgbp) {
    min-width: 554px;
    grid-column: 2 / span 3;
  }
}
