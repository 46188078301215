@use '@styles/theme.module' as *;

.allergens {
  color: $sale;
}

.kosher {
  display: flex;
  align-items: center;
  margin-top: spacing(4);

  &_icon {
    position: relative;
    width: 20px;
    height: 20px;
    margin-right: spacing(1);
  }
}

.link {
  display: block;
  margin: spacing(4) 0;
  @include typographyBodyBold;
}
