@use '@styles/theme.module' as *;
@use 'sass:map';

.wrapper {
  &_loading {
    pointer-events: none;
    opacity: 0.5;
  }
}

.footer {
  display: flex;
  justify-content: flex-end;
  margin-top: spacing(10);
  margin-bottom: spacing(9);

  @include media($from: $sm) {
    padding: 0 spacing(5);
  }
}
