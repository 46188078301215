@use '@styles/theme.module' as *;

.swiper {
  position: relative;

  .swiperControls {
    background-color: $white;
    max-width: 80px;
    position: absolute;
    right: 0;
    top: 216px;
    z-index: 10;
    display: flex;
    justify-content: flex-end;
    padding: 0;
    margin-top: calc(#{spacing(7)} - 4px);
  }

  @include media($from: $sm) {
    .swiperControls {
      top: 340px;
      max-width: 100px;
      margin-top: calc(#{spacing(8)} - 4px);
    }
  }
}

.giftCard {
  .imageContainer {
    position: relative;
    border-radius: spacing(3);
    height: 216px;
    max-width: 100%;
    box-shadow: 0 2px 12px rgb(0 0 0 / 0.1);
  }

  .imageTitle {
    margin-top: spacing(7);
    max-width: calc(100% - 80px);
    @include typographyH4Bold;
    @include media($from: $lg) {
      @include typographyH4Medium;
    }
  }

  .image {
    border-radius: spacing(3);
  }

  @include media($from: $sm) {
    .imageContainer {
      border-radius: spacing(5);
      height: 340px;
      width: 544px;
    }

    .imageTitle {
      margin-top: spacing(8);
      max-width: calc(100% - 100px);
    }

    .image {
      border-radius: spacing(5);
    }
  }
}

.arrowIcon {
  color: $black;

  &:hover {
    color: $primary;
    background-color: transparent;
  }
}

.leftArrow {
  padding-left: 0;
}

.rightArrow {
  padding-right: 0;
}
