@use '@styles/theme.module.scss' as *;

.dropdown_disabled {
  pointer-events: none;

  svg {
    display: none;
  }
}

.info_icon {
  margin: spacing(0) spacing(5) spacing(2);

  svg {
    color: $grayDarker;
    width: 14px;
  }
}

.link_text {
  margin-left: spacing(1);
  color: $grayDarker;

  &:hover,
  &:focus {
    color: inherit;
  }
}
