/* stylelint-disable */
@use '@styles/theme.module.scss' as *;

.text_button {
  @include typographyH5Medium;
}

.hamburger_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  background-color: $primaryDark;

  @include media($from: $md) {
    width: 420px;
  }

  .hamburger_inner_wrapper {
    overflow: auto;
  }
}

.close_button {
  width: 25px;
  height: 32px;
  padding: 0;
  position: fixed;
  top: spacing(5);
  right: spacing(5);
  z-index: $mobileStepper;

  @include media($from: $sm) {
    top: spacing(6);
    right: spacing(6);
  }

  svg path {
    stroke: $white;
  }
}

.accordion_summary {
  display: flex;
  justify-content: flex-start;
  padding: 0;
  min-height: spacing(7);

  :global(.MuiAccordionSummary-content) {
    flex-grow: 0;
    margin: 0;
    min-width: 0;
    flex-shrink: 1;
  }

  :global(.MuiAccordionSummary-expandIconWrapper) {
    width: 10px;
    height: 10px;

    svg path {
      stroke: $white;
    }
  }
}

.top_menu {
  display: flex;
  align-items: center;
  padding: spacing(5);
  justify-content: space-between;

  @include media($from: $sm) {
    padding: spacing(6);
  }

  .inner_wrapper {
    display: flex;
    align-items: center;

    .home_button {
      display: flex;
      justify-content: flex-start;
      width: 32px;
      height: 32px;
      padding: 0;
      margin-right: spacing(6);

      svg path {
        fill: $white;
      }
    }
  }
}

.delivery {
  display: flex;
  align-items: baseline;
  @include typographyH5Medium;
  @include media($from: $largeTablet) {
    display: none;
  }
}

.delivery_address {
  margin-left: spacing(1);
}

.delivery,
.delivery .delivery_address {
  color: $white;
  @include typographyH5Medium;
}

.divider {
  margin: 0 spacing(4);
  background-color: $white;
}

.see_all_item {
  padding: 0;
  margin: 0;

  :first-child {
    margin-top: 0;
  }
}

.accordion {
  :global(.MuiCollapse-wrapperInner) {
    :first-child {
      margin-top: spacing(2);
    }
  }

  &::before {
    display: none;
  }

  &.without_margin {
    :global(.MuiCollapse-wrapperInner) {
      :first-child {
        margin-top: spacing(0);
      }
    }
  }
}

.list_wrapper {
  padding: spacing(2);

  @include media($from: $sm) {
    padding: spacing(3);
  }

  .list_button {
    padding: 0;
  }

  .list_item {
    padding: spacing(3);
    align-items: flex-start;

    :global(.MuiListItemIcon-root) {
      @include media($from: $sm) {
        min-width: spacing(11);
      }
    }

    .list_image {
      width: 20px;
      height: 32px;
      color: $white;

      svg {
        min-width: 20px;
        min-height: 25px;

        path,
        circle,
        line,
        rect {
          stroke: $white;
        }
      }
    }

    .list_text {
      color: $white;
      margin-right: spacing(2);
      align-items: center;
      margin-top: 0;
      margin-bottom: 0;
      @include typographyH5Medium;
    }

    :global(.MuiPaper-root) {
      max-width: 75%;

      @include media($from: $sm) {
        max-width: 85%;
      }

      margin-top: 0;
    }
  }

  .account_list_item {
    align-items: center;
    display: flex;

    @include media($from: $sm) {
      padding: spacing(4);
    }
  }

  .account_list_image {
    margin-right: spacing(7);
  }

  .list_link_text {
    &:focus-within {
      background-color: rgb(0 0 0 / 0.1);
    }
  }
}

.accordion_details_text {
  color: $white;
  padding-left: spacing(3);
  margin: spacing(7) 0;
}

.list_item:hover > :global(.MuiPaper-root) {
  background-color: rgb(0 0 0 / 0%);
}

.link {
  color: $white;
  border-bottom: 1px solid $white;
  border-radius: 0;
  padding: 0;
  @include typographyH5Medium;

  &::before {
    content: unset;
  }

  &.fictitious {
    border-bottom: 1px solid transparent;
    cursor: default;

    &:active {
      color: $white;
    }

    @include hover {
      color: $white;
    }
  }

  &:focus {
    color: $white;
    outline: 1px dashed $white;
  }
}

.logged_in_link {
  border-bottom: none;
}

.info_icon {
  color: $white;
}

.sub_dep_list {
  margin: 0 0 0 spacing(4);
  padding: spacing(3) 0 0 0;
  color: $white;
}

.sub_dep_item {
  display: block;
  min-height: 32px;
  padding: spacing(3);

  &:last-child {
    padding-bottom: 0;
  }
}

.muted {
  opacity: 0.3;
}

.highlighted {
  opacity: 1;
}

.link_connector {
  color: white;
  line-height: 1.33;
  border-bottom: 1px solid transparent;
}

.hidden {
  visibility: hidden;
}
