/* stylelint-disable */

$primary: #298321;
$primaryDarkest: #0c270a;
$primaryDarker: #144110;
$primaryDark: #21691a;
$primaryLight: #549c4d;
$primaryLighter: #94c190;
$primaryLightest: #eaf3e9;
$primaryShadesGreenLightest: #eaf3e9;
$black: #000000;
$blackLight: #111111;
$blackSecondary: #697488;
$grayDarker: #444444;
$grayDark: #666666;
$grayLight: #888888;
$grayLighter: #dddddd;
$white: #ffffff;
$beige: #c4c4c4;
$topPickPrimary: $primaryDark;
$expressPrimary: #005484;
$expressTagBg: #faf1ed;
$peakQualityTagBg: #fcfcfc;
$sale: #b93d3f;
$warningBG: #fdf2ec;
$warningFG: #af3d08;
$errorBG: #fff8f8;
$errorFG: #bb0000;
$red: #bb0000;
$redDark: #a70404;
$infoBG: $primaryLightest;
$successBG: $primaryLightest;
$infoFG: $primaryDark;
$successFG: $primaryDark;
$deliveryPassBG: #fffaf4;
$deliveryPassFG: #c5521c;
$deliveryPassDark: #9e4216;
$masqueradeGreen: #096d19;
$masqueradeOrange: #e77630;
$orange: #c5521c;
$orangeDark: #9e4216;
$groupScaleBackgoundGreen: #eef5ed;

$gray1: #fafafa;
$gray2: #f0f0f0;
$gray3: #f5f5f5;
$gray4: #e5e5e5;
$gray5: #f8f8f8;
$grayB0: #b0b0b0;
$gray12: #121212;
$gray8C: #8c8c8c;
$gray95: #959595;
$grayF1: #f1f1f1;

$black06: rgba(0, 0, 0, 0.06);
$black10: rgba(0, 0, 0, 0.1);
$black15: rgba(0, 0, 0, 0.15);

:export {
  primary: $primary;
  primaryDarkest: $primaryDarkest;
  primaryDarker: $primaryDarker;
  primaryDark: $primaryDark;
  primaryLight: $primaryLight;
  primaryLighter: $primaryLighter;
  primaryLightest: $primaryLightest;
  primaryShadesGreenLightest: $primaryShadesGreenLightest;
  black: $black;
  grayDarker: $grayDarker;
  grayDark: $grayDark;
  grayLight: $grayLight;
  grayLighter: $grayLighter;
  white: $white;
  red: $red;
  redDark: $redDark;
  topPickPrimary: $topPickPrimary;
  expressPrimary: $expressPrimary;
  sale: $sale;

  warningBG: $warningBG;
  warningFG: $warningFG;
  errorBG: $errorBG;
  errorFG: $errorFG;
  infoBG: $infoBG;
  successBG: $successBG;
  infoFG: $infoFG;
  successFG: $successFG;
  deliveryPassBG: $deliveryPassBG;
  deliveryPassFG: $deliveryPassFG;
  deliveryPassDark: $deliveryPassDark;
  masqueradeGreen: $masqueradeGreen;
  masqueradeOrange: $masqueradeOrange;

  gray1: $gray1;
  gray2: $gray2;
  gray3: $gray3;
  gray4: $gray4;
  gray5: $gray5;
  grayB0: $grayB0;
  gray12: $gray12;
}
