@use '@styles/theme.module' as *;

.payment_modal {
  width: 100%;

  @include media($from: $sm) {
    max-width: 634px;
  }
}

.payment_tabs_container {
  display: flex;
  align-items: center;
}

.paypal_title {
  margin-bottom: spacing(4);
}

.payment_tab {
  @include media($from: $xs) {
    max-width: 101px;
  }

  @include media($from: $sm) {
    max-width: 128.5px;
  }
}

.icon {
  margin-right: spacing(1);

  &_tab {
    display: flex;
    align-items: center;
  }
}

.payments {
  height: fit-content;
}
