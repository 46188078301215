@use '@styles/theme.module' as *;

.container {
  width: 100%;
  background-color: $primaryLightest;
  color: $grayDark;
  display: flex;
  align-items: center;
  padding: spacing(2) spacing(1);
  margin-bottom: spacing(6);
  @include typographySmallBody;
}

.annotation_icon {
  height: 12px;
  width: 12px;
  margin: spacing(0) spacing(3);
}
