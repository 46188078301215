@use '@styles/theme.module' as *;

.tile {
  border-radius: spacing(3);
  max-width: 544px;
  max-height: 216px;
  width: 100%;
  height: 344px;

  @include media($from: $sm) {
    max-height: 344px;
    border-radius: spacing(5);
  }
}

.text_tile {
  width: 40%;
  height: 50px;
}
